import { FIELD_ICONS, FilterFields } from "../fields";
import { FilterItemValueContainer } from "./FilterContainer";
import { useLiveQuery } from "dexie-react-hooks";
import { debouncedItemsSearch } from "@/extensions/Tag";
import { useCallback } from "react";
import { GenericFilter } from "./GenericFilter";
import { getTagWithFullName } from "@/utils/getTagWithFullName";

interface TagFilterProps {
  dropdown?: boolean;
}

/**
 * Filter component for tags
 * Utilizes the GenericFilter component
 */
export function TagFilter({ dropdown = true }: TagFilterProps) {
  const getTags = useCallback(queryTags, []);
  const Icon = FIELD_ICONS[FilterFields.TagID];

  return (
    <GenericFilter
      field={FilterFields.TagID}
      getItems={getTags}
      Icon={Icon}
      itemRenderer={(itemsIds) => <Tags tagIds={itemsIds} />}
      title="Tags include"
      commandPlaceholder="Search for tag"
      dropdown={dropdown}
    />
  );
}

const queryTags = async (query: string) => {
  // Query tags from localdb and map them by label, value
  const tags = await debouncedItemsSearch({ query });
  return tags
    .filter((tag) => tag.fullName)
    .map((tag) => ({
      label: tag.fullName,
      value: tag.id,
    }));
};

interface TagsProps {
  tagIds: string[];
}
const Tags = ({ tagIds }: TagsProps) => {
  const tags = useLiveQuery(() => {
    return Promise.all(tagIds.map((tagId) => getTagWithFullName(tagId)));
  }, [tagIds]);
  if (!tags) return null;

  return (
    <FilterItemValueContainer>
      {tags.length === 0 && "No tags"}
      {tags.length === 1 && tags[0].fullName}
      {tags.length > 1 && `${tags.length} tags`}
    </FilterItemValueContainer>
  );
};
