import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import clsx from "clsx";
import { useLiveQuery } from "dexie-react-hooks";
import { NavLink } from "react-router-dom";
import { DataTest } from "../../tests/e2e/utils/constants";
import { getTagWithFullName } from "../utils/getTagWithFullName";

export function TagRenderer(props: NodeViewProps) {
  const tag = useLiveQuery(() => getTagWithFullName(props.node.attrs.id));
  if (!tag) return null;

  return (
    <NodeViewWrapper as="span" className="inline-block mx-0.5">
      <NavLink
        to={`/tags/${tag.id}`}
        className={clsx(
          "px-2 rounded-full no-underline",
          tag.deleted ? "bg-red-100" : "bg-green-200"
        )}
        data-testid={DataTest.TagLink}
      >
        #{tag.fullName}
      </NavLink>
    </NodeViewWrapper>
  );
}
