import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { v4 } from "uuid";

export const UniqueId = Extension.create({
  name: "uniqueId",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("uniqueId"),
        appendTransaction: (transactions, _prevState, nextState) => {
          const transaction = nextState.tr;
          let modified = false;

          if (transactions.some((transaction) => transaction.docChanged)) {
            nextState.doc.descendants((node, pos) => {
              // attribute was not found
              if (node.attrs["id"] === undefined) return true;
              // attribute is already set
              if (node.attrs["id"] !== null) return true;

              modified = true;
              transaction.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                id: v4(),
              });
            });
          }

          return modified ? transaction : null;
        },
      }),
    ];
  },
});
