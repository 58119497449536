import { omit } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

type KeyboardShortcut = {
  keys: {
    or: string[];
  }[];
  element: Window;
  preventDefault?: boolean;
  stopPropogation?: boolean;
};

export const useKeyboardShortcut = ({
  keys,
  element,
  preventDefault = false,
  stopPropogation = false,
}: KeyboardShortcut) => {
  const [activeKeys, setActiveKeys] = useState<{ [key: string]: boolean }>({});

  const isKeyboardShortcut = useMemo(() => {
    if (Object.keys(activeKeys).length !== keys.length) {
      return false;
    }
    return keys.every(({ or }) => or.some((orKey) => !!activeKeys[orKey]));
  }, [activeKeys, keys]);

  const isKeyboardShortcutActive = useCallback(
    (latestActiveKeys: { [key: string]: boolean }) => {
      if (Object.keys(latestActiveKeys).length !== keys.length) {
        return false;
      }
      return keys.every(({ or }) =>
        or.some((orKey) => !!latestActiveKeys[orKey])
      );
    },
    [keys]
  );

  useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent) => {
      if (stopPropogation) {
        evt.stopPropagation();
      }
      setActiveKeys((prevActiveKeys) => {
        const currentActiveKeys = {
          ...prevActiveKeys,
          ...(!["Meta", "Control", "Shift", "Alt"].includes(evt.key) && {
            [evt.key]: true,
          }),
          ...(evt.ctrlKey && { ctrl: evt.ctrlKey }),
          ...(evt.shiftKey && { shift: evt.shiftKey }),
          ...(evt.altKey && { alt: evt.altKey }),
          ...(evt.metaKey && { cmd: evt.metaKey }),
        };
        if (isKeyboardShortcutActive(currentActiveKeys)) {
          if (preventDefault) {
            evt.preventDefault();
          }
        }
        return currentActiveKeys;
      });
    };

    const onKeyUp = (evt: KeyboardEvent) => {
      if (stopPropogation) {
        evt.stopPropagation();
      }
      setActiveKeys((prevActiveKeys) => {
        const propertiesToRemove = [evt.key];
        if (!evt.ctrlKey) propertiesToRemove.push("ctrl");
        if (!evt.shiftKey) propertiesToRemove.push("shift");
        if (!evt.altKey) propertiesToRemove.push("alt");
        if (!evt.metaKey) propertiesToRemove.push("cmd");
        return {
          // this check is added because of issue see: https://stackoverflow.com/questions/27380018/when-cmd-key-is-kept-pressed-keyup-is-not-triggered-for-any-other-key
          ...(evt.key !== "Meta"
            ? {
                ...omit(prevActiveKeys, propertiesToRemove),
              }
            : {}),
        };
      });
    };
    element.addEventListener("keydown", onKeyDown);
    element.addEventListener("keyup", onKeyUp);
    return () => {
      element.removeEventListener("keydown", onKeyDown);
      element.removeEventListener("keyup", onKeyUp);
    };
  }, [keys, element]);

  return useMemo(
    () => ({
      isKeyboardShortcut,
    }),
    [isKeyboardShortcut]
  );
};
