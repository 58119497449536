import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../db";
import { useCallback } from "react";
import { merge } from "lodash";
import { SidePanelSection } from "../types";

interface SectionDetailsProps<T> extends SidePanelSection {
  setCollapsed: () => void;
  setSortByOrSortOrder: (
    key: "sortBy" | "sortOrder",
    value: string
  ) => Promise<string> | void;
  setManualSortOrder: (value: T[]) => void;
}

export const useSectionDetails = <T>(
  sectionType: "page" | "tag" | "contact"
): SectionDetailsProps<T> => {
  const settings = useLiveQuery(() =>
    db.settings.where({ id: "userPreference" }).first()
  );
  if (settings?.id && settings?.id !== "userPreference") {
    return {
      setCollapsed: () => {},
      setSortByOrSortOrder: () => {},
      setManualSortOrder: () => {},
    };
  }
  const setCollapsed = useCallback(async () => {
    await db.settings.put(
      merge(settings, {
        id: "userPreference",
        [sectionType]: {
          isCollapsed: !settings?.[sectionType]?.isCollapsed,
        },
      }),
      "userPreference"
    );
  }, [settings]);

  const setSortByOrSortOrder = useCallback(
    async (key: "sortBy" | "sortOrder", value: string) => {
      return await db.settings.put(
        merge(settings, {
          id: "userPreference",
          [sectionType]: {
            [key]: value,
          },
        }),
        "userPreference"
      );
    },
    [settings]
  );

  const setManualSortOrder = useCallback(
    async function manualOrder<T>(value: T[]) {
      return await db.settings.put(
        merge(settings, {
          id: "userPreference",
          [sectionType]: {
            manualSortOrder: value,
          },
        }),
        "userPreference"
      );
    },
    [settings]
  );

  return {
    isCollapsed: settings?.[sectionType]?.isCollapsed,
    sortBy: settings?.[sectionType]?.sortBy || "createdDate",
    sortOrder: settings?.[sectionType]?.sortOrder || "increasing",
    manualSortOrder: settings?.[sectionType]?.manualSortOrder || [],
    setCollapsed,
    setSortByOrSortOrder,
    setManualSortOrder,
  };
};
