import { PmNode } from "../types";

export function extractTagIds(node: PmNode) {
  const tagIds: string[] = [];

  if (node.type === "tag" && node.attrs?.id) {
    tagIds.push(node.attrs.id);
  }

  for (const item of node.content ?? []) {
    tagIds.push(...extractTagIds(item));
  }

  return tagIds;
}
