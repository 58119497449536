import { Extension } from "@tiptap/react";
import { Plugin } from "prosemirror-state";
import {
  FilesWithNodeIDs,
  addFilesToUploadQueue,
  insertFileNode,
  validateFiles,
} from "../utils/fileUtils";
import { v4 } from "uuid";

const getPastedItems = (
  clipboardData: DataTransfer | null
): DataTransferItem[] => {
  if (!clipboardData) return [];
  return Array.from(clipboardData.items);
};

export const Paste = Extension.create<{ newEditor: boolean }>({
  name: "Paste",
  addOptions() {
    return {
      newEditor: false,
    };
  },
  addProseMirrorPlugins() {
    const { options } = this;
    return [
      new Plugin({
        props: {
          handlePaste(view, event) {
            const pastedItems = getPastedItems(event.clipboardData);
            const filesWithNodeIDs: FilesWithNodeIDs = [];

            // Create an array of pasted files and new id for the file node
            pastedItems.forEach((item) => {
              if (item.type === "text/plain") return;

              const itemAsFile = item.getAsFile();
              if (!itemAsFile) return;

              filesWithNodeIDs.push([v4(), itemAsFile]);
            });

            if (filesWithNodeIDs.length) {
              // Validate size
              if (!validateFiles(filesWithNodeIDs)) {
                alert("File too large");
                return false;
              }

              // Add file to upload queue
              void addFilesToUploadQueue(filesWithNodeIDs);

              // Insert the file pm node into document
              filesWithNodeIDs.forEach(([fileID]) => {
                const tr = insertFileNode(
                  view.state.tr,
                  view.state,
                  fileID,
                  options.newEditor
                );
                if (tr) view.dispatch(tr);
              });
              return true;
            }

            return false;
          },
        },
      }),
    ];
  },
});
