import { MapExpire } from "./mapExpire";

// custom cache to store data
export class Cache<K, V> {
  // default option
  private options = {
    capacity: 2000, // max of 2k items
    duration: 60000, // 1min max time for each key in milli seconds
  };
  private cache;
  constructor(options?: {
    capacity: number;
    duration: number;
    notifyAfterDelete: (key: K) => void;
  }) {
    if (options) {
      this.options = options;
    }
    this.cache = new MapExpire<K, V>(this.options);
  }

  // Method to check for a value in cache
  has(key: K): boolean {
    return !!this.cache.getValue(key);
  }

  // Method to get a value from cache
  get(key: K) {
    return this.cache.getValue(key);
  }

  // Method to create/update a key from the cache
  set(key: K, value: V, duration?: number) {
    this.cache.setValue(key, value, duration || this.options.duration);
  }

  // Method to delete key from the cache
  deleteKey(key: K) {
    this.cache.delete(key);
  }

  // Method to clear value in cache
  clear() {
    this.cache = new MapExpire<K, V>(this.options);
  }

  // Method to get values of the cache
  getCacheEntries(): V[] {
    return this.cache.getEntries();
  }
}
