import Base from "@tiptap/extension-mention";
import { ReactNodeViewRenderer, ReactRenderer } from "@tiptap/react";
import { SuggestionOptions } from "@tiptap/suggestion";
import { PluginKey } from "prosemirror-state";
import tippy, { Instance } from "tippy.js";
import { v4 } from "uuid";
import { SuggestionList } from "../components/SuggestionList";
import { TagRenderer } from "../components/TagRenderer";
import { createNestedTag } from "../utils/createNestedTag";
import { BsPlusSquare } from "react-icons/bs";
import { debounceWithAsync } from "../utils/asyncDebounce";
import { EfNode } from "../types";
import { tagFullNameCache } from "../cache/tagCache";
import { EfNodeType } from "../graphql";
import { sortNodesBasedOnRecency } from "../utils/tags";

const TagPluginKey = new PluginKey("tag");
const separators = ["-", ":", "|", " ", "_", ".", "/"];
const filterFunction = (
  tags: (EfNode & { fullName?: string })[],
  cleanedQuery: string
) => {
  const tagList: ({
    label?: string;
    actualQuery: string;
  } & EfNode)[] = tags
    .map((tag) => ({
      ...tag,
      id: tag.id,
      label: tag.fullName,
      actualQuery: cleanedQuery,
    }))
    .filter((item) => {
      const set = new Set(
        separators.flatMap((separator) => (item.label || "").split(separator))
      );
      return !!Array.from(set).find((key) =>
        key.toLowerCase().startsWith(cleanedQuery.toLowerCase())
      );
    });

  const perfectMatch = tagList.find(
    (tag) => tag.label?.toLowerCase() === cleanedQuery.toLowerCase()
  );
  if (!cleanedQuery || perfectMatch) return tagList;

  const id = v4();
  return [
    {
      id,
      label: `Create #${cleanedQuery}`,
      node: (
        <p className="flex items-center space-x-2">
          <BsPlusSquare /> <span>Create new tag</span> <b>{cleanedQuery}</b>
        </p>
      ),
      relatedNodesModifiedTimeStamp: Infinity,
      titleText: cleanedQuery,
      action: () => {
        createNestedTag(cleanedQuery, id);
      },
    },
    ...tagList,
  ];
};

export const debouncedItemsSearch = debounceWithAsync(
  async ({ query }: { query: string }) => {
    const tags = tagFullNameCache.getCacheEntries();
    // this is done in case where user is trying to create tag like #test.new.... here we will remove
    // all unnecessary periods and just have test.new as final cleanedQuery.
    const cleanedQuery = query
      .split(".")
      .filter((name) => name)
      .join(".");
    const results = filterFunction(tags, cleanedQuery);
    return sortNodesBasedOnRecency({ [EfNodeType.Tag]: results }, cleanedQuery)
      .TAG;
  },
  300
);

const suggestion: Omit<SuggestionOptions, "editor"> = {
  char: "#",
  pluginKey: TagPluginKey,
  items: async ({ query }) => await debouncedItemsSearch({ query }),
  render: () => {
    let reactRenderer: ReactRenderer;
    let popup: Instance[];

    return {
      onStart: (props) => {
        reactRenderer = new ReactRenderer(SuggestionList, {
          props,
          editor: props.editor,
        });
        if (!props.clientRect || !props.editor.isFocused) {
          return;
        }

        // TODO
        // @ts-ignore
        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate(props) {
        if (!reactRenderer?.element?.isConnected) {
          this.onStart?.(props);
        }
        reactRenderer?.updateProps(props);

        if (!popup) return;

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          // @ts-ignore
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (!popup) return;

        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        if (popup[0].state.isVisible) {
          // @ts-ignore
          return reactRenderer?.ref?.onKeyDown(props);
        }
      },

      onExit() {
        popup && popup[0].destroy();
        reactRenderer?.destroy();
      },
    };
  },
};

export const Tag = Base.extend({
  name: "tag",
  priority: 100000,
  addNodeView() {
    return ReactNodeViewRenderer(TagRenderer, {
      update: ({ oldNode, newNode }) => {
        if (oldNode.attrs.id !== newNode.attrs.id) {
          // Ignore update in case prosemirror try to update with other tag
          return false;
        }
        return true;
      },
    });
  },
  renderHTML({ node }) {
    return ["span", { "data-type": this.name, "data-id": node.attrs.id }];
  },
}).configure({
  suggestion,
  HTMLAttributes: {
    spellcheck: false,
  },
});
