import { useState, useEffect } from "react";
import debounce from "lodash/debounce";

// Generic useDebounce hook
export function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    // Update debounced value after the specified delay
    const handler = debounce(() => setDebouncedValue(value), delay);

    // Call the debounced function
    handler();

    // Cancel the debounce on useEffect cleanup
    return handler.cancel;
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
}
