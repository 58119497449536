import { updateNode } from "../utils";
import { HistoryManager } from "../hooks/useHistoryManager";
import {
  ChangeEvent,
  KeyboardEvent,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import { EfNode } from "../types";
import { DataTest } from "../../tests/e2e/utils/constants";

type TitleProps = {
  page: EfNode;
  moveToFirstNodeInViewPort: () => void;
  disableEdit?: boolean;
  history: HistoryManager;
};
class Update {}
const _Title = ({
  page,
  disableEdit,
  moveToFirstNodeInViewPort,
  history,
}: TitleProps) => {
  const [titleText, setTitleText] = useState<string>();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const pendingUpdate = useRef<Update | null>(null);

  useEffect(() => {
    if (pendingUpdate.current) {
      return;
    }
    if (typeof page?.titleText === "string") setTitleText(page.titleText);
  }, [page]);

  useEffect(() => {
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "0px";
        textAreaRef.current.style.height =
          textAreaRef.current.scrollHeight + "px";
      }
    });
  }, [titleText]);

  const onChangePageTitleText = async (
    evt: ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (disableEdit) {
      return;
    }
    const currentText = evt.target.value;
    setTitleText(currentText);

    const lastUpdate = new Update();
    pendingUpdate.current = lastUpdate;
    await new Promise((resolve) => setTimeout(resolve, 300));
    if (pendingUpdate.current !== lastUpdate) {
      return;
    }
    history.run({
      redo: async () => {
        await updateNode({ ...page, titleText: currentText! });
      },
      undo: async () => {
        await updateNode({ ...page });
      },
    });
    pendingUpdate.current = null;
  };

  const onKeyDown = (evt: KeyboardEvent<HTMLTextAreaElement>) => {
    if (["Tab", "Enter"].includes(evt.key)) {
      evt.preventDefault();
      moveToFirstNodeInViewPort();
    }
  };

  if (!page) {
    return null;
  }
  return (
    <div className="relative w-full">
      <div className="font-semibold text-[35px] leading-[120%] w-full">
        <textarea
          className="outline-none bg-white w-full resize-none"
          placeholder="Enter Page Title"
          ref={textAreaRef}
          value={titleText}
          onChange={onChangePageTitleText}
          disabled={disableEdit}
          onKeyDown={onKeyDown}
          data-testid={DataTest.TitleNode}
        />
      </div>
    </div>
  );
};

export const Title = memo(_Title);
