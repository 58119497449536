import { Extension } from "@tiptap/core";
import { generateKeyBetween } from "fractional-indexing";
import { Plugin, PluginKey } from "prosemirror-state";

export const Position = Extension.create({
  name: "position",

  addProseMirrorPlugins() {
    const editor = this.editor;
    return [
      new Plugin({
        key: new PluginKey("position"),
        appendTransaction: (transactions, _prevState, nextState) => {
          const transaction = nextState.tr;
          let modified = false;

          if (transactions.some((transaction) => transaction.docChanged)) {
            nextState.doc.descendants((node, pos, parent, index) => {
              if (modified) return false;

              // attribute was not found
              if (node.attrs["position"] === undefined) return true;
              // attribute is already set
              if (node.attrs["position"] !== null) return true;

              const nodeBefore = parent?.maybeChild(index - 1);
              const nodeAfter = parent?.maybeChild(index + 1);

              // when we have pagination, nodeAfter will be empty but the next page might have a node
              // so we need to use the first node of the next page as the default after position
              const defaultAfterPosition = nodeAfter
                ? null // nodeAfter exists
                : transaction.doc.resolve(pos).depth !== 1
                ? null // node is not a root node
                : editor.storage.rootAfterPosition;

              modified = true;
              transaction.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                position: generateKeyBetween(
                  nodeBefore?.attrs.position,
                  nodeAfter?.attrs.position ?? defaultAfterPosition
                ),
              });
            });
          }

          return modified ? transaction : null;
        },
      }),
    ];
  },
});
