export enum AllAnyOperatorTypes {
  All = "all",
  Any = "any",
}
export const AllAnyOperators = [
  {
    label: AllAnyOperatorTypes.All,
    value: AllAnyOperatorTypes.All,
  },
  {
    label: AllAnyOperatorTypes.Any,
    value: AllAnyOperatorTypes.Any,
  },
];

export const combinatorToAllAnyOperator = (combinator: string) => {
  if (combinator === "and") {
    return AllAnyOperatorTypes.All;
  }
  return AllAnyOperatorTypes.Any;
};

export const allAnyOperatorToCombinator = (operator: string) => {
  if (operator === AllAnyOperatorTypes.All) {
    return "and";
  }
  return "or";
};
