"use client";

import * as React from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { useDebounce } from "@/hooks/useDebounce";
import { Checkbox } from "@/components/ui/checkbox";
import { RadioGroup, RadioGroupItem } from "@/components/ui/Radio";
import { Label } from "@/components/ui/label";

type Item = {
  value: string;
  label: string;
};

interface FilterCommand {
  disabled?: boolean;
  placeholder?: string;
  getItems: (query: string) => Promise<Item[]> | Item[];
  selectedItems?: string[];
  onSelect?: (value: string) => void;
  icon?: React.ReactNode;
  radio?: boolean;
}
export function FilterCommand({
  placeholder,
  getItems,
  selectedItems,
  onSelect,
  icon,
  disabled,
  radio,
}: FilterCommand) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState<Item[]>([]);
  const debouncedSearchQuery = useDebounce(searchQuery, 100);

  React.useEffect(() => {
    const search = async () => {
      setIsLoading(true);
      const items = await getItems(debouncedSearchQuery);
      setItems(items);
      setIsLoading(false);
    };
    search();
  }, [debouncedSearchQuery]);

  const renderItems = () => {
    const commandItems = items.map((item) => (
      <CommandItem
        disabled={disabled}
        key={item.value}
        value={item.value}
        onSelect={(value) => {
          onSelect?.(value);
        }}
        className="flex flex-row gap-2 bg-white"
      >
        {radio && (
          <div className="flex items-center space-x-2">
            <RadioGroupItem
              value={item.value}
              id={item.label}
              onSelect={() => {
                onSelect?.(item.value);
              }}
            />
            <Label htmlFor={item.label}>{item.label}</Label>
          </div>
        )}
        {!radio && (
          <>
            <Checkbox checked={selectedItems?.includes(item.value)} />
            {icon && icon}
            <span>{item.label}</span>
          </>
        )}
      </CommandItem>
    ));

    if (radio) {
      return <RadioGroup value={selectedItems?.[0]}>{commandItems}</RadioGroup>;
    }
    return commandItems;
  };

  return (
    <Command shouldFilter={false} className="p-0 gap-0">
      <CommandInput
        value={searchQuery}
        onValueChange={setSearchQuery}
        placeholder={placeholder}
        className="py-1"
      />
      <CommandList className="p-0">
        {isLoading && <CommandEmpty>Loading...</CommandEmpty>}
        {!isLoading && <CommandEmpty>No results found.</CommandEmpty>}
        {!isLoading && <CommandGroup>{renderItems()}</CommandGroup>}
      </CommandList>
    </Command>
  );
}
