import React, { useContext, useEffect } from "react";
import { ReactNode, createContext, useState } from "react";
import { useLocation } from "react-router-dom";

interface TitleContext {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const MenuContext = createContext<TitleContext>(null!);

export function MenuContextProvider({ children }: { children: ReactNode }) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.key]);

  return (
    <MenuContext.Provider value={{ isMenuOpen, setIsMenuOpen }}>
      {children}
    </MenuContext.Provider>
  );
}

export const useMenu = () => {
  return useContext(MenuContext);
};
