import { type Editor } from "@tiptap/core";
import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Code } from "@tiptap/extension-code";
import { CodeBlock } from "@tiptap/extension-code-block";
import Document from "@tiptap/extension-document";
import { Dropcursor } from "@tiptap/extension-dropcursor";
import { Gapcursor } from "@tiptap/extension-gapcursor";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Heading } from "@tiptap/extension-heading";
import { History } from "@tiptap/extension-history";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";
import { Italic } from "@tiptap/extension-italic";
import { Link } from "@tiptap/extension-link";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Paragraph } from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import { Strike } from "@tiptap/extension-strike";
import { Text } from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import { isMobile } from "react-device-detect";
import { CustomHistory } from "./CustomHistory";
import { Mention } from "./Mention";
import { Node } from "./Node";
import { NodeEmbed } from "./NodeEmbed";
import { NodeList } from "./NodeList";
import { Page } from "./Page";
import { Position } from "./Position";
import { PageRef } from "./PageRef";
import { Slash } from "./Slash";
import { Tag } from "./Tag";
import { File as FileNode } from "./File";
import { ThoughtPad } from "./ThoughtPad";
import { Title } from "./Title";
import { UniqueId } from "./UniqueId";
import { DataTest } from "../../tests/e2e/utils/constants";
import { Paste } from "./Paste";
import { SystemPage } from "./SystemPage";
import { DoubleSpacePeriodPlugin } from "./DoubleSpacePeriod";

export type ExtensionsOptions = {
  docContent: string;
  submitHandler?: (editor: Editor) => void;
};

export const makeExtensions = (options: ExtensionsOptions) => {
  const mobileExtensions = [DoubleSpacePeriodPlugin];
  const baseExtensions = [
    Placeholder.configure({
      includeChildren: true,
      showOnlyCurrent: false,
      placeholder: ({ node }) => {
        if (node.type.name === "title") {
          return "Enter Page Title";
        }
        return "";
      },
    }),
    Document.extend({
      content: options.docContent,
    }),
    Blockquote,
    Bold,
    Code,
    CodeBlock,
    Dropcursor,
    Gapcursor,
    HardBreak,
    Heading.extend({
      addInputRules() {
        return [];
      },
    }).configure({
      levels: [1, 2, 3],
    }),
    History,
    HorizontalRule,
    Italic,
    Link.configure({
      HTMLAttributes: {
        class: "link",
      },
    }),
    Mention,
    Strike,
    Text,
    Tag,
    FileNode,
    PageRef,
    Slash,
    Underline,
    Title.extend({ priority: 101 }).configure({
      HTMLAttributes: {
        "data-testid": DataTest.TitleNode,
      },
    }),
    UniqueId,
    Position,
    Page,
    ThoughtPad,
    SystemPage,
    Node.configure({
      submitHandler: options.submitHandler,
    }),
    NodeList,
    // NodeContent,
    NodeEmbed,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem.extend({ priority: 101 }),
    CustomHistory.extend({ priority: 100000 }),
    Paste,
  ];
  return isMobile ? [...baseExtensions, ...mobileExtensions] : baseExtensions;
};

export const defaultExtensions = makeExtensions({
  docContent: "EfPage | EfSystemPage | EfThoughtPad | EfNode",
});
