import { DBCore, Middleware } from "dexie";
import { isIOS } from "react-device-detect";

export enum AppState {
  Background = "Background",
  Active = "Active",
}

const shouldAbort = () => isIOS && window.appState === AppState.Background;

export class IOSBackgroundAbort extends Error {
  constructor() {
    super("Transaction Aborted: App in background");
  }
}

// Middleware that aborts transactions when app is in the background to avoid crashing when indexdb get killed by system
export const mobileBackgroundMiddleware: Middleware<DBCore> = {
  stack: "dbcore",
  name: "mobileBackgroundMiddleware",
  create(downlevelDatabase) {
    return {
      ...downlevelDatabase,
      table(tableName) {
        const downlevelTable = downlevelDatabase.table(tableName);
        return {
          ...downlevelTable,
          mutate: async (req) => {
            // When on ios and in background abort all transactions
            if (shouldAbort()) {
              throw new IOSBackgroundAbort();
            }
            return downlevelTable.mutate(req);
          },
        };
      },
    };
  },
};
