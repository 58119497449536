import { NodeViewContent, NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";
import { DataTest } from "../../tests/e2e/utils/constants";
import { EfNodeType } from "../graphql";
import { FileNode } from "./FileRenderer";
import { useState } from "react";
import { TextSelection } from "prosemirror-state";

export function NodeRenderer({
  editor,
  node,
  updateAttributes,
  getPos,
}: NodeViewProps) {
  const hasChildren = node.childCount === 2;
  const enableCollapse = hasChildren && editor.isEditable;
  let headingLevel = 0;
  if (node?.firstChild?.type === editor.schema.nodes.heading) {
    headingLevel = node.firstChild.attrs.level;
  }
  const [searchParams] = useSearchParams();
  const nodeParam = searchParams.get("node");
  const isHighlightedNode = nodeParam && nodeParam === node.attrs.id;
  const [isHighlighted, setIsHighlighted] = useState(isHighlightedNode);

  const setSelectionToNode = () => {
    const { view } = editor;
    const { tr } = view.state;
    const startPos = getPos();
    const contentSize = node.nodeSize - 2;
    const endPos = startPos + contentSize;
    const selection = TextSelection.create(tr.doc, endPos);
    editor.view.focus();
    editor.view.dispatch(tr.setSelection(selection));
  };

  return (
    <NodeViewWrapper
      className={clsx("flex items-stretch add-padding", {
        ["animate-highlight"]: isHighlighted,
      })}
      ref={(el: HTMLDivElement) => {
        if (!el) return;
        if (!isHighlighted) return;
        // Scroll to selected element
        setSelectionToNode();

        // There is some race condition that sometimes prevents scroll-into view from working
        // So we introduce a short timeout, this part can be improved
        setTimeout(() => {
          el.scrollIntoView({ behavior: "smooth", block: "center" });
          setTimeout(() => {
            setIsHighlighted(false);
          }, 2000);
        }, 500);
      }}
      data-testid={DataTest.EfNode}
    >
      <div
        className={clsx(
          {
            ["!h-9"]: headingLevel === 1,
            ["!h-6"]: headingLevel === 2,
            ["!h-5"]: headingLevel === 3,
          },
          "h-4 mr-1 pt-4 space-x-2 flex items-center justify-center"
        )}
        contentEditable={false}
      >
        <button
          type="button"
          className={clsx(
            "w-[5px] h-[5px] rounded-full box-content border-[6px] bg-[#374151]",
            enableCollapse && "hover:border-gray-200",
            node.attrs.collapsed ? "border-gray-200" : "border-white"
          )}
          disabled={!enableCollapse}
          onClick={() => {
            updateAttributes({
              collapsed: !node.attrs.collapsed,
            });
          }}
        />

        {node.attrs.subType === EfNodeType.Task && (
          <input
            type="checkbox"
            data-testid={DataTest.CheckBox}
            checked={node.attrs.taskStatus === "COMPLETED"}
            onChange={() => {
              updateAttributes({
                taskStatus:
                  node.attrs.taskStatus === "COMPLETED"
                    ? "PENDING"
                    : "COMPLETED",
              });
            }}
            tabIndex={-1}
            disabled={!editor.isEditable}
          />
        )}
      </div>
      <div
        className={clsx(
          "flex-1 overflow-hidden",
          node.attrs.collapsed && "ef-node-collapsed"
        )}
      >
        {node.attrs.subType === EfNodeType.File && (
          <FileNode id={node.attrs.id} properties={node.attrs} />
        )}
        <NodeViewContent />
      </div>
    </NodeViewWrapper>
  );
}
