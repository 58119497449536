import { RefObject, useEffect, useReducer, useState } from "react";
import { IconContext } from "react-icons";
import { useIosKeyboardHeight } from "../../../hooks/useIosKeyboardHeight";
import clsx from "clsx";
import { newBtn } from "../../../styles/classes";
import { VirtualizedEditorState } from "../VirtualizedEditor";
import { ToolbarType } from "./types";
import { options, toolbarStyles } from "./options";
import { HistoryManager } from "../../../hooks/useHistoryManager";
import { getEditorsMap } from "../../SelectionWrapper/selectionUtils";
import { EfNode } from "../../../types";
import { KeyboardHideIcon } from "../../Icons";
import { useMenu } from "@/context/MenuContext";

export const BOTTOM_TOOLBAR_HEIGHT = 45;

interface MobileToolbarProps {
  state: Pick<VirtualizedEditorState, "nodes">;
  currentId: string | null; // current focused node id
  setCurrentId: (id: string | null) => void;
  historyManager: HistoryManager;
  toolbarRef: RefObject<HTMLDivElement>;
  jumpToTop: (node: EfNode) => Promise<void>;
  relativeDepth?: number;
}
export function MobileToolbar({
  state,
  currentId,
  setCurrentId,
  historyManager,
  toolbarRef,
  jumpToTop,
  relativeDepth,
}: MobileToolbarProps) {
  const iosKeyboardHeight = useIosKeyboardHeight();
  const [toolbarType, setToolbarType] = useState(ToolbarType.Main);
  const editor = currentId ? getEditorsMap()[currentId] : undefined;
  const { isMenuOpen } = useMenu();
  const [, forceUpdate] = useReducer((counter) => counter + 1, 0);
  // Find node in state
  const getCurrentNode = () => state.nodes.find((n) => n.id === currentId);

  useEffect(() => {
    if (!editor && !currentId) {
      // Reset formatting mode
      setToolbarType(ToolbarType.Main);
    }
  }, [editor, currentId]);

  useEffect(() => {
    // Reset toolbar type when node changes
    setToolbarType(ToolbarType.Main);
  }, [currentId]);

  useEffect(() => {
    // When selecting text show formatting toolbar
    if (!editor) return;
    let formattingShown = false;
    const onSelectionUpdate = () => {
      const selectionEmpty = editor.state.selection.empty;
      if (selectionEmpty) {
        // If selection is empty, show main toolbar
        setToolbarType(ToolbarType.Main);
        // and reset formatting shown
        formattingShown = false;
      } else if (toolbarType !== ToolbarType.Formatting && !formattingShown) {
        setToolbarType(ToolbarType.Formatting);
        formattingShown = true;
      }
    };
    editor.on("selectionUpdate", onSelectionUpdate);
    return () => {
      editor.off("selectionUpdate", onSelectionUpdate);
    };
  }, [editor, toolbarType]);

  if ((!editor && !currentId) || isMenuOpen || !iosKeyboardHeight) return null;

  return (
    <div
      style={{ height: BOTTOM_TOOLBAR_HEIGHT, bottom: iosKeyboardHeight }}
      className={clsx("flex border-t absolute w-full bg-white left-0 z-50")}
      ref={toolbarRef}
      tabIndex={-1} // needed for relatedTarget on blur event on iOS
    >
      <div className={toolbarStyles[toolbarType]}>
        {options[toolbarType].map(
          ({ onClick, component, isActive, isDisabled, name }) => {
            const active = editor && isActive ? isActive(editor) : false;
            const disabled = editor && isDisabled ? isDisabled(editor) : false;
            return (
              <IconContext.Provider
                value={{
                  className: clsx(
                    "w-5 h-5",
                    active ? "text-blue-700" : "text-gray-400"
                  ),
                }}
                key={name}
              >
                <button
                  className={clsx(
                    newBtn,
                    active ? "bg-blue-200" : "",
                    disabled ? "opacity-50" : ""
                  )}
                  onClick={() => {
                    const node = getCurrentNode();
                    if (!node) return;
                    if (editor) editor.commands.focus();
                    onClick({
                      node,
                      editor,
                      setToolbarType,
                      historyManager,
                      setCurrentId,
                      jumpToTop,
                      relativeDepth,
                    });
                    // Sometimes after clicking a button react doesn't re-render so we don't
                    // see any update (eg: bold toggle). So we force a re-render after clicking a toolbar button
                    forceUpdate();
                  }}
                  key={name}
                >
                  {component}
                </button>
              </IconContext.Provider>
            );
          }
        )}
      </div>
      {/* hide keyboard button */}
      {toolbarType === ToolbarType.Main && (
        <button
          onClick={() => {
            if (!editor) return;
            editor.commands.blur();
            setCurrentId(null);
          }}
          className="bg-white"
          style={{
            display: "flex",
            position: "absolute",
            right: 0,
            width: 40,
            height: BOTTOM_TOOLBAR_HEIGHT,
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "4px 0px 10px 0px #0000000D inset",
          }}
        >
          <KeyboardHideIcon />
        </button>
      )}
    </div>
  );
}
