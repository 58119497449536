import { deepEqual } from "fast-equals";
import { EfNodeEditorData } from "../types";

export function diffEditorNodes(
  oldNodes: Map<string, EfNodeEditorData>,
  newNodes: Map<string, EfNodeEditorData>
) {
  const createdNodes = [];
  const deletedNodes = [];
  const updatedNodes = [];

  for (const [id, node] of newNodes) {
    if (!oldNodes.has(id)) {
      createdNodes.push(node);
    } else {
      if (!deepEqual(node, oldNodes.get(id))) {
        updatedNodes.push(node);
      }
      oldNodes.delete(id);
    }
  }

  for (const [id, node] of oldNodes) {
    if (!newNodes.has(id)) {
      deletedNodes.push(node);
    }
  }

  return { createdNodes, deletedNodes, updatedNodes };
}
