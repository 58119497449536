import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { btn } from "../styles/classes";

export function UpdateNotification() {
  const [show, setShow] = useState(false);
  const initialCotentRef = useRef<string | null>(null);

  /**
   * Fetch the content of the exec's root html file.
   * If the request fails, retry after 1 second.
   * @returns The content of the exec's root html file.
   */
  const fetchContent = async (): Promise<string> => {
    try {
      const response = await fetch("/index.html");
      if (!response.ok) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return fetchContent();
      }
      return response.text();
    } catch {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return fetchContent();
    }
  };

  useEffect(() => {
    const setInitialContent = async () => {
      const content = await fetchContent();
      initialCotentRef.current = content;
    };
    setInitialContent();

    /**
     * Interval to check if the content of the exec's root html file has changed.
     */
    let running = false;
    const interval = setInterval(async () => {
      if (running) return; // Make sure this interval does not run twice at the same time
      running = true;

      const initialContent = initialCotentRef.current;
      if (!initialContent) return;
      const content = await fetchContent();
      running = false; // Unlock the interval

      if (content !== initialContent) {
        // If the content has changed show the notification
        setShow(true);
      }
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, []);

  if (!show) return null; //

  return (
    <div className="border-t p-3 gap-1 flex">
      <p className="text-sm">A new version is available. Reload to update.</p>
      <button
        className={clsx(btn, "bg-yellow-300 hover:bg-yellow-400")}
        onClick={() => window.location.reload()}
      >
        Reload
      </button>
    </div>
  );
}
