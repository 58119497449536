export const debounceWithAsync = (
  func: (input: any) => any,
  wait: number,
  leading = false
) => {
  let timer: NodeJS.Timeout | undefined = undefined;
  return async (...args: any[]): Promise<any[]> => {
    return new Promise((resolve) => {
      if (!timer && leading) {
        // this check to call passed method first time and then debounce for next calls.
        resolve(func.apply(this, args as any));
      }
      clearTimeout(timer);
      timer = setTimeout(async () => {
        resolve(func.apply(this, args as any));
        timer = undefined;
      }, wait);
    });
  };
};
