import invariant from "tiny-invariant";
import { v4 } from "uuid";
import { db } from "../db";
import { EfNodeType } from "../graphql";
import { EfNode } from "../types";
import { createTagNode } from "./tags";

export async function createNestedTag(fullName: string, finalId: string) {
  const tags = await db.nodes
    .where("nodeType")
    .equals(EfNodeType.Tag)
    .toArray();

  const parts = fullName.split(".");
  const lastPart = parts.pop();

  let parent: EfNode | undefined;

  for (const part of parts) {
    const existing = tags.find(
      (tag) =>
        tag.titleText?.toLowerCase() === part.toLowerCase() &&
        !tag.deleted &&
        tag.parentId === (parent?.id ?? null)
    );

    if (existing) {
      parent = existing;
      continue;
    }

    const id = v4();
    await createTagNode(id, part, parent?.id ?? null);

    const tag = await db.nodes.get(id);
    parent = tag;
  }

  invariant(lastPart, "lastPart should exist");

  await createTagNode(finalId, lastPart, parent?.id ?? null);
}
