import { RefObject, useCallback, useEffect, useRef } from "react";

export const useResizeObserver = (resizeCallback: ResizeObserverCallback) => {
  const resizeObserverRef = useRef<ResizeObserver | null>();

  return useCallback((ele: Element | null) => {
    if (!resizeObserverRef.current && ele) {
      const resizeObserver = new ResizeObserver(resizeCallback);
      resizeObserver.observe(ele);
      resizeObserverRef.current = resizeObserver;
      return;
    }
    if (resizeObserverRef.current && !ele) {
      resizeObserverRef.current?.disconnect();
      return;
    }
  }, []);
};
