import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { GoTasklist } from "react-icons/go";
import { DataTest } from "../../../tests/e2e/utils/constants";
import { sidebarBtn, sidebarBtnActive } from "../../styles/classes";

export function Tasks() {
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex items-center space-x-2 pl-8">
        <GoTasklist />
        Tasks
      </div>
      <div className="flex flex-col space-y-2">
        <NavLink to={`/tasks/pending`}>
          {({ isActive }) => (
            <div
              className={clsx(
                sidebarBtn,
                isActive && sidebarBtnActive,
                "pl-12"
              )}
              data-testid={DataTest.Pending}
            >
              <span className="w-2 h-2 rounded-md bg-red-500 mr-2" />
              Pending
            </div>
          )}
        </NavLink>
        <NavLink to={`/tasks/completed`}>
          {({ isActive }) => (
            <div
              className={clsx(
                sidebarBtn,
                isActive && sidebarBtnActive,
                "pl-12"
              )}
              data-testid={DataTest.Completed}
            >
              <span className="w-2 h-2 rounded-md bg-green-500 mr-2" />
              Completed
            </div>
          )}
        </NavLink>
      </div>
    </div>
  );
}
