import clsx from "clsx";
import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import {
  MdAdd,
  MdChevronRight,
  MdClose,
  MdExpandMore,
  MdDragIndicator,
  MdSort,
} from "react-icons/md";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { DataTest } from "../../../tests/e2e/utils/constants";
import { db } from "../../db";
import { ImMoveUp } from "react-icons/im";
import { BiFolder, BiNotepad, BiSearch } from "react-icons/bi";
import { AiOutlineFileText } from "react-icons/ai";
import { TbClipboardText, TbSortAscending } from "react-icons/tb";
import { EfNodeType } from "../../graphql";
import { btn, sidebarBtn, sidebarBtnActive } from "../../styles/classes";
import { EfNode } from "../../types";
import { deleteNodeAndChildren, updateNode } from "../../utils";
import { createPageNode } from "../../utils/pages";
import { useTreeDrop } from "../../hooks/useTreeDrop";
import { useSectionDetails } from "../../hooks/useSectionDetails";
import { SidePanelAction, SidePanelActionType } from "./SidePanelAction";
import { cloneDeep, isNil } from "lodash";
import { getEmptyImage } from "react-dnd-html5-backend";
import { getParentNodes } from "../../utils/nodes";
import { ExecNotesIcon } from "../Icons";
import { sortSidePanelTree } from "./utils/sidePanelActionUtils";
import { BsSortAlphaUp } from "react-icons/bs";

export function PageTree() {
  const navigate = useNavigate();

  const pages = useLiveQuery(() =>
    db.nodes.where("nodeType").equals(EfNodeType.Page).toArray()
  );
  const {
    isCollapsed,
    sortBy,
    sortOrder,
    manualSortOrder,
    setCollapsed,
    setSortByOrSortOrder,
    setManualSortOrder,
  } = useSectionDetails("page");
  const systemPages: EfNode[] = [];

  let userPages = (pages || []).filter((page) => {
    if (page.deleted) {
      // In case of deleted dont include on both
      return false;
    }
    const isUserPage = !page.properties?.isSystemCreated;
    if (isUserPage) {
      return true;
    }
    systemPages.push(page);
    return false;
  });

  const updateManualOrder = (sortedPages: EfNode[]) => {
    setManualSortOrder(sortedPages.map(({ id }) => id));
  };

  const onChangeSortOrder = (key: "sortBy" | "sortOrder", value: string) => {
    if (key === "sortBy" && value === "manual" && !manualSortOrder?.length) {
      updateManualOrder(userPages);
    }
    setSortByOrSortOrder(key, value);
  };

  userPages = sortSidePanelTree(userPages, sortBy, manualSortOrder, sortOrder);

  return (
    <div>
      <div className="flex flex-col mb-5">
        <div className="flex flex-col gap-[6px]">
          <NavLink to={`/search`}>
            {({ isActive }) => (
              <div
                className={clsx(
                  "pl-7 !ml-1 mr-1",
                  sidebarBtn,
                  isActive && sidebarBtnActive
                )}
                data-testid={DataTest.Search}
              >
                <BiSearch className="mr-1" />
                Search
              </div>
            )}
          </NavLink>
          <NavLink to={`/thoughtpad`}>
            {({ isActive }) => (
              <div
                className={clsx(
                  "pl-7 !ml-1 mr-1",
                  sidebarBtn,
                  isActive && sidebarBtnActive
                )}
                data-testid={DataTest.EfThoughtPad}
              >
                <BiNotepad className="mr-1" />
                ThoughtPad
              </div>
            )}
          </NavLink>
        </div>
        {systemPages.map((systemPage) => (
          <PageNode
            hideActions
            key={systemPage.id}
            page={systemPage}
            pages={systemPages}
            canDrag={false}
            canDrop={false}
          />
        ))}
      </div>
      <div className="flex items-center justify-between pr-1 pl-3">
        <button
          onClick={setCollapsed}
          className={clsx("grow", {
            ["pointer-events-none"]: !userPages.length,
          })}
        >
          <div className="flex items-center">
            {isCollapsed ? (
              <MdChevronRight
                className={clsx("w-5 h-5", {
                  ["invisible"]: !userPages.length,
                })}
              />
            ) : (
              <MdExpandMore
                className={clsx("w-5 h-5", {
                  ["invisible"]: !userPages.length,
                })}
              />
            )}
            <BiFolder />
            <div className="ml-4">Pages</div>
          </div>
        </button>

        <div className="flex">
          <SidePanelAction
            sortBy={sortBy}
            sortOrder={sortOrder}
            onChange={onChangeSortOrder}
            sidePanelActions={pageSidePanelActions}
          />
          <button
            className={clsx(btn, "!p-1")}
            onClick={async () => {
              const id = v4();
              await createPageNode(id, "", null);
              navigate(`/pages/${id}`);
              isCollapsed && setCollapsed();
            }}
            data-testid={DataTest.CreatePage}
          >
            <MdAdd className="w-5 h-5" />
          </button>
        </div>
      </div>

      {!isCollapsed && (
        <PageNode
          page={null}
          pages={userPages}
          canDrop={sortBy === "manual"}
          canDrag={sortBy === "manual"}
          showDragHandles={sortBy === "manual"}
          updateManualOrder={updateManualOrder}
        />
      )}
    </div>
  );
}

export function PageNode({
  page,
  pages,
  canDrop,
  canDrag,
  hideActions,
  index,
  isLast,
  showDragHandles,
  level = 0,
  updateManualOrder,
  markParentAsOver,
}: {
  page: EfNode | null;
  pages: EfNode[];
  canDrop: boolean;
  canDrag: boolean;
  hideActions?: boolean;
  index?: number;
  isLast?: boolean;
  showDragHandles?: boolean;
  level?: number;
  updateManualOrder?: (nodes: EfNode[]) => void;
  markParentAsOver?: (isOverFromChild: boolean) => void;
}) {
  const parentId = page ? page.id : null;
  const childPages = pages.filter((page) => page.parentId === parentId);
  const hasChildren = childPages.length > 0;
  const isCollapsed = page?.properties?.collapsed === true;
  const [isOverLastFromChild, setIsOverLastFromChild] = useState(false);

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      item: {
        itemValue: page,
        index,
        level,
        hasChildren,
      },
      type: EfNodeType.Page,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [page]
  );

  // This adds empty image to the Drag Preview
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const changePosition = (currentIndex: number, hoverIndex?: number) => {
    if (isNil(hoverIndex)) {
      return;
    }
    const clonedCards = [...cloneDeep(pages)];
    const removedItem = clonedCards.splice(currentIndex, 1)[0];
    clonedCards.splice(hoverIndex, 0, removedItem);
    updateManualOrder?.(clonedCards);
  };

  const onClickMoveToTop = useCallback(
    (evt: any) => {
      if (!page) {
        return;
      }
      showDragHandles && evt.preventDefault();
      showDragHandles && evt.stopPropagation();
      const clonedCards = cloneDeep(pages);
      updateManualOrder?.([
        page,
        ...clonedCards.filter(({ id }) => page.id !== id),
      ]);
    },
    [pages, updateManualOrder, page]
  );

  const {
    drop,
    dropBetween,
    dropBetweenLast,
    isOverCurrent,
    isOverCurrentBetween,
    isOverCurrentBetweenLast,
  } = useTreeDrop({
    canDrop,
    isDragging,
    node: page,
    accept: EfNodeType.Page,
    index,
    changePosition,
  });

  const showDropOutline =
    canDrop && !isDragging && (isOverCurrent || isOverLastFromChild);

  useEffect(() => {
    markParentAsOver?.(isOverCurrentBetweenLast || isOverCurrentBetween);
  }, [isOverCurrentBetweenLast, isOverCurrentBetween]);

  return (
    <>
      {page && (
        <div
          ref={dropBetween}
          className={`relative h-[6px] w-full ${
            canDrag && canDrop && isOverCurrentBetween && "bg-blue-400"
          }`}
        />
      )}
      <div ref={drop} data-testid={DataTest.PageTitle}>
        <div
          className={clsx(
            // Outline is only added to nodes with parents
            showDropOutline && "!bg-blue-200",
            isDragging && "opacity-40"
          )}
        >
          {page && (
            <NavLink key={page.id} to={`/pages/${page.id}`}>
              {({ isActive }) => (
                <div ref={drag} className="w-full">
                  <PageElement
                    page={page}
                    isActive={isActive}
                    hasChildren={hasChildren}
                    hideActions={hideActions}
                    level={level}
                    showDragHandles={showDragHandles}
                    onClickMoveToTop={onClickMoveToTop}
                  />
                </div>
              )}
            </NavLink>
          )}
          {hasChildren && !isCollapsed && (
            <div
              className={clsx(parentId && showDropOutline && "!bg-blue-200")}
            >
              {childPages.map((page, idx) => {
                return (
                  <PageNode
                    key={page.id}
                    page={page}
                    pages={pages}
                    canDrop={canDrop && !isDragging}
                    canDrag={canDrag}
                    level={level + 1}
                    showDragHandles={showDragHandles}
                    index={pages.findIndex(({ id }) => id === page.id)}
                    isLast={childPages.length - 1 === idx}
                    updateManualOrder={updateManualOrder}
                    markParentAsOver={(value) => {
                      setIsOverLastFromChild(value);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      {isLast && (
        <div
          ref={dropBetweenLast}
          className={`relative h-[6px] ${
            canDrag && canDrop && isOverCurrentBetweenLast && "bg-blue-400"
          }`}
        />
      )}
    </>
  );
}

export const PageElement = ({
  page,
  isActive,
  showDragHandles,
  hasChildren,
  hideActions,
  level,
  onClickMoveToTop,
}: {
  page: EfNode | null;
  isActive: boolean;
  showDragHandles?: boolean;
  hasChildren: boolean;
  hideActions?: boolean;
  level: number;
  onClickMoveToTop?: (evt: any) => void;
}) => {
  const { id } = page || {};
  const getIcon = () => {
    switch (page?.titleText) {
      case "Files": {
        return <AiOutlineFileText className="mr-1" />;
      }
      case "ClipBoard": {
        return <TbClipboardText className="mr-1" />;
      }
      case "ExecNotes": {
        return <ExecNotesIcon className="mr-1" />;
      }
    }
  };

  return (
    <div
      className={clsx(
        "px-1 !ml-1 mr-1",
        sidebarBtn,
        isActive && sidebarBtnActive,
        "group truncate items-stretch"
      )}
    >
      <div>
        {!hideActions && (
          <div className="flex items-center">
            <ImMoveUp
              title="move to top"
              onClick={onClickMoveToTop}
              className={clsx("my-1 ml-2 text-lg opacity-0 ", {
                ["group-hover:opacity-100"]: showDragHandles,
              })}
            />
            <MdDragIndicator
              className={clsx("my-1 ml-2", { ["opacity-0"]: !showDragHandles })}
            />
          </div>
        )}
      </div>
      <div
        {...(!hideActions
          ? {
              style: {
                marginLeft: `${16 * ((level || 1) - 1)}px`,
              },
            }
          : { style: { marginLeft: 16 } })}
        className="flex items-center justify-between min-w-0 w-full"
      >
        {!hideActions && <PageNodeCollapse page={page!} hide={!hasChildren} />}
        {getIcon()}
        <div className="flex-1 truncate" data-testid={DataTest.FileNode}>
          {page?.titleText}
        </div>
        {!hideActions && <PageNodeActions page={page!} />}
      </div>
    </div>
  );
};

export function PageNodeCollapse({
  page,
  hide,
}: {
  page: EfNode;
  hide: boolean;
}) {
  return (
    <button
      className={clsx("cursor-pointer", { ["opacity-0"]: hide })}
      onClick={(e) => {
        e.preventDefault();
        updateNode({
          ...page,
          properties: {
            ...page.properties,
            collapsed: !page?.properties?.collapsed,
          },
        });
      }}
    >
      {page?.properties?.collapsed ? (
        <MdChevronRight className="w-5 h-5" />
      ) : (
        <MdExpandMore className="w-5 h-5" />
      )}
    </button>
  );
}

export function PageNodeActions({ page }: { page: EfNode }) {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className="space-x-2 sm:space-x-1 flex items-center justify-center">
      <button
        className="cursor-pointer lg:opacity-0 group-hover:opacity-100 transition-all"
        onClick={async (evt) => {
          evt.preventDefault();
          const id = v4();
          await createPageNode(id, "", page.id);
          navigate(`/pages/${id}`);
        }}
      >
        <MdAdd className="w-4 h-4 sm:w-5 sm:h-5" />
      </button>
      <button
        className="cursor-pointer lg:opacity-0 group-hover:opacity-100 transition-all"
        data-testid={DataTest.DeletePage}
        onClick={async (evt) => {
          if (params.pageId !== page.id) {
            evt.preventDefault();
          }
          const confirmed = confirm(`Are you sure?`);
          if (!confirmed) return;
          await deleteNodeAndChildren(page);
          const parentPages = await getParentNodes(params.pageId!);
          if (parentPages.some(({ deleted }) => deleted)) {
            navigate("/");
          }
        }}
      >
        <MdClose className="w-4 h-4 sm:w-5 sm:h-5" />
      </button>
    </div>
  );
}

const pageSidePanelActions: SidePanelActionType[] = [
  {
    value: "sort",
    defaultMenuButton: <MdSort />,
    menuItems: [
      {
        Icon: TbSortAscending,
        value: "sortBy",
        displayName: "Sort By",
        showArrowIcon: true,
        menuItems: [
          {
            value: "createdDate",
            keyToUpdate: "sortBy",
            displayName: "Date Created",
          },
          {
            value: "name",
            keyToUpdate: "sortBy",
            displayName: "Alphabetical",
          },
          {
            value: "manual",
            keyToUpdate: "sortBy",
            displayName: "Manual",
          },
        ],
      },
      {
        Icon: BsSortAlphaUp,
        value: "sortOrder",
        displayName: "Sort Order",
        showArrowIcon: true,
        isDisabled: (value: any) => ["manual", "recency"].includes(value),
        menuItems: [
          {
            value: "increasing",
            keyToUpdate: "sortOrder",
            displayName: "Ascending",
          },
          {
            value: "decreasing",
            keyToUpdate: "sortOrder",
            displayName: "Descending",
          },
        ],
      },
    ],
  },
];
