import EventEmitter from "events";

class Entity<K, V> {
  private key: K;
  private _value: V;
  private expire: number;
  private timer: 0 | NodeJS.Timeout;
  private notifyAfterDelete?: (key: K) => void;

  constructor(
    key: K,
    value: V,
    map: MapExpire<K, V>,
    duration: number,
    notifyAfterDelete?: (key: K) => void
  ) {
    this.key = key;
    this._value = value;
    this.expire = duration && Date.now() + duration;
    this.notifyAfterDelete = notifyAfterDelete;
    this.timer =
      duration &&
      setTimeout(() => {
        map.delete(key);
        this.notifyAfterDelete?.(key);
      }, duration);
  }

  update(newValue: V, map: MapExpire<K, V>, duration?: number) {
    this._value = newValue;
    if (duration) {
      if (this.timer) clearTimeout(this.timer);
      this.expire = Date.now() + duration;
      this.timer = setTimeout(() => {
        map.delete(this.key);
        this.notifyAfterDelete?.(this.key);
      }, duration);
    }
  }

  get value() {
    return this._value;
  }
}

export class MapExpire<K, V> extends Map<K, Entity<K, V>> {
  private events: any;
  private capacity: number;
  private duration: number;
  private notifyAfterDelete?: (key: K) => void;
  constructor(options: {
    capacity: number;
    duration: number;
    notifyAfterDelete?: (key: K) => void;
  }) {
    super();
    this.events = new EventEmitter();
    this.capacity = options && options.capacity;
    this.duration = options && options.duration;
    this.notifyAfterDelete = options.notifyAfterDelete;
  }

  setValue(key: K, value: V, duration?: number) {
    const entity = super.get(key);
    if (entity) {
      entity.update(value, this, duration);
      this.events.emit("update", key, value);
    } else {
      super.set(
        key,
        new Entity(
          key,
          value,
          this,
          duration || this.duration,
          this.notifyAfterDelete
        )
      );
      this.events.emit("set", key, value);
    }
    this.clean();
  }

  getValue(key: K) {
    const entity = super.get(key);
    return entity?.value;
  }

  delete(key: K) {
    const superKey = super.get(key);
    if (superKey) this.events.emit("delete", key, superKey.value);
    return super.delete(key);
  }

  clear() {
    for (let key of this.keys()) {
      this.delete(key);
    }
    super.clear();
  }

  clean() {
    const { size, capacity } = this;
    if (!capacity || size <= capacity) return;
    var keys = this.keys();
    while (this.size > this.capacity) {
      var key = keys.next().value;
      this.delete(key);
    }
  }

  getEntries() {
    return Array.from(this.entries()).map(
      ([_, entity]: [K, Entity<K, V>]) => entity.value
    );
  }

  on(event: any, callback: any) {
    this.events.on(event, callback);
  }
}
