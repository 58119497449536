import { db } from "@/db";
import { EfNodeType } from "@/graphql";
import { getPagePropertiesInSettings } from "@/utils/settings";
import { Params } from "react-router-dom";

export const getThoughtpadProperties = async () => {
  const thoughtpad = await db.nodes
    .where("nodeType")
    .equals(EfNodeType.ThoughtPad)
    .first();
  const pageProperties = await getPagePropertiesInSettings();
  return {
    thoughtpad,
    thoughtpadProperties: pageProperties?.[thoughtpad?.id!],
  };
};

export const getCurrentPageNode = (params: Readonly<Params<string>>) => {
  if (params.pageId) {
    return db.nodes.where("id").equals(params.pageId).first();
  } else if (window.location.pathname.includes("thoughtpad")) {
    return db.nodes.where("nodeType").equals(EfNodeType.ThoughtPad).first();
  } else {
    return undefined;
  }
};
