import { findNodeAncestors } from "./findNodeAncestors";
import { EfNode } from "@/types";

export const getTagWithFullName = async (
  tagId: string
): Promise<EfNode & { fullName?: string }> => {
  const ancestors = await findNodeAncestors(tagId);
  const fullName = ancestors
    .map((a) => a.titleText)
    .reverse()
    .join(".");
  return { ...ancestors[0], fullName };
};
