import { useLiveQuery } from "dexie-react-hooks";

export function useQuery<T>(querier: () => Promise<T> | T, deps: any[]) {
  const [result, loading] = useLiveQuery(
    () => Promise.resolve(querier()).then((result) => [result, false]),
    deps,
    [undefined, true]
  );
  return [result, loading] as const;
}
