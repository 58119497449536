import { useAreaSelection } from "@/components/SelectionWrapper/SelectionWrapper";
import { EfNode } from "@/types";

export const onSelectForward =
  (
    nodes: EfNode[],
    node: EfNode,
    areaSelection: ReturnType<typeof useAreaSelection>,
    index: number,
    setCurrentId: (id: string) => void,
    selectNode: (node: EfNode, nextNode?: EfNode) => void
  ) =>
  () => {
    const nextNode = nodes[index + 1];

    if (!areaSelection.areaSelectedBounds.anchorNode) {
      selectNode(node, nextNode);
    } else if (nextNode) {
      selectNode(nextNode);
    }
    if (nextNode) setCurrentId(nextNode.id);
  };

export const onSelectBackward =
  (
    nodes: EfNode[],
    node: EfNode,
    areaSelection: ReturnType<typeof useAreaSelection>,
    index: number,
    setCurrentId: (id: string) => void,
    selectNode: (node: EfNode, nextNode?: EfNode) => void
  ) =>
  () => {
    const previousNode = nodes[index - 1];

    if (!areaSelection.areaSelectedBounds.anchorNode) {
      selectNode(node, previousNode);
    } else if (previousNode) {
      selectNode(previousNode);
    }
    if (previousNode) setCurrentId(previousNode.id);
  };
