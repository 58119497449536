import { MouseEvent } from "react";
import {
  Pagination,
  PaginationNext,
  PaginationPrevious,
} from "../ui/pagination";

interface PaginationProps {
  totalCount: number;
  perPage: number;
  onClickNext: (evt: MouseEvent<HTMLButtonElement>, pageNumber: number) => void;
  onClickPrev: (evt: MouseEvent<HTMLButtonElement>, pageNumber: number) => void;
  page: number;
  showCount: boolean;
  disableNext: boolean;
  disablePrev: boolean;
}

export const PaginationComponent = ({
  totalCount,
  onClickNext,
  onClickPrev,
  page,
  perPage,
  showCount,
  disableNext,
  disablePrev,
}: PaginationProps) => {
  const count = Math.ceil(totalCount / perPage);

  return (
    <div className="flex flex-col items-start space-x-3 space-y-2 md:flex-row md:items-center">
      {showCount && (
        <span>
          Showing <b>{(page - 1) * perPage + 1}</b> to{" "}
          <b>{Math.min(page * perPage, totalCount)}</b> of {totalCount} results
        </span>
      )}
      <Pagination>
        <PaginationPrevious
          disabled={disablePrev}
          onClick={(evt: MouseEvent<HTMLButtonElement>) => {
            if (disablePrev) {
              return;
            }
            if (page - 1 <= 0) {
              onClickPrev(evt, count);
              return;
            }
            onClickPrev(evt, page - 1);
          }}
        />

        <PaginationNext
          disabled={disableNext}
          onClick={(evt: MouseEvent<HTMLButtonElement>) => {
            if (disableNext) {
              return;
            }
            if (page + 1 > count) {
              onClickNext(evt, 1);
              return;
            }
            onClickNext(evt, page + 1);
          }}
        />
      </Pagination>
    </div>
  );
};
