import { Menu } from "@headlessui/react";
import { MdSort } from "react-icons/md";
import { TbSortAscending } from "react-icons/tb";
import { BsChevronDown, BsSortAlphaUp, BsChevronUp } from "react-icons/bs";
import { FiCheck as Checkmark } from "react-icons/fi";
import { useCallback, useRef } from "react";
import { startCase } from "lodash";
import { v4 } from "uuid";
import clsx from "clsx";

type SidePanelActionKey = {
  sortOrder?: "increasing" | "decreasing";
  sortBy?: "name" | "createdDate" | "manual" | "recency";
};

type SidePanelActionProps = {
  onChange: (
    key: "sortBy" | "sortOrder",
    value: string
  ) => Promise<string> | void;
  sidePanelActions?: SidePanelActionType[];
} & SidePanelActionKey;

export type SidePanelActionType = {
  value: string;
  Icon?: React.ElementType;
  showArrowIcon?: boolean;
  displayName?: string;
  keyToUpdate?: keyof SidePanelActionKey;
  defaultMenuButton?: React.ReactNode;
  menuItems?: SidePanelActionType[];
  isDisabled?: (value: any) => boolean;
};

const defaultSidePanelActions: SidePanelActionType[] = [
  {
    value: "sort",
    defaultMenuButton: <MdSort />,
    menuItems: [
      {
        Icon: TbSortAscending,
        value: "sortBy",
        displayName: "Sort By",
        showArrowIcon: true,
        menuItems: [
          {
            value: "createdDate",
            keyToUpdate: "sortBy",
            displayName: "Date Created",
          },
          {
            value: "name",
            keyToUpdate: "sortBy",
            displayName: "Alphabetical",
          },
          {
            value: "manual",
            keyToUpdate: "sortBy",
            displayName: "Manual",
          },
          // {
          //   value: "recency",
          //   keyToUpdate: "sortBy",
          //   displayName: "Recency",
          // },
        ],
      },
      {
        Icon: BsSortAlphaUp,
        value: "sortOrder",
        displayName: "Sort Order",
        showArrowIcon: true,
        isDisabled: (value: any) => ["manual", "recency"].includes(value),
        menuItems: [
          {
            value: "increasing",
            keyToUpdate: "sortOrder",
            displayName: "Ascending",
          },
          {
            value: "decreasing",
            keyToUpdate: "sortOrder",
            displayName: "Descending",
          },
        ],
      },
    ],
  },
];

export const SidePanelAction = (props: SidePanelActionProps) => {
  const { onChange, sortBy, sidePanelActions } = props;
  const { current: uniqueId } = useRef(v4());

  const getPositionOfMenuItem = useCallback((menuId: string, elements = 0) => {
    const position = document.getElementById(menuId)?.getBoundingClientRect();
    let top = (position?.height ?? 0) + (position?.top ?? 0) + 2;
    if (top + 40 * elements > window.screen.height) {
      top -= 40 * (elements + 1);
    }
    return {
      top,
      left:
        (window.screen.width > 640
          ? (position?.left ?? 0) + (position?.width ?? 0) / 2
          : 40) + 2,
    };
  }, []);

  const onClickItem = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      const value = evt.currentTarget.getAttribute("data-value")!;
      const key = evt.currentTarget.getAttribute(
        "data-key"
      ) as keyof SidePanelActionKey;
      key && onChange(key, value);
    },
    [onChange]
  );

  const getMenu = useCallback(
    (menus?: SidePanelActionType[]) => {
      return (menus || []).map(
        ({
          menuItems,
          Icon,
          keyToUpdate,
          displayName,
          value,
          showArrowIcon,
          defaultMenuButton,
          isDisabled,
        }) => {
          const textWithIcon =
            Icon || displayName ? (
              <div className="flex items-center">
                {Icon && <Icon />}
                <div className="ml-2">{displayName}</div>
              </div>
            ) : null;
          return (
            <div key={value}>
              {!!menuItems?.length ? (
                <Menu as="div" id={`${value}+${uniqueId}`}>
                  {({ open }) => (
                    <>
                      <Menu.Button
                        disabled={isDisabled?.(sortBy)}
                        className={clsx(
                          {
                            ["!text-gray-400 hover:!bg-white"]:
                              isDisabled?.(sortBy),
                          },
                          "w-full px-3 py-2 text-gray-700  hover:bg-gray-100 hover:rounded-md"
                        )}
                      >
                        <div className="flex items-center justify-between gap-10 sm:gap-7">
                          {textWithIcon}
                          <div className="flex items-center gap-1">
                            {(!!value &&
                              startCase(
                                menuItems.find(
                                  ({ value: menuItemValue }) =>
                                    menuItemValue ===
                                    props[value as keyof SidePanelActionProps]
                                )?.displayName
                              )) ||
                              defaultMenuButton}
                            {showArrowIcon &&
                              (open ? <BsChevronUp /> : <BsChevronDown />)}
                          </div>
                        </div>
                      </Menu.Button>
                      <Menu.Items
                        style={getPositionOfMenuItem(
                          `${value}+${uniqueId}`,
                          menuItems.length
                        )}
                        className="flex flex-col fixed p-1 min-w-[18rem] lg:min-w-[12rem] z-30 bg-white rounded-md border border-gray-300 focus:outline-none"
                      >
                        {getMenu(menuItems)}
                      </Menu.Items>
                    </>
                  )}
                </Menu>
              ) : (
                <Menu.Item key={value}>
                  <span
                    onClick={onClickItem}
                    className="flex items-center justify-between px-3 py-2 text-gray-700 hover:rounded-md hover:bg-gray-100 cursor-pointer"
                    data-value={value}
                    data-key={keyToUpdate}
                  >
                    {textWithIcon}
                    {keyToUpdate && props[keyToUpdate] === value && (
                      <Checkmark className="text-blue-600" />
                    )}
                  </span>
                </Menu.Item>
              )}
            </div>
          );
        }
      );
    },
    [uniqueId, onClickItem, getPositionOfMenuItem]
  );

  return (
    <div className="mx-1">
      {getMenu(sidePanelActions || defaultSidePanelActions)}
    </div>
  );
};
