import { db } from "../db";
import { EfNode } from "../types";

export async function findNodeAncestors(nodeId: string) {
  const nodes: EfNode[] = [];
  const node = await db.nodes.get(nodeId);

  if (!node) return nodes;

  nodes.push(node);

  if (!node.parentId) return nodes;

  const ancestors = await findNodeAncestors(node.parentId);
  nodes.push(...ancestors);

  return nodes;
}
