import { getEditorsMap } from "@/components/SelectionWrapper/selectionUtils";
import { db } from "@/db";
import { useHistoryManager } from "@/hooks/useHistoryManager";
import { EfNode } from "@/types";
import {
  createNode,
  deleteNode,
  memoizedGenerateHTMLForNewTipTap,
  updateNode,
} from "@/utils";
import { extractIds } from "@/utils/extractIds";
import { Selection } from "prosemirror-state";

// this function is used when delete or backspace is pressed in editor
export const joinNodes = async (
  node1: EfNode,
  node2: EfNode,
  deleteNode1: boolean
) => {
  // getting node1 and ndoe2 content from editorMap
  const node1Content =
    getEditorsMap()[node1.id]?.state.doc?.content.toJSON() || [];
  const node2Content =
    getEditorsMap()[node2.id]?.state.doc?.content.toJSON() || [];

  // taking lastElement from node1Content and firstElement from node2Content
  const lastElementInNode1 = node1Content.at(-1) || {};
  const firstElementInNode2 = node2Content.at(0) || {};

  // removing last element from node1Content
  node1Content.splice(node1Content.length - 1, 1);
  // removing first element from node2Content
  node2Content.splice(0, 1);

  // adding null or undefined check.
  if (lastElementInNode1.content && firstElementInNode2.content) {
    // merging lastElement in node1 and firstElement in node2 so than join happens
    lastElementInNode1.content.push(...firstElementInNode2.content);
  } else if (firstElementInNode2?.content) {
    // lastElement in node1 is null then just assign firstElement in node2 as lastElement of node1
    lastElementInNode1.content = [...firstElementInNode2.content];
  }

  // meging content into one doc in below order.
  // 1. node1Content(after removing last element)
  // 2. last element in node1
  // 3. node2Content(after removing first element)
  const mergedContent = {
    type: "doc",
    content: [...node1Content, lastElementInNode1, ...node2Content],
  };

  const editorNode1 = getEditorsMap()?.[node1.id];
  // setting selection  to the end if this node as new content will come after current content
  editorNode1
    ?.chain()
    .focus()
    .setTextSelection({
      from: Selection.atEnd(editorNode1.state.doc).to,
      to: Selection.atEnd(editorNode1.state.doc).to,
    })
    .run();
  await deleteNode(node2);

  if (node2.contentText && !["", "<p></p>"].includes(node2.contentText)) {
    await updateNode({
      ...node1,
      tagIds: extractIds(mergedContent, "tag"),
      referencedPageIds: extractIds(mergedContent, "PageRef"),
      mentionIds: extractIds(mergedContent, "mention"),
      fileIds: extractIds(mergedContent, "file"),
      contentText: memoizedGenerateHTMLForNewTipTap(mergedContent),
    });
  }

  // if we are deleting node2 then update parentId of all first level children to node1.
  if (deleteNode1) {
    await updateChildrenParent(node2, node1);
  }
};

export const undoJoinNodes = async (
  node1: EfNode,
  node2: EfNode,
  undoNode1: boolean,
  setCurrentId: (val: string) => void
) => {
  await createNode(node2);
  if (node2.contentText && !["", "<p></p>"].includes(node2.contentText)) {
    await updateNode(node1);
  }
  setCurrentId(node2.id);
  if (undoNode1) {
    await updateChildrenParent(node1, node2);
  }
};

const updateChildrenParent = async (node: EfNode, parentNode: EfNode) => {
  const children = await db.nodes.where("parentId").equals(node.id).toArray();
  await Promise.allSettled(
    children.map(
      async (child) => await updateNode({ ...child, parentId: parentNode.id })
    )
  );
};

export const onDeleteBackwards =
  (
    history: ReturnType<typeof useHistoryManager>,
    nodes: EfNode[],
    node: EfNode,
    index: number,
    setCurrentId: (id: string) => void
  ) =>
  () => {
    const previousNode = nodes[index - 1];
    const nextNode = nodes[index + 1];
    const currentNodeDepth = node.computed?.pathInPage?.split("/")?.length || 0;
    const previousNodeDepth =
      previousNode?.computed?.pathInPage?.split("/")?.length || 0;
    if (
      !previousNode ||
      (nextNode?.parentId === node.id && currentNodeDepth !== previousNodeDepth)
    ) {
      return;
    }
    history.run(
      {
        redo: async () => {
          await db.transaction("rw", db.nodes, async () => {
            console.log("redo join bw");
            await joinNodes(previousNode, node, nextNode?.parentId === node.id);
          });
        },
        undo: async () => {
          await db.transaction("rw", db.nodes, async () => {
            // TODO: extract restoreNode
            await undoJoinNodes(
              previousNode,
              node,
              nextNode?.parentId === node.id,
              setCurrentId
            );
          });
        },
      },
      true // Disable chunking
    );
  };

export const onDeleteForwards =
  (
    history: ReturnType<typeof useHistoryManager>,
    nodes: EfNode[],
    node: EfNode,
    index: number,
    setCurrentId: (id: string) => void
  ) =>
  () => {
    const nextNode = nodes[index + 1];
    const secondNextNode = nodes[index + 2];
    const nextNodeDepth =
      nextNode?.computed?.pathInPage?.split("/")?.length || 0;
    const currrentNodeDepth =
      node.computed?.pathInPage?.split("/")?.length || 0;
    if (
      !nextNode ||
      (secondNextNode?.parentId === nextNode?.id &&
        nextNodeDepth !== currrentNodeDepth)
    ) {
      return;
    }
    history.run({
      redo: async () => {
        await db.transaction("rw", db.nodes, async () => {
          await joinNodes(
            node,
            nextNode,
            secondNextNode?.parentId === nextNode?.id
          );
        });
      },
      undo: async () => {
        await db.transaction("rw", db.nodes, async () => {
          // TODO: extract restoreNode
          await undoJoinNodes(
            node,
            nextNode,
            secondNextNode?.parentId === nextNode?.id,
            setCurrentId
          );
        });
      },
    });
  };
