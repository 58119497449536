import { type Editor, type Extensions } from "@tiptap/core";
import { EditorContent, useEditor } from "@tiptap/react";
import clsx from "clsx";
import { isEqual } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { FiArrowUp, FiHash } from "react-icons/fi";
import {
  MdCheckBox,
  MdClose,
  MdCode,
  MdFormatBold,
  MdFormatClear,
  MdFormatIndentDecrease,
  MdFormatIndentIncrease,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdFormatStrikethrough,
  MdFormatUnderlined,
  MdKeyboardReturn,
  MdRedo,
  MdUndo,
  MdAttachFile,
} from "react-icons/md";
import { RiBracketsLine, RiH1, RiH2, RiH3, RiParagraph } from "react-icons/ri";
import { defaultExtensions } from "../extensions";
import { useIosKeyboardHeight } from "../hooks/useIosKeyboardHeight";
import { btn, btnActive } from "../styles/classes";
import { uploadFileCommand } from "../utils/uploadFileCommand";
import { ContextMenu } from "./ContextMenu";

const btnIcon = clsx(btn, "!p-2");

const BOTTOM_TOOLBAR_HEIGHT = 57;

export type TiptapProps = {
  content: any;
  setContent?: (value: any) => void;
  ignoreIosKeyboard?: boolean;
  disableEdit?: boolean;
  hideToolbar?: boolean;
  disableCheck?: boolean;
  autofocus?: boolean;
  extensions?: Extensions;
  onCreate?: (editor: Editor) => void;
  onSelectionUpdate?: (editor: Editor) => void;
  rootAfterPosition?: string | null;
};

export function Tiptap({
  content,
  setContent,
  ignoreIosKeyboard,
  disableEdit,
  hideToolbar,
  disableCheck,
  extensions = defaultExtensions,
  autofocus = false,
  onCreate,
  onSelectionUpdate,
  rootAfterPosition,
}: TiptapProps) {
  const [mode, setMode] = useState<"outline" | "document">("outline");
  const [contextMenu, setContextMenu] = useState(false);
  const [menuStyle, setMenuStyle] = useState<React.CSSProperties>({
    left: 0,
    top: 0,
  });
  const iosKeyboardHeight = useIosKeyboardHeight();

  // Add getEditorJSON for e2e tests
  if (process.env.TEST) {
    window.getEditorJSON = async () => editor?.getJSON();
  }

  const closeContextMenu = () => {
    setContextMenu(false);
  };

  const editor = useEditor({
    editable: !disableEdit,
    content,
    onUpdate({ editor }) {
      if (disableEdit) return;
      setContent?.(editor.getJSON());
    },
    onCreate({ editor }) {
      onCreate?.(editor);
    },
    onSelectionUpdate({ editor }) {
      onSelectionUpdate?.(editor);
    },
    autofocus,
    extensions,
  });

  useEffect(() => {
    // add merging for editor to keep the cursor above keyboard when active
    if (editor) {
      editor.setOptions({
        editorProps: {
          handleKeyDown(view, event) {
            if (event.key === "Tab") event.preventDefault();
            view.focus();
          },
          handleDOMEvents: {
            contextmenu: (_view, event: MouseEvent) => {
              event.preventDefault();
              setMenuStyle({ left: event.clientX, top: event.clientY });
              setContextMenu(true);
            },
          },
          scrollThreshold: {
            top: 0,
            bottom: iosKeyboardHeight + BOTTOM_TOOLBAR_HEIGHT,
            right: 0,
            left: 0,
          },
          scrollMargin: {
            top: 0,
            bottom: iosKeyboardHeight + BOTTOM_TOOLBAR_HEIGHT,
            right: 0,
            left: 0,
          },
        },
      });
    }
  }, [iosKeyboardHeight, editor]);

  useEffect(() => {
    if (!editor) return;
    editor.storage.mode = mode;
  }, [editor, mode]);

  useEffect(() => {
    if (!editor) return;
    editor.storage.rootAfterPosition = rootAfterPosition;
  }, [editor, rootAfterPosition]);

  if (process.env.NODE_ENV !== "production") {
    if (!disableCheck) {
      editor?.schema.nodeFromJSON(content).check();
    }
  }

  useEffect(() => {
    if (!editor) return;
    // deepEqual from fast-equals give false when objects are equal!
    // using isEqual from lodash instead
    if (isEqual(editor.getJSON(), content)) return;

    // update content and restore selection
    queueMicrotask(() => {
      editor
        .chain()
        .setContent(content)
        .setTextSelection(editor.state.selection)
        .run();
    });
  }, [editor, content]);

  return (
    <div
      className="tiptap"
      style={{ paddingBottom: ignoreIosKeyboard ? 0 : iosKeyboardHeight }}
    >
      {editor && editor.isFocused && hideToolbar !== true && (
        <div
          style={{ height: BOTTOM_TOOLBAR_HEIGHT, bottom: iosKeyboardHeight }}
          className="flex space-x-1 overflow-auto z-10 p-2 bg-white border-t lg:hidden fixed left-0 right-0"
        >
          <button
            onClick={() => {
              editor.commands.blur();
            }}
            disabled={!editor.isFocused}
            className={clsx(btnIcon, "lg:hidden")}
          >
            <MdClose className="w-5 h-5" />
          </button>
          {editor.can().toggleTask() && (
            <button
              className={clsx(btnIcon)}
              onClick={() => {
                editor.view.focus();
                editor.commands.toggleTask();
              }}
            >
              <MdCheckBox className="w-5 h-5" />
            </button>
          )}
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.insertContent("#");
            }}
            className={btnIcon}
          >
            <FiHash className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.liftNode();
            }}
            disabled={!editor.can().liftNode()}
            className={clsx(btnIcon, "lg:hidden")}
          >
            <MdFormatIndentDecrease className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.sinkNode();
            }}
            disabled={!editor.can().sinkNode()}
            className={clsx(btnIcon, "lg:hidden")}
          >
            <MdFormatIndentIncrease className="w-5 h-5" />
          </button>
          <button
            className={clsx(btnIcon, "lg:hidden")}
            onClick={() => {
              editor.view.focus();
              editor.commands.splitBlock();
            }}
          >
            <MdKeyboardReturn className="w-5 h-5" />
          </button>
          <button
            className={clsx(btnIcon, "lg:hidden")}
            onClick={() => {
              editor.view.focus();
              editor.commands.moveCursorToTop();
            }}
          >
            <FiArrowUp />
          </button>
          <button
            className={clsx(btnIcon)}
            onClick={() => {
              editor.view.focus();
              uploadFileCommand(editor);
            }}
          >
            <MdAttachFile className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.undo();
            }}
            disabled={!editor.can().undo()}
            className={btnIcon}
          >
            <MdUndo className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.redo();
            }}
            disabled={!editor.can().redo()}
            className={btnIcon}
          >
            <MdRedo className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.toggleBold();
            }}
            disabled={!editor.can().toggleBold()}
            className={clsx(btnIcon, editor.isActive("bold") && btnActive)}
          >
            <MdFormatBold className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.toggleItalic();
            }}
            disabled={!editor.can().toggleItalic()}
            className={clsx(btnIcon, editor.isActive("italic") && btnActive)}
          >
            <MdFormatItalic className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.toggleUnderline();
            }}
            disabled={!editor.can().toggleUnderline()}
            className={clsx(btnIcon, editor.isActive("underline") && btnActive)}
          >
            <MdFormatUnderlined className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.toggleStrike();
            }}
            disabled={!editor.can().toggleStrike()}
            className={clsx(btnIcon, editor.isActive("strike") && btnActive)}
          >
            <MdFormatStrikethrough className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.toggleCode();
            }}
            disabled={!editor.can().toggleCode()}
            className={clsx(btnIcon, editor.isActive("code") && btnActive)}
          >
            <RiBracketsLine className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.toggleHeading({ level: 1 });
            }}
            className={clsx(
              btnIcon,
              editor.isActive("heading", { level: 1 }) ? btnActive : ""
            )}
          >
            <RiH1 className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.toggleHeading({ level: 2 });
            }}
            className={clsx(
              btnIcon,
              editor.isActive("heading", { level: 2 }) ? btnActive : ""
            )}
          >
            <RiH2 className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.toggleHeading({ level: 3 });
            }}
            className={clsx(
              btnIcon,
              editor.isActive("heading", { level: 3 }) ? btnActive : ""
            )}
          >
            <RiH3 className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.setParagraph();
            }}
            className={clsx(
              btnIcon,
              editor.isActive("paragraph") ? btnActive : ""
            )}
          >
            <RiParagraph className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.toggleBulletList();
            }}
            className={clsx(
              btnIcon,
              editor.isActive("bulletList") && btnActive
            )}
          >
            <MdFormatListBulleted className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.toggleOrderedList();
            }}
            className={clsx(
              btnIcon,
              editor.isActive("orderedList") && btnActive
            )}
          >
            <MdFormatListNumbered className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.toggleCodeBlock();
            }}
            className={clsx(btnIcon, editor.isActive("codeBlock") && btnActive)}
          >
            <MdCode className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              editor.view.focus();
              editor.commands.toggleBlockquote();
            }}
            className={clsx(
              btnIcon,
              editor.isActive("blockquote") && btnActive
            )}
          >
            <MdFormatQuote className="w-5 h-5" />
          </button>
          <button
            className={btn}
            onClick={() => {
              editor.view.focus();
              editor.commands.unsetAllMarks();
            }}
          >
            <MdFormatClear className="w-5 h-5" />
          </button>
          <button
            className={clsx(btn, "uppercase font-bold")}
            onClick={() => {
              editor.view.focus();
              setMode(mode === "outline" ? "document" : "outline");
            }}
          >
            {mode}
          </button>
        </div>
      )}
      {editor && contextMenu && (
        <ContextMenu
          editor={editor}
          closeContextMenu={closeContextMenu}
          style={menuStyle}
        />
      )}
      <EditorContent
        editor={editor}
        className="prose prose-sm max-w-none leading-[120%] w-full"
      />
    </div>
  );
}
