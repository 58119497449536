import { useState } from "react";
import { Tiptap } from "./Tiptap";

export function EditorRoute() {
  const [content, setContent] = useState(null);

  return (
    <div className="max-w-5xl mx-auto my-4">
      <Tiptap content={content} setContent={setContent} disableCheck />
    </div>
  );
}
