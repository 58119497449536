import { RefObject } from "react";
import { isMobile } from "react-device-detect";

export const onBlur =
  (mobileToolbarRef: RefObject<HTMLDivElement>) => (e: FocusEvent) => {
    const focusedToToolbar = () => {
      const toolbar = mobileToolbarRef.current;
      if (!toolbar) return false;
      if (!(e.relatedTarget instanceof Node)) return false;
      return toolbar.contains(e.relatedTarget);
    };
    if (!isMobile || !focusedToToolbar()) {
      // If we are not on mobile or
      // We are on mobile but did not lose focus to toolbar - unset currentId
      // setCurrentId(null);
    }
  };
