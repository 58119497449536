import CodeBlock from "@tiptap/extension-code-block";
import { Editor } from "@tiptap/react";

export const CustomCodeBlock = CodeBlock.extend({
  priority: 1000,
  addKeyboardShortcuts() {
    return {
      "Shift-Enter": ({ editor }) => onNewline(editor as Editor),
      Enter: ({ editor }) => onNewline(editor as Editor),
    };
  },
});

const onNewline = (editor: Editor) => {
  const { state } = editor;
  const { selection } = state;
  const { $from } = selection;

  // Find the node (CodeBlock) where the selection is
  const codeBlockNode = $from.node($from.depth);

  // Check if the current node is a CodeBlock
  if (codeBlockNode.type.name !== "codeBlock") return false;

  // Check if the cursor is at the end of the CodeBlock
  const isAtEndOfCodeBlock = selection.to === $from.end($from.depth);

  // Get the last two characters of the CodeBlock content
  const codeBlockText = codeBlockNode.textContent;
  const lastTwoCharacters = codeBlockText.slice(-2);

  // Check if the last two characters are newlines
  if (isAtEndOfCodeBlock && lastTwoCharacters === "\n\n") return false; // Prevent inserting more new lines

  // Insert new line
  editor.commands.insertContent("\n");
  return true;
};
