import { DesignSystemRoute } from "@/components/DesignSystemRoute";
import * as Sentry from "@sentry/browser";
import Dexie from "dexie";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { FallbackProps } from "react-error-boundary";
import {
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from "react-router-dom";
import { App } from "./components/App";
import { ContactRouteWapper } from "./components/ContactRoute";
import { DataRoute } from "./components/DataRoute";
import { EditorRoute } from "./components/EditorRoute";
import { IndexRoute } from "./components/IndexRoute";
import { OldPageRoute } from "./components/OldPageRoute";
import { OldThoughtPadRoute } from "./components/OldThoughtPadRoute";
import { PageRouteWapper } from "./components/PageRoute";
import { SearchRoute } from "./components/SearchRoute";
import { TagRouteWapper } from "./components/TagRoute";
import { TasksRouteWapper } from "./components/TasksRoute";
import { EfNodeType } from "./graphql";

const container = document.getElementById("app");
const root = createRoot(container!);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    ErrorBoundary: () => {
      const error = useRouteError();
      return (
        <ErrorFallback
          resetErrorBoundary={() => {
            window.location.replace("/");
          }}
          error={error as Error}
        />
      );
    },
    children: [
      { path: "/", element: <IndexRoute /> },
      {
        path: "/thoughtpad",
        element: <PageRouteWapper nodeType={EfNodeType.ThoughtPad} />,
      },
      { path: "/old-thoughtpad", element: <OldThoughtPadRoute /> },
      { path: "/data", element: <DataRoute /> },
      { path: "/search", element: <SearchRoute /> },
      { path: "/editor", element: <EditorRoute /> },
      {
        path: "/pages/:pageId",
        element: <PageRouteWapper nodeType={EfNodeType.Page} />,
      },
      { path: "/old-pages/:pageId", element: <OldPageRoute /> },
      { path: "/tags/:tagId", element: <TagRouteWapper /> },
      { path: "/contacts/:contactId", element: <ContactRouteWapper /> },
      { path: "/tasks/:status", element: <TasksRouteWapper /> },
    ],
  },
  { path: "/design-system", element: <DesignSystemRoute /> },
]);

root.render(<RouterProvider router={router} />);

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-gray-50 p-4 rounded-lg max-w-xl space-y-4 shadow border">
        <div className="font-bold">Something went wrong</div>
        <div>{error.message}</div>
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white py-2 px-3 rounded"
            onClick={resetErrorBoundary}
          >
            Try Again
          </button>
          <button
            className="bg-red-500  text-white py-2 px-3 rounded"
            onClick={async () => {
              await Dexie.delete("db");
              localStorage.clear();
              window.location.reload();
            }}
          >
            Clear Local DB
          </button>
        </div>
      </div>
    </div>
  );
}
