import { PmNode } from "../types";

export function extractIds(
  node: PmNode,
  nodeType: "mention" | "tag" | "PageRef" | "file"
) {
  const ids: string[] = [];

  if (node.type === nodeType && node.attrs?.id) {
    ids.push(node.attrs.id);
  }

  for (const item of node.content ?? []) {
    ids.push(...extractIds(item, nodeType));
  }

  return ids;
}
