// @ts-nocheck
import { Extension } from "@tiptap/core";
import { ReactRenderer } from "@tiptap/react";
import Suggestion, { SuggestionOptions } from "@tiptap/suggestion";
import { PluginKey } from "prosemirror-state";
import tippy, { Instance } from "tippy.js";
import { SuggestionList } from "../../components/SuggestionList";
import { uploadFileCommand } from "../../utils/uploadFileCommand";
import { findElementWithNodeId } from "../../utils/findElementWithNodeId";
import { isMacOs } from "react-device-detect";
import { getNodeIdFromEditorId } from "../../components/VirtualizedEditor/editorUtils/genericUtils";

const SlashPluginKey = new PluginKey("slash");

const suggestion: Omit<SuggestionOptions, "editor"> = {
  char: "/",
  pluginKey: SlashPluginKey,
  items: ({ editor, query }) => {
    const items = [
      {
        label: "Slack",
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .insertContent("#slack.")
            .run();
        },
      },
      {
        label: "Paragraph",
        command: ({ editor, range }) => {
          editor.chain().focus().deleteRange(range).setNode("paragraph").run();
        },
      },
      {
        label: "Heading 1",
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode("heading", { level: 1 })
            .run();
        },
      },
      {
        label: "Heading 2",
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode("heading", { level: 2 })
            .run();
        },
      },
      {
        label: "Heading 3",
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode("heading", { level: 3 })
            .run();
        },
      },
      {
        label: "Bullet List",
        command: ({ editor, range }) => {
          editor.chain().focus().deleteRange(range).toggleBulletList().run();
        },
      },
      {
        label: "Ordered List",
        command: ({ editor, range }) => {
          editor.chain().focus().deleteRange(range).toggleOrderedList().run();
        },
      },
      {
        label: "Code block",
        command: ({ editor, range }) => {
          editor.chain().focus().deleteRange(range).toggleCodeBlock().run();
        },
      },
      {
        label: "Blockquote",
        command: ({ editor, range }) => {
          editor.chain().focus().deleteRange(range).toggleBlockquote().run();
        },
      },
      {
        label: "File",
        action: async () => {
          uploadFileCommand(editor);
        },
        command: ({ editor, range }) => {
          editor.chain().focus().deleteRange(range).run();
        },
      },
      {
        key: "copyLinkToNode",
        label: "Copy link to this node",
        command: ({ editor }) => {
          const nodeId = getNodeIdFromEditorId(
            findElementWithNodeId(editor.view.dom)?.getAttribute("data-nodeid")
          );
          if (!nodeId) {
            return;
          }
          navigator.clipboard?.writeText?.(
            `${window.location.origin}${window.location.pathname}?node=${nodeId}`
          );
        },
      },
    ];

    if (isMacOs) {
      items.push({
        key: "TextView",
        label: "TextView",
        command: ({ editor }) => {
          const nodeId = getNodeIdFromEditorId(
            findElementWithNodeId(editor.view.dom)?.getAttribute("data-nodeid")
          );
          if (!nodeId) {
            return;
          }
          window.open(`execfnimgprocess://image?id=${nodeId}&type=efNode`);
        },
      });
    }
    if (editor.can().toggleTask()) {
      items.unshift({
        label: "Task",
        command: ({ editor, range }) => {
          editor.chain().toggleTask(range);
        },
      });
    }

    return items.filter((item) =>
      item.label.toLowerCase().startsWith(query.toLowerCase())
    );
  },
  render: () => {
    let reactRenderer: ReactRenderer;
    let popup: Instance[];

    return {
      onStart: (props) => {
        reactRenderer = new ReactRenderer(SuggestionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        // TODO
        // @ts-ignore
        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate(props) {
        reactRenderer.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          // @ts-ignore
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        if (popup[0].state.isVisible) {
          // @ts-ignore
          return reactRenderer.ref?.onKeyDown(props);
        }
      },

      onExit() {
        if (!popup) return;
        popup[0].destroy();
        reactRenderer.destroy();
      },
    };
  },
};

export const NewSlash = Extension.create({
  name: "slash",
  priority: 100000,
  addOptions() {
    return {
      suggestion: {
        char: "/",
        command: ({ editor, range, props }) => {
          props.command({ editor, range });
        },
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
}).configure({ suggestion });
