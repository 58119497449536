import { Node as BaseNode } from "@tiptap/react";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { FileRenderer } from "../components/FileRenderer";

export const File = BaseNode.create({
  name: "file",

  priority: 100000,

  group: "inline",

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            "data-id": attributes.id,
          };
        },
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(FileRenderer, {
      update: ({ oldNode, newNode }) => {
        if (oldNode.attrs.id !== newNode.attrs.id) {
          // Ignore update in case prosemirror try to update with other tag
          return false;
        }
        return true;
      },
    });
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node }) {
    return ["span", { "data-type": this.name, "data-id": node.attrs.id }];
  },
});
