import { EfNode } from "@/types";
import { db } from "../db";
import { findNodeDescendents } from "./findNodeDescendents";

/**
 * This function will get the passed tagId full node object along with its descendents.
 * @param tagId
 * @returns descendents of type EfNode[]
 */
export async function findNodesByParentTag(tagId: string) {
  // added this to make use query reactive for this tagId search
  const currentTag = await db.nodes.where("tagIds").equals(tagId).first();
  const set = new Set<string>(await findNodeDescendents(tagId));
  if (currentTag) {
    set.add(currentTag.id);
  }
  const descendents: Map<string, EfNode> = new Map();

  await Promise.all(
    Array.from(set).map(async (descendentId) =>
      (
        await db.nodes.where("tagIds").equals(descendentId).toArray()
      ).forEach((node) => {
        descendents.set(node.id, node);
      })
    )
  );
  return Array.from(descendents.values());
}
