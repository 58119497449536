import { Node } from "@tiptap/react";

export const ThoughtPad = Node.create({
  name: "EfThoughtPad",
  content: "EfNodeList",
  topNode: true,

  addAttributes() {
    return {
      id: { default: null },
    };
  },

  parseHTML() {
    return [{ tag: "div" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", HTMLAttributes, 0];
  },
});
