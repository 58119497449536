import { uploadToQueue } from "./sync";
import { EfNodeType } from "../graphql";
import { useEffect, useState } from "react";
import { EditorState, Transaction } from "prosemirror-state";
import { findParentNodeOfType } from "prosemirror-utils";
import { EfNodeData, FileNodeData } from "../types";
import { db } from "../db";

const FILE_SIZE_LIMIT_IN_MB = 25;

export type FilesWithNodeIDs = [string, File][];
export function addFilesToUploadQueue(filesWithNodeIDs: FilesWithNodeIDs) {
  return Promise.all(
    filesWithNodeIDs.map(async ([id, file]) => {
      try {
        const fileProperties: FileNodeData["properties"] = {
          fileContentType: file.type,
          fileName: file.name,
          fileUrl: file || "",
          fileCreatedTime: new Date(file.lastModified),
        };

        await uploadToQueue({
          id,
          contentText: "",
          nodeType: EfNodeType.File,
          tagIds: [],
          properties: fileProperties,
        });
      } catch (err) {
        console.error(err);
      }
    })
  );
}

/**
 * Returns a promise that resolves the files source
 */
export function getSourceFromFile(
  file: File
): Promise<string | ArrayBuffer | null> {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((res, rej) => {
    reader.addEventListener("load", function () {
      res(reader.result);
    });
  });
}

export const MAX_FILE_SCALE = 450;
/**
 * Hook that receives file as a parameter
 * Scales the image to 400x400
 * Then return the image src
 * @param file
 * @returns files source
 */
export function useScaledFileSource(file?: File) {
  const [source, setSource] = useState("");

  const scaleFileSource = async (file: File) => {
    try {
      const isImage = file.type.startsWith("image/");
      const source = await getSourceFromFile(file);
      if (typeof source !== "string") return;
      if (!isImage) {
        setSource(source);
      } else {
        const scaled = await scaleImage(source, MAX_FILE_SCALE, MAX_FILE_SCALE);
        setSource(scaled);
      }
    } catch (error) {
      console.error("Failed to scale image", error);
    }
  };

  useEffect(() => {
    if (!file) return;
    void scaleFileSource(file);
  }, [file]);

  return source;
}
export function selectFiles(): Promise<FileList | null> {
  return new Promise((res) => {
    const input = document.createElement("input");
    document.body.appendChild(input); // On safari input must be on dom for onchange to be called
    input.type = "file";
    input.multiple = true;
    input.onchange = (e) => {
      const files = (e.target as HTMLInputElement).files;
      res(files);
    };
    const events = ["focus", "touchstart"];
    function removeInput() {
      input.remove();
      events.forEach((eventName) => {
        window.removeEventListener(eventName, removeInput);
      });
    }
    events.forEach((eventName) => {
      window.addEventListener(eventName, removeInput);
    });

    input.style.visibility = "hidden";
    input.click();
  });
}

// Remove nodes from file queue
export function removeNodesFromFileQueue(nodes: EfNodeData[]) {
  return nodes
    .filter((node) => node.nodeType === EfNodeType.File)
    .map(async (node) => {
      // Remove from queue
      await db.fileQueue.delete(node.id);
    });
}

/**
 * Insert a file node which will act as a reference to EfFileNode
 */
export function insertFileNode(
  tr: Transaction,
  state: EditorState,
  fileNodeID: string,
  newEditor = false
) {
  const node = findParentNodeOfType(state.schema.nodes.EfNodeList)(
    state.selection
  );

  // We do not need this check on new editor -- Once migration is complete we can remove this
  if (!newEditor && !node) return false;

  // Create new file node
  const fileNode = state.schema.nodes["file"].createAndFill({
    id: fileNodeID,
  });

  if (!fileNode) return false;

  const insertAt = state.selection.from;

  return tr.insert(insertAt, fileNode);
}

/**
 * Insert a file node which will act as a reference to FileNode
 */
export function insertFileNodeForNewTipTap(
  tr: Transaction,
  state: EditorState,
  fileNodeID: string
) {
  // Create new file node
  const fileNode = state.schema.nodes["file"].createAndFill({
    id: fileNodeID,
  });

  if (!fileNode) return false;

  const insertAt = state.selection.from;

  return tr.insert(insertAt, fileNode);
}

// Validate single file size
export function validateFileSize(file: File) {
  const sizeInBytes = file.size;
  const sizeInMB = sizeInBytes / 1024 / 1024;
  return sizeInMB < FILE_SIZE_LIMIT_IN_MB;
}

/**
 * Validate multiple files
 * @param files
 * @returns false if any of the files are too large otherwise wil return true
 */
export function validateFiles(files: FilesWithNodeIDs) {
  for (const [_, file] of files) {
    if (!validateFileSize(file)) return false;
  }
  return true;
}

/**
 * Scale image - similar to PIL preview
 */
const scaleImage = (
  fileSource: string,
  maxWidth: number,
  maxHeight: number
) => {
  return new Promise<string>((resolve, reject) => {
    // Create image
    const image = new Image();

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      let width = image.width;
      let height = image.height;

      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }

      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(image, 0, 0, width, height);

      resolve(canvas.toDataURL());
    };

    image.onerror = () => {
      reject(new Error("Failed to load the image."));
    };

    // Load source into image
    image.src = fileSource;
  });
};
