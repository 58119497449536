import { isRuleGroup } from "react-querybuilder";
import { TagFilter } from "./Filters/TagFilter";
import { MentionsFilter } from "./Filters/MentionsFilter";
import { TaskStatusFilter } from "./Filters/TaskStatusFilter";
import { DateFilter } from "./Filters/DateFilter";
import { NodeTypeFilter } from "./Filters/NodeTypeFilter";
import { DropdownMenu } from "../ui/dropdown";
import { SearchRuleGroupType } from "./types";
import { FilterFields } from "./fields";

interface FilterRowProps {
  group: SearchRuleGroupType;
  recentlyAddedFilter?: string;
}
export function FilterRow({ group, recentlyAddedFilter }: FilterRowProps) {
  const renderRow = (filter: SearchRuleGroupType["rules"][number]) => {
    if (isRuleGroup(filter)) {
      switch (filter.id) {
        case FilterFields.TagID:
          return <TagFilter />;
        case FilterFields.MentionID:
          return <MentionsFilter />;
        case FilterFields.TaskStatus:
          return <TaskStatusFilter />;
        case FilterFields.NodeType:
          return <NodeTypeFilter />;
      }
    } else {
      switch (filter.id) {
        case FilterFields.CreatedAt:
          return <DateFilter field={FilterFields.CreatedAt} />;
        case FilterFields.ModifiedAt:
          return <DateFilter field={FilterFields.ModifiedAt} />;
      }
    }
  };
  return (
    <div className="flex flex-row flex-wrap gap-2">
      {group.rules.map((filter) => (
        <DropdownMenu
          key={filter.id!}
          defaultOpen={filter.id === recentlyAddedFilter}
        >
          {renderRow(filter)}
        </DropdownMenu>
      ))}
    </div>
  );
}
