import * as Sentry from "@sentry/browser";
import clsx from "clsx";
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useAutoSync } from "../hooks/useAutoSync";
import { btn } from "../styles/classes";
import { getNodesToPush } from "../utils";
import { ContactList } from "./SidePannel/ContactList";
import { Loader } from "./Loader";
import { PageTree } from "./SidePannel/PageTree";
import { SidePanelTreeDraggingLayer } from "./SidePannel/SidePanelTreeDragLayer";
import { TagTree } from "./SidePannel/TagTree";
import { Tasks } from "./SidePannel/Tasks";
import { UpdateNotification } from "./UpdateNotification";
import { GlobalSearch } from "./GlobalSearch/GlobalSearch";
import { BASE_URL, EfNodeType } from "../graphql";
import { TopBar } from "./TopBar/TopBar";
import { LogoutIcon, SettingsIcon } from "./Icons";
import { useAppVersionCheck } from "../utils/useAppVersionCheck";
import { UpdateAppPrompt } from "./UpdateAppPrompt";
import { FocusModeProvider } from "@/context/FocusMode";
import { TitleProvider } from "@/context/TitleContext";
import { SearchContextProvider } from "@/context/SearchContext";
import { Avatar, AvatarFallback } from "./ui/avatar";
import { SchemaVersionMissmatch } from "./SchemaVersionMissmatch";
import { MenuContextProvider } from "@/context/MenuContext";
import { MenuDialog } from "./MenuDialog";
import { db } from "@/db";
import { populateTagCacheInitially } from "@/cache/tagCache";
import { groupBy } from "lodash";

const SyncIndication = () => {
  const nodesToPush = useLiveQuery(() => getNodesToPush());
  const pendingChanges = nodesToPush ? nodesToPush.length : false;
  return (
    <span
      className={clsx(
        "w-2 h-2 self-center ml-2 rounded-full",
        pendingChanges ? "bg-yellow-500" : "bg-green-500"
      )}
    />
  );
};

export function App() {
  const { loadingVersionCheck, failedVersionCheck } = useAppVersionCheck();
  const [openGlobalSearch, setOpenGlobalSearch] = useState(false);
  const location = useLocation();
  const [isVersionMissmatch, setIsVersionMissmatch] = useState(false);

  const onPage = !![
    "/thoughtpad",
    "/pages",
    "/search",
    "/tags",
    "/tasks",
    "/contacts",
  ].find((path) => location.pathname.startsWith(path));

  const onClickGlobalSearch = () => {
    setOpenGlobalSearch(true);
  };

  useEffect(() => {
    // Solve scroll issue on ios see: https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => {
      window.removeEventListener("resize", appHeight);
    };
  }, []);

  // populating tag cache when reloading
  const populateTagCache = async (hard = false) => {
    const tags = await db.nodes
      .where("nodeType")
      .equals(EfNodeType.Tag)
      .toArray();
    const tagsGroupById = groupBy(tags, "id");
    tags
      .filter((tag) => !tag.deleted)
      .map((tag) => populateTagCacheInitially(tag.id, tagsGroupById));
  };

  const {
    showLoader: syncLoading,
    nodesLeftToPull,
    totalNodes,
  } = useAutoSync(setIsVersionMissmatch);

  const { loading: authLoading, user } = useAuth();

  useEffect(() => {
    if (!authLoading && !syncLoading && !loadingVersionCheck) {
      populateTagCache(true);
    }
  }, [authLoading, syncLoading, loadingVersionCheck]);

  if (failedVersionCheck) {
    return <UpdateAppPrompt />;
  }

  if (authLoading || syncLoading || loadingVersionCheck)
    return (
      <>
        <SchemaVersionMissmatch isVersionMissmatch={isVersionMissmatch} />
        <Loader nodesLeftToPull={nodesLeftToPull} totalNodes={totalNodes} />
      </>
    );

  const avatar = (
    <div className="flex py-3 px-6 items-center gap-2 w-full justify-between border-b">
      <div className="flex flex-row items-center gap-2">
        <Avatar>
          {/* <AvatarImage src="https://github.com/shadcn.png" /> */}
          <AvatarFallback>{user?.username[0]?.toUpperCase()}</AvatarFallback>
        </Avatar>
        <div className="flex flex-col">
          <div className="text-sm font-bold">{user?.username}</div>
          {/* <div className="text-xs text-gray-500">{user.email}</div> */}
        </div>
      </div>
      <SyncIndication />
    </div>
  );
  const navigation = (
    <div
      className="flex w-full h-full border-r flex-col bg-gray-50"
      style={{
        paddingTop: "var(--safe-area-inset-top)",
        paddingBottom: "var(--safe-area-inset-bottom)",
      }}
    >
      {avatar}
      <div className="flex-1 flex-col space-y-8 py-3 overflow-auto">
        <Tasks />
        <PageTree />
        <TagTree />
        <ContactList />
        <SidePanelTreeDraggingLayer />
      </div>
      <UpdateNotification />
      <div className="flex space-x-1 border-t p-1">
        <NavLink to={`/data`}>
          <button title="Data" className={btn}>
            <SettingsIcon className="w-5 h-5" />
          </button>
        </NavLink>
        <button
          title="Logout"
          className={btn}
          onClick={async () => {
            Sentry.setUser(null);
            window.location.assign(`${BASE_URL}/accounts/logout/`);
          }}
        >
          <LogoutIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );

  return (
    <>
      <SchemaVersionMissmatch isVersionMissmatch={isVersionMissmatch} />
      <DndProvider backend={HTML5Backend}>
        <SearchContextProvider>
          <MenuContextProvider>
            <MenuDialog navigation={navigation} />
            <div
              style={{
                height: "var(--app-height)",
                boxSizing: "border-box",
                display: "flex",
              }}
              className="text-gray-700"
            >
              <div className="hidden lg:flex w-72">{navigation}</div>

              <div
                className="flex-1 flex flex-col min-w-0"
                style={{ paddingTop: "var(--safe-area-inset-top)" }}
              >
                <FocusModeProvider>
                  <TitleProvider>
                    <TopBar onClickGlobalSearch={onClickGlobalSearch} />
                    {onPage ? (
                      <Outlet />
                    ) : (
                      <div className="flex-1 overflow-auto p-2">
                        <Outlet />
                      </div>
                    )}
                  </TitleProvider>
                </FocusModeProvider>
              </div>
            </div>
          </MenuContextProvider>
        </SearchContextProvider>

        <GlobalSearch
          isOpen={openGlobalSearch}
          setIsOpen={(isOpenSearch: boolean) =>
            setOpenGlobalSearch(isOpenSearch)
          }
        />
      </DndProvider>
    </>
  );
}
