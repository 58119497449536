import { Field, defaultOperators } from "react-querybuilder";
import { CircleCheckIcon, MentionIcon, TagIcon } from "../Icons";
import { Calendar, Hexagon } from "lucide-react";
import {
  ContentOperatorObject,
  ContentOperators,
  DateOperatorObject,
  DateOperators,
  OperatorsObject,
  RegularFieldOperatorObjects,
  RegularFieldOperators,
  TitleOperatorObject,
  TitleOperators,
} from "./types";

export enum FilterFields {
  ContentAndTitle = "contentAndTitle", // Field for searching both title and content
  Filters = "filters", // Field for storing all filters other than content and title
  Content = "contentText",
  Title = "titleText",
  TagID = "tagIds",
  MentionID = "mentionIds",
  PageID = "pageId",
  TaskStatus = "taskStatus",
  NodeSource = "nodeSource",
  CreatedAt = "createdTime",
  ModifiedAt = "modifiedTime",
  NodeType = "nodeType",
}

export const Operators = defaultOperators.reduce((acc, item) => {
  acc[item.label] = item;
  return acc;
}, {} as OperatorsObject);

// Fields that will be displayed in query builder
export const UIFields = [
  FilterFields.TagID,
  FilterFields.MentionID,
  // FilterFields.PageID,
  // FilterFields.Title,
  FilterFields.TaskStatus,
  // FilterFields.NodeSource,
  FilterFields.CreatedAt,
  FilterFields.ModifiedAt,
  FilterFields.NodeType,
];

export const ContentField: Field<
  FilterFields.Content,
  ContentOperators,
  string,
  ContentOperatorObject
> = {
  label: "Content",
  name: FilterFields.Content,
  operators: [
    Operators.contains,
    Operators["does not contain"],
    Operators["="],
    Operators["!="],
    Operators["begins with"],
    Operators["ends with"],
  ],
  defaultOperator: "contains",
  defaultValue: "",
};

export const TitleField: Field<
  FilterFields.Title,
  TitleOperators,
  string,
  TitleOperatorObject
> = {
  label: "Title",
  name: FilterFields.Title,
  operators: [Operators.contains, Operators["does not contain"]],
  defaultOperator: "contains",
  defaultValue: "",
};
export const TagField: Field<
  FilterFields.TagID,
  RegularFieldOperators,
  string,
  RegularFieldOperatorObjects
> = {
  label: "Tag",
  name: FilterFields.TagID,
  operators: [Operators["="]],
  defaultOperator: "=",
  defaultValue: "",
};

export const MentionField: Field<
  FilterFields.MentionID,
  RegularFieldOperators,
  string,
  RegularFieldOperatorObjects
> = {
  label: "Mention",
  name: FilterFields.MentionID,
  operators: [Operators["="]],
  defaultOperator: "=",
  defaultValue: "",
};
export const PageField: Field<
  FilterFields.PageID,
  RegularFieldOperators,
  string,
  RegularFieldOperatorObjects
> = {
  label: "Page",
  name: FilterFields.PageID,
  operators: [Operators["="]],
  defaultOperator: "=",
  defaultValue: "",
};
export const TaskStatusField: Field<
  FilterFields.TaskStatus,
  RegularFieldOperators,
  string,
  RegularFieldOperatorObjects
> = {
  label: "Task Status",
  name: FilterFields.TaskStatus,
  operators: [Operators["="]],
  defaultOperator: "=",
  defaultValue: "",
};
export const NodeSourceField: Field<
  FilterFields.NodeSource,
  RegularFieldOperators,
  string,
  RegularFieldOperatorObjects
> = {
  label: "Node Source",
  name: FilterFields.NodeSource,
  operators: [Operators["="]],
  defaultOperator: "=",
  defaultValue: "",
};

export const NodeTypeField: Field<
  FilterFields.NodeType,
  RegularFieldOperators,
  string,
  RegularFieldOperatorObjects
> = {
  label: "Node Type",
  name: FilterFields.NodeType,
  operators: [Operators["="]],
  defaultOperator: "=",
  defaultValue: "",
};

export const CreatedAtField: Field<
  FilterFields.CreatedAt,
  DateOperators,
  string,
  DateOperatorObject
> = {
  label: "Created At",
  shortenedLabel: "Created",
  name: FilterFields.CreatedAt,
  operators: [
    Operators["="],
    Operators[">"],
    Operators["<"],
    Operators[">="],
    Operators["<="],
    Operators["between"],
  ],
  defaultOperator: "=",
  defaultValue: "",
};

export const ModifiedAtField: Field<
  FilterFields.ModifiedAt,
  DateOperators,
  string,
  DateOperatorObject
> = {
  label: "Modified At",
  shortenedLabel: "Modified",
  name: FilterFields.ModifiedAt,
  operators: [
    Operators["="],
    Operators[">"],
    Operators["<"],
    Operators[">="],
    Operators["<="],
    Operators["between"],
  ],
  defaultOperator: "=",
  defaultValue: "",
};

export const Fields = [
  ContentField,
  TitleField,
  TagField,
  MentionField,
  PageField,
  TaskStatusField,
  NodeSourceField,
  CreatedAtField,
  ModifiedAtField,
  NodeTypeField,
] as const;

export const FIELD_ICONS = {
  [FilterFields.TagID]: TagIcon,
  [FilterFields.MentionID]: () => <MentionIcon size={20} />,
  [FilterFields.TaskStatus]: () => <CircleCheckIcon size={20} />,
  [FilterFields.CreatedAt]: () => <Calendar size={20} color="gray" />,
  [FilterFields.ModifiedAt]: () => <Calendar size={20} color="gray" />,
  [FilterFields.NodeType]: () => <Hexagon size={20} color="gray" />,
};
