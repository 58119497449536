import Dexie, { Table, Transaction } from "dexie";
import "dexie-export-import";
import { isIOS } from "react-device-detect";
import { EfNode, FileQueueItem, Selection, Settings } from "../types";
import {
  clearCursors,
  getLocalDBSchemaVersion,
  setLocalDBSchemaVersion,
} from "../utils";
import { mobileBackgroundMiddleware } from "./middleware";

/**
 * Incrementing LATEST_DB_SCHEMA_VERSION will clear all nodes and reset cursor
 * This is used when a new field is added to nodes that are already synced on localDB
 * The nodes will be cleared and pulled again
 */
const LATEST_DB_SCHEMA_VERSION = 14;

class DB extends Dexie {
  nodes!: Table<EfNode, string>;
  settings!: Table<Settings, string>;
  selections!: Table<Selection, string>;
  fileQueue!: Table<FileQueueItem, string>;

  constructor() {
    super("db");
    this.version(1).stores({
      nodes: "id, nodeType, parentId, clientTimestamp, *tagIds",
      settings: "id",
    });
    this.version(2).stores({
      selections: "nodeId",
    });
    this.version(3).stores({
      fileQueue: "id, uploaded",
    });
    this.version(4).stores({
      nodes: "id, nodeType, parentId, clientTimestamp, *tagIds, *mentionIds",
    });
    this.version(5).stores({
      nodes:
        "id, nodeType, parentId, clientTimestamp, *tagIds, *mentionIds, [computed.visible+computed.path]",
    });

    this.version(6).stores({
      nodes:
        "id, nodeType, parentId, clientTimestamp, *tagIds, *mentionIds, [computed.visible+computed.path], *referencedPageIds",
    });

    this.version(7).stores({
      nodes:
        "id, nodeType, parentId, clientTimestamp, *tagIds, *mentionIds, [computed.visible+computed.path], *referencedPageIds, [computed.visible+computed.pathInPage]",
    });

    this.version(8).stores({
      nodes:
        "id, nodeType, parentId, clientTimestamp, *tagIds, *mentionIds, [computed.visible+computed.path], *referencedPageIds, [computed.visible+computed.pathInPage], modifiedTime",
    });
    this.version(9).stores({
      nodes:
        "id, nodeType, parentId, clientTimestamp, *tagIds, *mentionIds, [computed.visible+computed.path], *referencedPageIds, [computed.visible+computed.pathInPage], modifiedTime, *fileIds",
    });

    this.on("populate", async () => {
      db.settings.add({
        id: "general",
      });
    });

    this.on("close", () => {
      if (isIOS) {
        // On ios the os might kill indexdb when in background, if the db is closed reload the page
        console.log("DB CLOSED - RELOADING");
        window.location.reload();
      }
    });

    // middleware
    if (isIOS) this.use(mobileBackgroundMiddleware);
  }

  getTable(name: string, tx?: Transaction) {
    if (!this.tables.find((table) => table.name === name)) {
      throw new Error("table doesn't exists");
    }
    if (tx) {
      return tx.table(name);
    }
    return this.table(name);
  }
}

export const db = new DB();

// After creating db make sure that schema
export async function checkSchemaVersion(
  setShowLoader: (val: boolean) => void
) {
  const schemaVersion = await getLocalDBSchemaVersion();
  if (schemaVersion < LATEST_DB_SCHEMA_VERSION) {
    // set showLoader to true because we will not have any nodes and will sync again
    setShowLoader(true);
    console.log("Schema version changed, clearing nodes table");
    await db.nodes.clear();
    await clearCursors();
    // Update local db schema version
    await setLocalDBSchemaVersion(LATEST_DB_SCHEMA_VERSION);
  } else setShowLoader(false);
}

const checkDBAlive = async () => {
  try {
    await db.settings.toArray();
  } catch {
    console.log("DB IS DEAD - RELOADING");
    // If db is not alive reload the page
    window.location.reload();
  }
};

window.iosAppEnterForegroundCallback = () => {
  // When entering foreground check db is alive, if not reload the page
  checkDBAlive();
};

window.iosAppEnterBackgroundCallback = () => {
  // Do nothing, can we used in future
};
