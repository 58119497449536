import { isRuleGroup, RuleGroupType } from "react-querybuilder";
import { FIELD_ICONS, FilterFields } from "../fields";
import {
  FilterContainer,
  FilterItemsContainer,
  FilterItemValueContainer,
  MobileFilterContainer,
  MobileFilterOperatorContainer,
} from "./FilterContainer";
import { OperatorDropdown } from "../OperatorDrowdown";
import { FilterCommand } from "./FilterCommand";
import { useMemo } from "react";
import {
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown";
import { ChevronDown, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useSearch } from "@/context/SearchContext";
import { SearchRuleGroupType, FilterRule } from "../types";
import {
  ApplyFiltersButton,
  useDirty,
} from "../MobileFilterDrawer/ApplyFilterButton";

interface TaskStatusFilterProps {
  dropdown?: boolean;
}

/**
 * Filter component for task status (pending, completed)
 */
export function TaskStatusFilter({ dropdown = true }: TaskStatusFilterProps) {
  const { filters, removeRule, setRule } = useSearch();
  const { dirty } = useDirty(FilterFields.TaskStatus);
  const rule = useMemo(
    () =>
      filters.rules.find(
        (rule) => rule.id === FilterFields.TaskStatus
      ) as SearchRuleGroupType,
    [filters]
  );
  const operator = combinatorToOperator(rule.combinator);
  const showDropDown = operator === TaskStatusOperators.TaskIs;
  const activeTasksStatus = useMemo(
    () => getActiveStatuses(rule.rules),
    [rule.rules]
  );

  const setOperator = (operator: string) => {
    if (operator === TaskStatusOperators.TaskIs) {
      return setRule({
        ...rule,
        combinator: operatorToCombinator(operator),
        rules: rule.rules.length ? [rule.rules[0]] : [],
      });
    }

    return setRule({
      ...rule,
      combinator: operatorToCombinator(operator),
      rules: [
        {
          id: TaskStatus.Pending,
          field: FilterFields.TaskStatus,
          operator: "=",
          value: TaskStatus.Pending,
        },
        {
          id: TaskStatus.Complete,
          field: FilterFields.TaskStatus,
          operator: "=",
          value: TaskStatus.Complete,
        },
      ],
    });
  };

  const getStatuses = () => {
    return Object.values(TaskStatus).map((status) => ({
      label: status,
      value: status,
    }));
  };

  const setStatus = (status: string) => {
    setRule({
      ...rule,
      rules: [
        {
          id: status,
          field: FilterFields.TaskStatus,
          operator: "=",
          value: status,
        },
      ],
    });
  };

  const Icon = FIELD_ICONS[FilterFields.TaskStatus];

  const operatorDropdownWithTitle = (
    <>
      <div className="flex flex-row items-center gap-1">
        <Icon />
        <span>Status</span>
      </div>
      <OperatorDropdown
        operator={operator}
        setOperator={setOperator}
        operators={Operators}
      />
    </>
  );

  if (!dropdown) {
    return (
      <MobileFilterContainer>
        <MobileFilterOperatorContainer>
          {operatorDropdownWithTitle}
          <div className="flex flex-1 justify-end">
            {dirty && <ApplyFiltersButton />}
          </div>
        </MobileFilterOperatorContainer>
        <FilterCommand
          icon={<Icon />}
          selectedItems={activeTasksStatus}
          getItems={getStatuses}
          onSelect={(status) => {
            setStatus(status);
          }}
          placeholder="Status"
          radio={showDropDown}
          disabled={!showDropDown}
        />
      </MobileFilterContainer>
    );
  }

  return (
    <FilterContainer>
      <FilterItemsContainer>
        {operatorDropdownWithTitle}
        {showDropDown && (
          <DropdownMenuTrigger className="flex flex-row items-center">
            <FilterItemValueContainer>
              {activeTasksStatus.join(", ") || "Select status"}
            </FilterItemValueContainer>
            <ChevronDown height={16} width={16} />
          </DropdownMenuTrigger>
        )}
        {!showDropDown && (
          <FilterItemValueContainer>
            {activeTasksStatus.join(", ") || "Select status"}
          </FilterItemValueContainer>
        )}
        <Button
          variant="outline"
          size="icon"
          onClick={() => removeRule(FilterFields.TaskStatus)}
          className="h-5 w-5 border-0"
        >
          <X height={16} width={16} />
        </Button>
      </FilterItemsContainer>
      {showDropDown && (
        <DropdownMenuContent>
          <FilterCommand
            icon={<Icon />}
            selectedItems={activeTasksStatus}
            getItems={getStatuses}
            onSelect={(status) => {
              setStatus(status);
            }}
            placeholder="Status"
            radio
          />
        </DropdownMenuContent>
      )}
    </FilterContainer>
  );
}

enum TaskStatus {
  Pending = "PENDING",
  Complete = "COMPLETED",
}

enum TaskStatusOperators {
  TaskIs = "is",
  TaskIsEither = "is either",
}

const Operators = [
  {
    label: TaskStatusOperators.TaskIs,
    value: TaskStatusOperators.TaskIs,
  },
  {
    label: TaskStatusOperators.TaskIsEither,
    value: TaskStatusOperators.TaskIsEither,
  },
];

const combinatorToOperator = (combinator: string) => {
  if (combinator === "and") {
    return TaskStatusOperators.TaskIs;
  }
  return TaskStatusOperators.TaskIsEither;
};

const operatorToCombinator = (operator: string) => {
  if (operator === TaskStatusOperators.TaskIs) {
    return "and";
  }
  return "or";
};

const getActiveStatuses = (
  rules: RuleGroupType<FilterRule, string>["rules"]
) => {
  return rules
    .filter(
      (rule) => !isRuleGroup(rule) && rule.field === FilterFields.TaskStatus
    )
    .map((rule) => (rule as FilterRule).value);
};
