import { Menu } from "@headlessui/react";
import { MdOutlineMoreHoriz } from "react-icons/md";
import {
  setLandingPageInSettings,
  setPagePropertiesInSettings,
} from "../../utils/settings";
import { deleteNodeAndChildren } from "../../utils";
import { db } from "../../db";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DeleteIcon, FocusModeIcon, LandingPageIcon } from "../Icons";
import { useEffect, useState } from "react";
import { EfNode, FocusModeType } from "../../types";
import { Switch } from "../ui/switch";
import { useLiveQuery } from "dexie-react-hooks";
import { getThoughtpadProperties } from "./ToolBarUtils";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { RadioGroup, RadioGroupItem } from "../ui/Radio";
import { startCase } from "lodash";

export const TopBarAction = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [node, setNode] = useState<EfNode | undefined>(undefined);
  const { thoughtpad, thoughtpadProperties } =
    useLiveQuery(() => getThoughtpadProperties(), []) || {};

  useEffect(() => {
    const getAndSetNode = async () => {
      if (params.pageId) {
        setNode(await db.nodes.where("id").equals(params.pageId).first());
      } else if (params.tagId) {
        setNode(await db.nodes.where("id").equals(params.tagId).first());
      } else if (params.contactId) {
        setNode(await db.nodes.where("id").equals(params.contactId).first());
      } else {
        setNode(undefined);
      }
    };
    getAndSetNode();
  }, [params]);

  const onClickMarkAsLandingPage = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete("node");
    setLandingPageInSettings(
      window.location.pathname + urlSearchParams.toString()
    );
  };

  const onClickDeletePage = async () => {
    if (!node) {
      return;
    }
    const confirmed = confirm(`Are you sure you want to delete?`);
    if (!confirmed) return;
    await deleteNodeAndChildren(node);
    navigate("/");
  };

  const onCheckChanged = (focusModeType: FocusModeType) => {
    setPagePropertiesInSettings({
      [thoughtpad?.id!]: {
        focusMode: {
          type: focusModeType,
        },
      },
    });
  };

  return (
    <Menu as="div" id="side-bar-acton" className="flex relative">
      <Menu.Button className="h-5 w-5">
        <MdOutlineMoreHoriz className="w-full h-full" />
      </Menu.Button>
      <Menu.Items className="absolute right-0 top-4 mt-2 w-80 origin-top-right divide-y z-30 divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
        <Menu.Item as="div" className="w-full">
          {({ close }) => (
            <span
              onClick={() => {
                onClickMarkAsLandingPage();
                close();
              }}
              className="flex items-center justify-start font-normal space-x-1.5 px-3 py-2 text-gray-700 hover:rounded-md hover:bg-gray-100 cursor-pointer"
            >
              <LandingPageIcon />
              <div>Set as landing page</div>
            </span>
          )}
        </Menu.Item>
        {node && !node?.properties?.isSystemCreated && (
          <Menu.Item as="div" className="w-full">
            <span
              onClick={onClickDeletePage}
              className="flex items-center justify-start font-normal space-x-4 px-3 py-2 text-gray-600 hover:rounded-md hover:bg-gray-100 cursor-pointer"
            >
              <DeleteIcon />
              <div>Delete</div>
            </span>
          </Menu.Item>
        )}
        {!!location.pathname.includes("thoughtpad") && (
          <Menu.Item as="div" className="w-full">
            <Accordion
              type="single"
              collapsible
              className="w-full font-normal"
              onClick={(e) => e.preventDefault()}
            >
              <AccordionItem value="item-1" className="border-0">
                <AccordionTrigger className="p-0 px-3 py-2 bg-slate-100 font-normal">
                  <div className="flex space-x-3 ">
                    <FocusModeIcon className="mt-1" />
                    <div>Focus Mode</div>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="space-y-2 ml-7 px-3">
                    <RadioGroup
                      defaultValue={thoughtpadProperties?.focusMode?.type}
                      className="first:pt-2"
                    >
                      {Object.entries(FocusModeType).map(([key, value]) => {
                        return (
                          <span
                            className="py-0 flex justify-between cursor-pointer"
                            key={key}
                            onClick={() => onCheckChanged(key as FocusModeType)}
                          >
                            <div>{startCase(value.toLowerCase())}</div>
                            <RadioGroupItem
                              value={key}
                              checked={
                                (thoughtpadProperties?.focusMode?.type ||
                                  FocusModeType.DIM_OTHER_BLOCKS) === key
                              }
                            />
                          </span>
                        );
                      })}
                    </RadioGroup>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </Menu.Item>
        )}
      </Menu.Items>
    </Menu>
  );
};
