import { NavLink, useParams } from "react-router-dom";
import { DataTest } from "../../tests/e2e/utils/constants";
import { db } from "../db";
import { useQuery } from "../hooks/useQuery";
import { btn } from "../styles/classes";
import { PaginatedPresistedNode } from "./PaginatedPresistedNode";
import { PresistedNode } from "./PresistedNode";

export function OldPageRoute() {
  const { pageId = "" } = useParams();
  const [page, loading] = useQuery(() => db.nodes.get(pageId), [pageId]);

  if (loading) return null;

  return (
    <div className="max-w-5xl mx-auto my-4">
      <div className="flex mb-4">
        <NavLink to={`/pages/${pageId}`} className={btn}>
          Use New Editor
        </NavLink>
      </div>
      {page?.properties?.isSystemCreated && (
        <div
          className="font-bold text-4xl mb-8 is-empty"
          data-testid={DataTest.File}
        >
          {page.titleText}
        </div>
      )}
      {page ? (
        <>
          {page.properties?.isSystemCreated && page.titleText === "Files" ? (
            <PaginatedPresistedNode
              key={pageId}
              node={page}
              tiptapProps={{ autofocus: true }}
            />
          ) : (
            <PresistedNode
              key={pageId}
              node={page}
              tiptapProps={{ autofocus: true }}
            />
          )}
        </>
      ) : (
        <div className="text-center">
          <h1 className="text-4xl font-bold">Page not found</h1>
          <p className="text-gray-500">
            The page you are looking for does not exist.
          </p>
        </div>
      )}
    </div>
  );
}
