import { findNodeAncestors } from "../utils/findNodeAncestors";
import {
  findNodeDescendents,
  findNodeDescendentsNode,
} from "../utils/findNodeDescendents";
import { Cache } from "./cache";
import { EfNode } from "../types";
import { getTagWithFullName } from "../utils/getTagWithFullName";
import { Dictionary } from "lodash";

// using base cache class to create a TagCache
export const tagFullNameCache = new Cache<
  string,
  EfNode & { fullName?: string }
>({
  capacity: 15000, // max of 15k items
  duration: 3600000, // 30min max time for each key in milli seconds,
  notifyAfterDelete: (key: string) => {
    populateAndGetTagCache(key);
  },
});

// updating tag and its children in cache with correct fullName.
export const updateTagAndItsChildrenInCache = async (tagId: string) => {
  const descendents = await findNodeDescendentsNode(tagId);
  descendents.forEach(async (descendent) => {
    const ancestors = await findNodeAncestors(descendent.id);
    updateTagCacheForKey(descendent.id, {
      ...descendent,
      fullName: ancestors
        .map((a) => a.titleText)
        .reverse()
        .join("."),
    });
  });
};

const findParentNodes = (
  currTag: EfNode,
  tagsGroupByParentId: Dictionary<EfNode[]>
) => {
  const ancestors: EfNode[] = [];
  let tag: EfNode | undefined = currTag;
  while (!!tag) {
    ancestors.push(tag);
    tag = tag.parentId ? tagsGroupByParentId[tag.parentId]?.[0] : undefined;
  }

  return {
    ...currTag,
    fullName: ancestors
      .map((a) => a.titleText)
      .reverse()
      .join("."),
  };
};

export const populateTagCacheInitially = async (
  tagId: string,
  tagsGroupById: Dictionary<EfNode[]>
) => {
  tagFullNameCache.set(
    tagId,
    findParentNodes(tagsGroupById[tagId][0], tagsGroupById)
  );
};

export const populateAndGetTagCache = async (tagId: string, hard = false) => {
  if (hard || !tagFullNameCache.has(tagId)) {
    tagFullNameCache.set(tagId, await getTagWithFullName(tagId));
  }
  return tagFullNameCache.get(tagId);
};

// deleting tag and its children in cache.
export const deleteTagAndItsChildrenInCache = async (tagId: string) => {
  const tagInCache = tagFullNameCache.get(tagId);
  if (!tagInCache) {
    return;
  }
  const descendentIds = await findNodeDescendents(tagId);
  descendentIds.forEach((descendentId) => {
    tagFullNameCache.deleteKey(descendentId);
  });
};

// update cache tag key
export const updateTagCacheForKey = (
  tagId: string,
  newObj: EfNode & { fullName?: string }
) => {
  const currValue = tagFullNameCache.get(tagId);
  tagFullNameCache.set(tagId, {
    ...(currValue || {}),
    ...newObj,
  });
};
