import { channel } from "@/hooks/useAutoSync";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert";
import { TabsMessageType } from "@/types";
import { useEffect, useState } from "react";
import { getNodesToPush } from "@/utils";

export const SchemaVersionMissmatch = ({
  isVersionMissmatch,
}: {
  isVersionMissmatch: boolean;
}) => {
  const [nodesToPush, setNodesToPush] = useState<number | undefined>(undefined);

  const onClickReload = () => {
    // reloading all the tabs
    channel.postMessage(TabsMessageType.RELOAD);
    // reloading current tab
    window.location.reload();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getNodesToPush().then((nodes) => {
        setNodesToPush(nodes.length);
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <AlertDialog open={isVersionMissmatch} onOpenChange={() => {}}>
      <AlertDialogContent
        onEscapeKeyDown={(e) => {
          e.preventDefault();
        }}
        className="w-[90%] rounded-lg"
      >
        <div className="space-y-2">
          <AlertDialogHeader>
            <AlertDialogTitle>
              There is a new version available
            </AlertDialogTitle>
            <AlertDialogDescription className="!mb-2">
              Please reload EF to get latest version
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction
              onClick={onClickReload}
              disabled={nodesToPush != 0}
            >
              Reload
            </AlertDialogAction>
          </AlertDialogFooter>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};
