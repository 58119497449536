import { useLiveQuery } from "dexie-react-hooks";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { db } from "../db";
import { EfNodeType } from "../graphql";
import { btn } from "../styles/classes";
import { PresistedNode } from "./PresistedNode";

export function OldThoughtPadRoute() {
  const thoughtPad = useLiveQuery(() =>
    db.nodes.where("nodeType").equals(EfNodeType.ThoughtPad).first()
  );

  useEffect(() => {
    if (thoughtPad) {
      // In case open from iOS send message to the native side so it will know when the app is completely loaded
      setTimeout(() => {
        if (window.webkit?.messageHandlers?.iosListener?.postMessage) {
          window.webkit.messageHandlers.iosListener.postMessage("app-loaded");
        }
      });
    }
  }, [thoughtPad]);

  if (!thoughtPad) return null;

  return (
    <div className="max-w-5xl mx-auto my-4">
      <div className="flex mb-4">
        <NavLink to={`/thoughtpad`} className={btn}>
          Use New Thoughtpad
        </NavLink>
      </div>
      <PresistedNode node={thoughtPad} tiptapProps={{ autofocus: true }} />
    </div>
  );
}
