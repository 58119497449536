import { useEffect, useState } from "react";
import { isIOS } from "react-device-detect";

interface Version {
  version: number;
  build: number;
}

const MIN_IOS: Version = {
  version: 0, // Min version eg: 1.1
  build: 0, // Min build for version eg: 163
};

const getMinVersion = () => {
  if (isIOS) return MIN_IOS;
};

const getCurrentVersion = () => {
  if (isIOS && window.iosVersion && window.iosBuild) {
    return {
      version: parseFloat(window.iosVersion),
      build: parseInt(window.iosBuild),
    };
  }
};

export enum VersionCheckStatus {
  Loading,
  Passed,
  Failed,
}

export const useAppVersionCheck = () => {
  const [versionCheckStatus, setVersionCheckStatus] =
    useState<VersionCheckStatus>(VersionCheckStatus.Loading);

  const checkVersion = (currentVersion: Version, minVersion: Version) => {
    // Check if the current version is less than the minimum version
    if (currentVersion.version < minVersion.version) {
      return false;
    } else if (currentVersion.version === minVersion.version) {
      // If we are on the same version we must check the build
      if (currentVersion.build < minVersion.build) return false;
    }
    return true;
  };

  useEffect(() => {
    try {
      const minVersion = getMinVersion();
      const currentVersion = getCurrentVersion();
      if (minVersion && currentVersion) {
        const passed = checkVersion(currentVersion, minVersion);
        setVersionCheckStatus(
          passed ? VersionCheckStatus.Passed : VersionCheckStatus.Failed
        );
      } else {
        // If we can't get the version, assume it's good (we are probably on web)
        setVersionCheckStatus(VersionCheckStatus.Passed);
      }
    } catch (error) {
      console.error(`Failed version check`, error);
      setVersionCheckStatus(VersionCheckStatus.Passed);
    }
  }, []);

  return {
    failedVersionCheck: versionCheckStatus === VersionCheckStatus.Failed,
    loadingVersionCheck: versionCheckStatus === VersionCheckStatus.Loading,
  };
};
