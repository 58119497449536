import { PmNode } from "../types";

export function getNodeText(node: PmNode) {
  const result: string[] = [];

  if (node.type === "text" && node.text !== undefined) {
    result.push(node.text);
  }

  for (const child of node.content ?? []) {
    result.push(...getNodeText(child));
  }

  return result;
}
