import {
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { DataTest } from "../../tests/e2e/utils/constants";
import { isEmpty } from "lodash";
import clsx from "clsx";
import { Tooltip, TooltipContent, TooltipTrigger } from "./Tooltip";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../db";
import { EfNodeType } from "../graphql";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";

export const SuggestionList = forwardRef(
  (
    {
      items,
      command,
      query,
    }: {
      items: {
        text?: string;
        id: string;
        label: string;
        node?: ReactNode;
        action: () => void;
        modifiedTime?: Date;
        actualQuery: string;
        path?: string;
        showMetaDetails?: boolean;
      }[];
      command: (item: any) => void;
      query: string;
    },
    ref
  ) => {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [showTooltip, setShowTooltip] = useState(false);
    const virtuosoRef = useRef<VirtuosoHandle>(null);
    const userNode = useLiveQuery(() => {
      return db.nodes.where("nodeType").equals(EfNodeType.User).first();
    });

    const selectItem = (index: number) => {
      const item = items[index];

      if (item) {
        if (item.action) {
          item.action();
        }
        command(item);
      }
    };

    const upHandler = () => {
      let nextIndex = (selectedIndex + items.length - 1) % items.length;
      virtuosoRef.current?.scrollIntoView({
        index: nextIndex,
        behavior: "smooth",
        done: () => {
          setSelectedIndex(nextIndex);
        },
      });
    };

    const downHandler = () => {
      let nextIndex = (selectedIndex + 1) % items.length;
      virtuosoRef.current?.scrollIntoView({
        index: nextIndex,
        behavior: "smooth",
        done: () => {
          setSelectedIndex(nextIndex);
        },
      });
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(-1), [items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event: { key } }: { event: KeyboardEvent }) => {
        if (!items?.length) {
          return false;
        }
        switch (key) {
          case "ArrowUp": {
            upHandler();
            return true;
          }
          case "ArrowDown": {
            downHandler();
            return true;
          }
          case "Enter": {
            enterHandler();
            return true;
          }
          default: {
            return false;
          }
        }
      },
    }));

    return (
      <div
        className={clsx(
          "flex flex-col items-start bg-white shadow-2xl w-80 h-36 overflow-auto rounded-md p-1 cursor-pointer text-gray-700",
          {
            hidden: !items.length,
            "sm:h-48": items.length < 6,
            "sm:h-72": items.length >= 6,
          }
        )}
        data-testid={DataTest.SlashMenu}
      >
        <Virtuoso
          style={{ height: "100%", width: "100%" }}
          data={items}
          ref={virtuosoRef}
          itemContent={(
            index,
            {
              text,
              id,
              label,
              node,
              actualQuery,
              path,
              modifiedTime,
              showMetaDetails,
            }
          ) => {
            const textNodeString = text || label;
            const matchedIndex = isEmpty(actualQuery || query)
              ? -1
              : textNodeString
                  .toLocaleLowerCase()
                  .indexOf(
                    actualQuery?.toLocaleLowerCase() ||
                      query?.toLocaleLowerCase()
                  );
            const textNode = node || (
              <div>
                {textNodeString.substring(0, matchedIndex)}
                <b>
                  {textNodeString.substring(
                    matchedIndex,
                    matchedIndex + (actualQuery || query).length
                  )}
                </b>
                {textNodeString.substring(
                  matchedIndex + (actualQuery || query).length,
                  textNodeString.length
                )}
              </div>
            );
            return (
              <Tooltip
                open={showTooltip && index === selectedIndex}
                placement="right"
                key={id}
              >
                <TooltipTrigger className="flex w-full">
                  <span
                    className={clsx("w-full rounded-md px-3 py-2 break-words", {
                      ["bg-slate-200"]: index === selectedIndex,
                    })}
                    key={id}
                    onClick={() => selectItem(index)}
                    onMouseEnter={() => {
                      setShowTooltip(true);
                      setSelectedIndex(index);
                    }}
                    onMouseLeave={() => setShowTooltip(false)}
                    data-testid={`${DataTest.TagSuggestion}${textNodeString}`}
                  >
                    {textNode}
                    {path && (
                      <div className="text-slate-500 text-sm">{path}</div>
                    )}
                  </span>
                </TooltipTrigger>
                {showMetaDetails && (
                  <TooltipContent>
                    <div className="bg-slate-100 shadow-2xl w-80 ml-2 py-2 rounded-md pl-2">
                      <div>Created by: {userNode?.titleText}</div>
                      <div>
                        Edited on:{" "}
                        {modifiedTime?.getFullYear() +
                          "/" +
                          modifiedTime?.getMonth() +
                          "/" +
                          modifiedTime?.getDay()}
                      </div>
                    </div>
                  </TooltipContent>
                )}
              </Tooltip>
            );
          }}
        />
      </div>
    );
  }
);
