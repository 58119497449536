import {
  MdAlignHorizontalLeft,
  MdCode,
  MdFormatAlignRight,
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdFormatStrikethrough,
  MdFormatUnderlined,
} from "react-icons/md";
import { liftNode, sinkNode } from "../editorUtils/liftAndSink";
import { ToolbarOption, ToolbarType } from "./types";
import { RiH1, RiH2, RiH3 } from "react-icons/ri";
import { updateNode } from "../../../utils";
import {
  BackToTopIcon,
  CheckBoxIcon,
  CloseIcon,
  FormattingIcon,
  LiftIcon,
  MentionIcon,
  PageRefIcon,
  ParagraphIcon,
  SinkIcon,
  TagIcon,
  TextIcon,
} from "../../Icons";
import { BiUndo, BiRedo } from "react-icons/bi";
import { BsSlashSquare } from "react-icons/bs";
import { FiPaperclip } from "react-icons/fi";
import { uploadFileCommand } from "../../../utils/uploadFileCommand";
import { toggleTask } from "../editorUtils/task";
import { shouldDisable } from "@/extensions/new-tiptap/CustomExtension";

const editorOptions: ToolbarOption[] = [
  {
    name: "lift",
    onClick: async ({ node, historyManager, setCurrentId }) => {
      if (!node) return;
      await historyManager.run({
        redo: async () => {
          await liftNode(node);
        },
        undo: async () => {
          void updateNode(node);
          setCurrentId(node.id);
        },
      });
    },
    component: <LiftIcon />,
  },
  {
    name: "sink",
    onClick: async ({ node, historyManager, setCurrentId, relativeDepth }) => {
      if (!node) return;
      if (shouldDisable(node, relativeDepth)) return;
      await historyManager.run({
        redo: async () => {
          await sinkNode(node);
        },
        undo: async () => {
          void updateNode(node);
          setCurrentId(node.id);
        },
      });
    },
    component: <SinkIcon />,
  },
  {
    name: "jumpToTop",
    onClick: ({ jumpToTop, node }) => {
      void jumpToTop(node);
    },
    component: <BackToTopIcon />,
  },
  {
    name: "tag",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.view.focus();
      editor.commands.insertContent("#");
    },
    component: <TagIcon />,
  },
  {
    name: "toggleTask",
    onClick: async ({ node, historyManager }) => {
      if (!node) return;
      await historyManager.run({
        redo: async () => {
          await toggleTask(node);
        },
        undo: async () => {
          await updateNode({
            ...node,
          });
        },
      });
    },
    component: <CheckBoxIcon />,
  },
  {
    name: "layout",
    onClick: ({ setToolbarType }) => {
      setToolbarType(ToolbarType.Layout);
    },
    component: <MdFormatAlignRight />,
  },
  {
    name: "formatting",
    onClick: ({ setToolbarType, editor }) => {
      if (!editor) return;
      setToolbarType(ToolbarType.Formatting);
    },
    component: <FormattingIcon />,
  },
  {
    name: "pageReference",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.view.focus();
      editor.commands.insertContent("[[");
    },
    component: <PageRefIcon />,
  },
  {
    name: "mention",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.view.focus();
      editor.commands.insertContent("@");
    },
    component: <MentionIcon />,
  },
  {
    name: "slashMenu",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.view.focus();
      editor.commands.insertContent("/");
    },
    component: <BsSlashSquare />,
  },
  {
    name: "attach",
    onClick: ({ editor }) => {
      if (!editor) return;
      uploadFileCommand(editor);
    },
    component: <FiPaperclip className="rotate-45" />,
  },
  {
    name: "undo",
    onClick: ({ historyManager }) => historyManager.undo(),
    component: <BiUndo />,
  },
  {
    name: "redo",
    onClick: ({ historyManager }) => historyManager.redo(),
    component: <BiRedo />,
  },
];
const layoutOptions: ToolbarOption[] = [
  {
    name: "closeLayout",
    onClick: ({ editor, setToolbarType }) => {
      if (!editor) return;
      setToolbarType(ToolbarType.Main);
    },
    component: <CloseIcon />,
  },
  {
    name: "Normal Layout",
    onClick: ({ editor }) => {
      if (!editor) return;
      if (editor.isActive("codeBlock")) editor.commands.toggleCodeBlock();
      if (editor.isActive("blockquote")) editor.commands.toggleBlockquote();
      if (editor.isActive("orderedList")) editor.commands.toggleOrderedList();
      if (editor.isActive("bulletList")) editor.commands.toggleBulletList();
    },
    component: <MdAlignHorizontalLeft />,
    isActive: (editor) => {
      return !(
        editor.isActive("codeBlock") ||
        editor.isActive("blockquote") ||
        editor.isActive("orderedList") ||
        editor.isActive("bulletList")
      );
    },
  },
  {
    name: "orderedList",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.commands.toggleOrderedList();
    },
    component: <MdFormatListNumbered />,
    isActive: (editor) => editor.isActive("orderedList"),
  },
  {
    name: "bulletList",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.commands.toggleBulletList();
    },
    component: <MdFormatListBulleted />,
    isActive: (editor) => editor.isActive("bulletList"),
  },
  {
    name: "codeBlock",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.commands.toggleCodeBlock();
    },
    component: <MdCode />,
    isActive: (editor) => editor.isActive("codeBlock"),
  },
  {
    name: "quote",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.commands.toggleBlockquote();
    },
    component: <MdFormatQuote />,
    isActive: (editor) => editor.isActive("blockquote"),
  },
];
const formattingOptions: ToolbarOption[] = [
  {
    name: "closeFormatting",
    onClick: ({ editor, setToolbarType }) => {
      if (!editor) return;
      setToolbarType(ToolbarType.Main);
    },
    component: <CloseIcon />,
  },
  {
    name: "bodyText",
    onClick: ({ editor }) => {
      if (!editor) return;
      if (editor.isActive("bold")) editor.commands.toggleBold();
      if (editor.isActive("italic")) editor.commands.toggleItalic();
      if (editor.isActive("underline")) editor.commands.toggleUnderline();
      if (editor.isActive("strike")) editor.commands.toggleStrike();
    },
    isActive: (editor) => {
      return !(
        editor.isActive("bold") ||
        editor.isActive("italic") ||
        editor.isActive("underline") ||
        editor.isActive("strike")
      );
    },
    component: <TextIcon />,
  },
  {
    name: "bold",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.commands.toggleBold();
    },
    isActive: (editor) => editor.isActive("bold"),
    isDisabled: (editor) => !editor.can().toggleBold(),
    component: <MdFormatBold className="w-5 h-5" />,
  },
  {
    name: "italic",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.commands.toggleItalic();
    },
    isActive: (editor) => editor.isActive("italic"),
    isDisabled: (editor) => !editor.can().toggleItalic(),
    component: <MdFormatItalic className="w-5 h-5" />,
  },
  {
    name: "underline",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.commands.toggleUnderline();
    },
    component: <MdFormatUnderlined />,
    isActive: (editor) => editor.isActive("underline"),
  },
  {
    name: "strikethrough",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.commands.toggleStrike();
    },
    component: <MdFormatStrikethrough />,
    isActive: (editor) => editor.isActive("strike"),
  },
  {
    name: "paragraph",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.commands.setParagraph();
    },
    component: <ParagraphIcon />,
    isActive: (editor) => editor.isActive("paragraph"),
  },
  {
    name: "h1",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.commands.toggleHeading({ level: 1 });
    },
    component: <RiH1 />,
    isActive: (editor) => editor.isActive("heading", { level: 1 }),
  },
  {
    name: "h2",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.commands.toggleHeading({ level: 2 });
    },
    component: <RiH2 />,
    isActive: (editor) => editor.isActive("heading", { level: 2 }),
  },
  {
    name: "h3",
    onClick: ({ editor }) => {
      if (!editor) return;
      editor.commands.toggleHeading({ level: 3 });
    },
    component: <RiH3 />,
    isActive: (editor) => editor.isActive("heading", { level: 3 }),
  },
  // {
  //   name: "link",
  //   onClick: ({ editor }) => {
  //     if (!editor) return;
  //     editor.commands.focus();
  //   },
  //   component: <MdLink />,
  //   isActive: (editor) => editor.isActive("link"),
  // },
];

export const options = {
  [ToolbarType.Main]: editorOptions,
  [ToolbarType.Formatting]: formattingOptions,
  [ToolbarType.Layout]: layoutOptions,
};

export const toolbarStyles = {
  [ToolbarType.Main]:
    "bg-white flex row overflow-x-auto gap-x-5 justify-normal mr-10 ml-2",
  [ToolbarType.Formatting]: "bg-gray-100 flex row w-full justify-around",
  [ToolbarType.Layout]: "bg-white flex row w-full justify-around",
};
