import { groupBy } from "lodash";
import { EfNode } from "../../../types";

export const sortSidePanelTree = (
  list: EfNode[] = [],
  sortBy?: "createdDate" | "name" | "manual" | "recency",
  manualSortOrder?: string[],
  sortOrder?: "decreasing" | "increasing"
) => {
  const sortedList = list.sort((a: EfNode, b: EfNode) => {
    let sortValue = 1;
    switch (sortBy) {
      case "createdDate": {
        sortValue =
          (a.createdTime?.getTime?.() ||
            a.clientModifiedTime?.getTime?.() ||
            a.modifiedTime?.getTime?.() ||
            Infinity) -
          (b.createdTime?.getTime?.() ||
            b.clientModifiedTime?.getTime?.() ||
            b.modifiedTime?.getTime?.() ||
            Infinity);
        break;
      }
      case "name": {
        sortValue = (a.titleText ?? "")
          .toLocaleLowerCase()
          .localeCompare(b.titleText?.toLocaleLowerCase() || "");
        break;
      }
      case "manual": {
        return (
          (manualSortOrder?.indexOf(a.id!) || 0) -
          (manualSortOrder?.indexOf(b.id!) || 0)
        );
      }
      case "recency": {
        if (
          !b.relatedNodesModifiedTimeStamp &&
          !a.relatedNodesModifiedTimeStamp
        ) {
          return (a.titleText || "").localeCompare(b.titleText || "");
        }
        if (!b.relatedNodesModifiedTimeStamp) {
          return -1;
        }
        if (!a.relatedNodesModifiedTimeStamp) {
          return 1;
        }
        if (
          b.relatedNodesModifiedTimeStamp === a.relatedNodesModifiedTimeStamp
        ) {
          return (a.titleText || "").localeCompare(b.titleText || "");
        }
        return (
          b.relatedNodesModifiedTimeStamp - a.relatedNodesModifiedTimeStamp
        );
      }
    }
    return sortValue * (sortOrder === "decreasing" ? -1 : 1);
  });
  const set: Set<string> = new Set();
  if (sortBy === "recency") {
    const tagsById = groupBy(sortedList, ({ id }) => id);
    for (const tag of sortedList) {
      const parentTagsWithPosition = tag.computed.path?.split("/");
      parentTagsWithPosition
        ?.map((parentTagWithPosition) => parentTagWithPosition.split("#")[1])
        .filter((tagId) => {
          if (tagId) {
            set.add(tagId);
          }
        });
    }
    const finalArray: EfNode[] = [];
    for (const tagId of set) {
      finalArray.push(tagsById[tagId][0]);
    }
    return finalArray;
  }
  return sortedList;
};
