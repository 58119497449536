import { Button } from "@/components/ui/button";
import { useSearch } from "@/context/SearchContext";
import { cn } from "@/utils/styles";
import { ChevronLeft } from "lucide-react";
import React from "react";
import { isMobile } from "react-device-detect";

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
}

export const FilterContainer = ({ children, onClick }: Props) => {
  return (
    <div
      className="flex flex-row gap-2 rounded-lg border border-slate-100"
      style={{
        padding: "3px 5px",
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const FilterItemsContainer = ({ children }: Props) => {
  return (
    <div className="flex flex-row gap-1">
      <div
        className={cn(
          "flex flex-row gap-2 font-normal text-sm p-1 items-center",
          isMobile ? "gap-1" : "gap-2"
        )}
      >
        {children}
      </div>
    </div>
  );
};

export const FilterItemValueContainer = ({ children }: Props) => {
  return (
    <div className="flex rounded-lg h-5 w-fit p-2 flex-1 bg-slate-100 items-center justify-center">
      {children}
    </div>
  );
};

export const MobileFilterContainer = ({ children }: Props) => {
  return <div className="flex flex-col gap-2 p-3 mt-2">{children}</div>;
};

export const MobileFilterOperatorContainer = ({ children }: Props) => {
  const { setMobileFilterState } = useSearch();

  return (
    <div className="flex flex-row items-center gap-2">
      <Button
        variant="outline"
        size="icon"
        onClick={() => {
          setMobileFilterState({ open: true, field: undefined });
        }}
        className="h-5 w-5 border-0"
      >
        <ChevronLeft height={16} width={16} />
      </Button>
      {children}
    </div>
  );
};
