import { Extension } from "@tiptap/core";
import { closeHistory } from "@tiptap/pm/history";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    History: {
      closeHistory: () => ReturnType;
    };
  }
}

export const CustomHistory = Extension.create({
  name: "custom-history",

  addCommands() {
    return {
      closeHistory:
        () =>
        ({ tr }) => {
          closeHistory(tr);
          return false;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => {
        return this.editor.commands.closeHistory();
      },
    };
  },
});
