import { useParams } from "react-router-dom";
import { db } from "../db";
import {
  NodesGroupedByPage,
  NodesGroupedByPageSkeleton,
} from "./NodesGroupedByPage";
import { v4 } from "uuid";
import { EfNodeType } from "@/graphql";
import { generateKeyBetween } from "fractional-indexing";
import { EfNodeEditorData } from "@/types";
import { getFirstNodeInAnThoughtPad } from "@/utils/nodes";
import { useFocusNode } from "@/hooks/useFocusNode";
import { cn } from "@/utils/styles";
import { useQuery } from "@/hooks/useQuery";
import { useEffect, useMemo, useRef } from "react";

export const ContactRouteWapper = () => {
  const { contactId = "" } = useParams();
  return <ContactRoute contactId={contactId} key={contactId} />;
};

function ContactRoute({ contactId }: { contactId: string }) {
  const [contact, contactLoading] = useQuery(
    () => db.nodes.get(contactId),
    [contactId]
  );

  const stateChangeCountRef = useRef<number>(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contactLoading) {
      stateChangeCountRef.current += 1;
    }
  }, [contact]);

  const { nodeFocussed, onScroll, observer } = useFocusNode({
    pageId: contactId,
    ids: [contactId],
    enabled: !!contact,
    stateChangeCountRef: stateChangeCountRef,
    scrollRef: scrollRef,
  });

  const [nodes, nodesLoading] = useQuery(() => {
    return db.nodes.where("mentionIds").equals(contactId).toArray();
  }, [contactId]);

  const createNewNodeAtStart = async () => {
    const { firstNode, thoughtPad } =
      (await getFirstNodeInAnThoughtPad()) || {};
    const node: EfNodeEditorData = {
      id: v4(),
      parentId: thoughtPad?.id!,
      position: generateKeyBetween(null, firstNode?.position || null),
      nodeType: EfNodeType.Block,
      properties: {},
      tagIds: [],
      referencedPageIds: [],
      mentionIds: [contactId],
      titleText: "",
      contentText: `<p> <span data-type="mention" data-id="${contactId}"></span> </p>`,
    };
    return node;
  };

  // Based on the height of the display getting how many skeleton to show.
  const length = useMemo(() => {
    const iosTopPadding = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        "--safe-area-inset-top"
      ) || "0"
    );
    return Math.floor((window.innerHeight - 40 - iosTopPadding) / 44) - 1;
  }, []);

  if (!contact || (!nodesLoading && !nodes)) return null;

  return (
    <>
      <div
        className={cn("relative", {
          hidden: nodeFocussed,
          block: !nodeFocussed,
        })}
      >
        <div className="p-2 px-4 absolute w-full">
          <NodesGroupedByPageSkeleton length={length} />
        </div>
      </div>
      <div
        className={cn("overflow-auto overscroll-none p-2 flex-1", {
          "opacity-0": !nodeFocussed,
          "opacity-100": nodeFocussed,
          overflowAnchor: "none",
        })}
        ref={scrollRef}
        onScroll={onScroll}
      >
        <div className="max-w-5xl mx-auto mt-4" ref={observer}>
          <div className="prose mb-6" ref={headerRef}>
            <h1>@{contact.titleText}</h1>
          </div>
          <NodesGroupedByPage
            nodes={nodes || []}
            createNewNodeAtStart={createNewNodeAtStart}
            tagsOrMentions={[contactId]}
            containerScrollRef={scrollRef}
            headerRef={headerRef}
          />
        </div>
      </div>
    </>
  );
}
