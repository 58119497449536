import { EfNodeType } from "../graphql";
import { db } from "../db";
import { EfNode } from "../types";
import { Transaction } from "dexie";

export const getParentNodes = async (
  nodeId?: string,
  tx?: Transaction
): Promise<EfNode[]> => {
  if (!nodeId) {
    return [];
  }
  let node = await db.getTable("nodes", tx).get(nodeId);
  if (!node) {
    return [];
  }
  const tree = [node];
  while (node?.parentId) {
    node = await db.getTable("nodes", tx).get(node.parentId);
    if (!node) {
      return tree;
    }
    tree.push(node);
  }
  return tree;
};

export const getFirstNodeInAnThoughtPad = async () => {
  const thoughtPad = await db.nodes
    .where({ nodeType: EfNodeType.ThoughtPad })
    .first();
  if (!thoughtPad) {
    return;
  }
  return {
    firstNode: await db.nodes
      .where("[computed.visible+computed.pathInPage]")
      .above([1, `/null:${thoughtPad.id}`])
      .first(),
    thoughtPad,
  };
};

export const getFirstNodeInAnPage = async (pageId: string) => {
  const pageNode = await db.nodes.where({ id: pageId }).first();
  if (!pageNode) {
    return;
  }
  return {
    firstNode: await db.nodes
      .where("[computed.visible+computed.pathInPage]")
      .above([1, `/null:${pageNode.id}`])
      .first(),
    pageNode,
  };
};

export function sortNodesBasedOnArray<T extends EfNode>(
  nodes: T[],
  initialSortOrder: string[],
  sortOtherNodes?: (a: T, b: T) => number
) {
  return nodes.sort((a, b) => {
    const aInitialNode = initialSortOrder.indexOf(a.id);
    const bInitialNode = initialSortOrder.indexOf(b.id);
    // condition when not present in initialSortOrder
    if (aInitialNode === bInitialNode && sortOtherNodes) {
      return sortOtherNodes(a, b);
    }
    return aInitialNode - bInitialNode;
  });
}
