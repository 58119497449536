import { RefObject } from "react";
import { isMobile } from "react-device-detect";
import { scrollNodeAboveKeyboard } from "../mobileUtils";

export const onUpdate = (
  nodeElement?: HTMLElement | null,
  scrollElement?: HTMLElement | null,
  iosKeyboardHeight?: number,
  headerRef?: RefObject<HTMLElement>
) => {
  fixMobileScroll(nodeElement, scrollElement, iosKeyboardHeight, headerRef);
};

export const fixMobileScroll = (
  nodeElement?: HTMLElement | null,
  scrollElement?: HTMLElement | null,
  iosKeyboardHeight?: number,
  headerRef?: RefObject<HTMLElement>
) => {
  if (isMobile && nodeElement instanceof HTMLElement && scrollElement) {
    // If on mobile, make sure text is above keyboard
    // On first click we do not know the height of the keyboard (because it takes some time for it to open and inject height)
    // So only in this case to we wait 100ms before scrolling
    const timeToWait = iosKeyboardHeight ? 0 : 100;
    setTimeout(
      () =>
        scrollNodeAboveKeyboard(scrollElement, nodeElement, headerRef?.current),
      timeToWait
    );
  }
};
