import { EfNodeType } from "@/graphql";
import { useHistoryManager } from "@/hooks/useHistoryManager";
import { EfNode } from "@/types";
import { updateNode } from "@/utils";
import { Editor, Range } from "@tiptap/core";

// Toggle Task
export const onToggleTask =
  (node: EfNode, history: ReturnType<typeof useHistoryManager>) =>
  (range: Range, editor: Editor) => {
    history.run({
      redo: async () => {
        await toggleTask(node, range, editor);
      },
      undo: async () => {
        await updateNode({
          ...node,
        });
      },
    });
  };

export const toggleTask = async (
  node: EfNode,
  range?: Range,
  editor?: Editor
) => {
  if (editor && range) {
    editor.chain().focus().ignoreHistory().deleteRange(range).run();
  }
  await updateNode({
    ...node,
    ...(editor && { contentText: editor.getHTML() }),
    nodeType:
      node.nodeType === EfNodeType.Block ? EfNodeType.Task : EfNodeType.Block,
    properties: {
      taskStatus: "PENDING",
    },
  });
};
