import { useDragLayer } from "react-dnd";
import { EfNodeType } from "../../graphql";
import { PageElement } from "./PageTree";
import clsx from "clsx";
import { TagElement } from "./TagTree";
import { ContactElement } from "./ContactList";

// this component is used to render custom dragLayer for sidePanel
export const SidePanelTreeDraggingLayer = () => {
  // useDrag layer is used to get thee position offset of dragged element
  const { itemType, isDragging, item, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  // Based on itemType which is dragged it will return ReactElement based on that.
  function renderSidePanelTreeItem() {
    switch (itemType) {
      case EfNodeType.Page:
        return (
          <PageElement
            page={item.itemValue}
            isActive={false}
            hasChildren={item.hasChildren}
            hideActions={false}
            level={item.level}
            showDragHandles={true}
          />
        );
      case EfNodeType.Tag:
        return (
          <TagElement
            tag={item.itemValue}
            isActive={false}
            hasChildren={item.hasChildren}
            level={item.level}
            showDragHandles={true}
          />
        );
      case EfNodeType.Contact:
        return (
          <ContactElement
            contact={item.itemValue}
            isActive={false}
            showDragHandles={true}
          />
        );
      default:
        return null;
    }
  }

  // if any item is not being dragged then return null
  if (!isDragging) {
    return null;
  }

  return (
    <div
      style={{
        transform: `translate(${currentOffset?.x}px, ${currentOffset?.y}px)`,
      }}
      className={clsx(
        "fixed pointer-events-none z-50 left-0 -top-10 bg-gray-200 w-72",
        { ["opacity-50"]: isDragging }
      )}
    >
      {renderSidePanelTreeItem()}
    </div>
  );
};
