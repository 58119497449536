import { mergeAttributes, Node } from "@tiptap/react";
import { TextSelection } from "prosemirror-state";
import { findParentNodeOfType } from "prosemirror-utils";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    title: {
      moveSelectionToFirstBlock: () => ReturnType;
    };
  }
}

type HTMLAttributes = {};
export interface CustomExtensionOptions {
  HTMLAttributes: HTMLAttributes;
}

export const Title = Node.create<CustomExtensionOptions>({
  name: "title",
  marks: "",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  content: "text*",

  parseHTML() {
    return [{ tag: "div" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: "font-semibold text-[35px] leading-[120%] mb-7 title",
      }),
      0,
    ];
  },

  addCommands() {
    return {
      moveSelectionToFirstBlock:
        () =>
        ({ state, tr }) => {
          const title = findParentNodeOfType(state.schema.nodes.title)(
            state.selection
          );
          if (!title) return false;
          const newPos = title.pos + title.node.nodeSize;
          tr.setSelection(TextSelection.near(tr.doc.resolve(newPos)));
          return true;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.moveSelectionToFirstBlock(),
      Tab: () => this.editor.commands.moveSelectionToFirstBlock(),
    };
  },
});
