import { v4 } from "uuid";
import { EfNodeType } from "../graphql";
import { createNode } from "./sync";

export async function createContactNode(
  id: string = v4(),
  name: string,
  email: string
) {
  await createNode({
    id,
    nodeType: EfNodeType.Contact,
    contentText: "",
    titleText: name,
    parentId: null,
    position: null,
    tagIds: [],
    referencedPageIds: [],
    properties: {
      contactEmails: [{ email }],
    },
  });
}
