import { Editor } from "@tiptap/react";
import { TextSelection } from "prosemirror-state";
import { NodeRendererClass } from "../NewNodeRenderer";
import { EfNode } from "../../types";
import { db } from "../../db";
import { RefObject } from "react";
import {
  getEditorId,
  getNodeIdFromEditorId,
} from "../VirtualizedEditor/editorUtils/genericUtils";

export interface Coord {
  x: number;
  y: number;
}

export interface SelectionBounds {
  anchorNode?: EfNode;
  currentNode?: EfNode;
}

export const SelectionWrapperClass = "selection-wrapper";

/**
 * Draw the area selection
 */
export function drawAreaSelection(
  element: HTMLDivElement,
  coord1: Coord,
  coord2: Coord
) {
  element.style.top = `${Math.min(coord1.y, coord2.y)}px`;
  element.style.left = `${Math.min(coord1.x, coord2.x)}px`;
  element.style.height = `${Math.abs(coord2.y - coord1.y)}px`;
  element.style.width = `${Math.abs(coord2.x - coord1.x)}px`;
}

// Get map for editorID (nodeID) to tiptap editor
export const getEditorsMap = () => window.editors || {};

/**
 * Checks if clickedTarget, is part of a node
 */
export const hasClickedNode = (
  clickedTarget: HTMLElement,
  nodes: HTMLDivElement[]
) => {
  for (const node of nodes) {
    if (node.contains(clickedTarget)) {
      return true;
    }
  }
  return false;
};

/**
 * Query all nodes, and get their rects
 */
export function getNodes(params?: {
  selector?: string;
  parent?: RefObject<Element>;
}) {
  const selector =
    params?.selector || `.${SelectionWrapperClass} .${NodeRendererClass}`;
  const elements: Record<string, HTMLDivElement> = {};
  const allElements = (params?.parent?.current || document).querySelectorAll(
    selector
  );
  for (const el of allElements) {
    if (!(el instanceof HTMLDivElement)) continue;
    const nodeID = getNodeIdFromEditorId(el.dataset.nodeid) || "";
    elements[nodeID] = el;
  }
  return elements;
}

/**
 * Returns position of rect
 */
export const getPosition = (element: HTMLDivElement) => {
  const top = element.offsetTop;
  const bottom = top + element.offsetHeight;
  const left = element.offsetLeft;
  const right = left + element.offsetWidth;
  return {
    top,
    right,
    bottom,
    left,
  };
};

/**
 * Get's the closest node ID to the given coord
 * @param mouseCoord
 * @param scrollRect
 * @param scrollRef
 * @returns closest nodeID
 */
export const getAnchorNode = (
  mouseCoord: Coord,
  nodeElements: Record<string, HTMLDivElement>
) => {
  for (const [nodeID, element] of Object.entries(nodeElements)) {
    const { top, bottom } = getPosition(element);
    if (mouseCoord.y > top && mouseCoord.y < bottom) {
      return nodeID;
    }
  }
};

/**
 * Returns the current node in selection
 * The current node in selection is the node that is in the area selection
 * and is closest to the selection end
 * @param selectionStart area selection start (mouse down)
 * @param selectionEnd are selection end (current position of mouse)
 * @param nodeRects
 * @param scrollRect
 * @param scrollRef
 */
export const getCurrentNodeInSelection = (
  selectionStart: Coord,
  selectionEnd: Coord,
  nodeElements: Record<string, HTMLDivElement>
) => {
  for (const [nodeID, element] of Object.entries(nodeElements)) {
    const { top, bottom, left, right } = getPosition(element);
    // First we must find the node in selection end top + bottom bounds
    if (selectionEnd.y < top || selectionEnd.y > bottom) continue;

    // Now we must check that the node is inside area selection bounds
    const x1 = Math.min(selectionStart.x, selectionEnd.x);
    const x2 = Math.max(selectionStart.x, selectionEnd.x);
    const y1 = Math.min(selectionStart.y, selectionEnd.y);
    const y2 = Math.max(selectionStart.y, selectionEnd.y);
    if (right >= x1 && left <= x2 && bottom >= y1 && top <= y2) {
      return nodeID;
    }
  }
};

export const getModifierKey = (event: KeyboardEvent) => {
  if (event.metaKey) return "Meta";
  if (event.ctrlKey) return "Ctrl";
  if (event.altKey) return "Alt";
  if (event.shiftKey) return "Shift";
  return "";
};

export const focusEditor = (editorToFocus: Editor) => {
  // Focus on first node
  editorToFocus
    .chain()
    .focus()
    .setTextSelection(TextSelection.atEnd(editorToFocus.state.doc))
    .ignoreHistory() // This is important as do not trigger a history event
    .run();
};

/**
 * If we are selecting nodes, but no text is selected (natively) then scroll will not work (chrome specific)
 * The scroll only works if there has been a text selection, so in this function we select text
 * for a single frame and then restore the original selection
 *
 * We could have also solved this by:
 * Setting content-editable on nodes to false - this overkill and would have caused a lot of issues
 * Manually scrolling - this would not behave like default browser scroll and will be a lot of work
 *
 * For more context see https://github.com/execfn/exec-world/pull/428
 */
export const enableScroll = (rootNodeId: string, anchorNodeID: string) => {
  const selection = window.getSelection(); // Get the current selection
  const range = document.createRange(); // Create a new range
  const queryAnchorNode = document.querySelector(
    `[data-nodeid="${getEditorId(rootNodeId, anchorNodeID)}"]`
  );

  if (!queryAnchorNode || !selection) return;
  // Copy initial range
  const initialRange =
    selection.rangeCount > 0 && selection.getRangeAt(0).cloneRange();

  // Select the anchor node
  range.selectNodeContents(queryAnchorNode);
  selection.removeAllRanges(); // Clear any existing selections
  selection.addRange(range); // Add the new range to the selection

  setTimeout(() => {
    // Restore selection in next run
    const updatedSelection = window.getSelection();
    if (updatedSelection && initialRange) {
      updatedSelection.removeAllRanges();
      updatedSelection.addRange(initialRange);
    }
  });
};

export const getSelectedNodes = async (areaSelectedBounds: SelectionBounds) => {
  if (!areaSelectedBounds.anchorNode || !areaSelectedBounds.currentNode)
    return [];
  if (
    !areaSelectedBounds.anchorNode.computed.pathInPage ||
    !areaSelectedBounds.currentNode.computed.pathInPage
  )
    return [];
  const anchor = areaSelectedBounds.anchorNode.computed.pathInPage;
  const current = areaSelectedBounds.currentNode.computed.pathInPage;
  const lower = anchor <= current ? anchor : current;
  const upper = anchor <= current ? current : anchor;

  const nodes = await db.nodes
    .where("[computed.visible+computed.pathInPage]")
    .between([1, lower], [1, upper], true, true)
    .toArray();

  return nodes;
};
