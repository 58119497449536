import { useMenu } from "@/context/MenuContext";
import { Transition, Dialog } from "@headlessui/react";
import { Fragment } from "react";

interface MenuDialogProps {
  navigation: React.ReactNode;
}
export const MenuDialog = ({ navigation }: MenuDialogProps) => {
  const { isMenuOpen, setIsMenuOpen } = useMenu();
  return (
    <Transition.Root show={isMenuOpen} as={Fragment}>
      <Dialog
        static
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        as="div"
        className="lg:hidden fixed inset-0 z-40 bg-gray-700 bg-opacity-50"
      >
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-200 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-200 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <Dialog.Panel className="relative w-72 h-full">
            {navigation}
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};
