import Base from "@tiptap/extension-mention";
import {
  ReactNodeViewRenderer,
  ReactRenderer,
  mergeAttributes,
} from "@tiptap/react";
import { Editor } from "@tiptap/core";
import { SuggestionOptions } from "@tiptap/suggestion";
import { PluginKey, TextSelection } from "prosemirror-state";
import tippy, { Instance } from "tippy.js";
import { db } from "../db";
import { EfNodeType, Maybe } from "../graphql";
import { PageReferenceNodeView } from "../components/PageReferenceNodeView";
import { v4 } from "uuid";
import { createPageNode } from "../utils/pages";
import { SuggestionList } from "../components/SuggestionList";
import { BsPlusSquare } from "react-icons/bs";
import React from "react";

const RefPluginKey = new PluginKey("ref");

const getPathToCurrentNode = (
  id: string,
  pageMap: {
    [key: string]: { titleText?: string; parentId?: string };
  },
  currNodeId: string
): string[] => {
  const page = pageMap[id];
  if (!page) return [];
  if (!page.parentId) {
    if (id === currNodeId) {
      return [];
    }
    return [page.titleText!];
  }
  const array = getPathToCurrentNode(page.parentId, pageMap, currNodeId);
  if (currNodeId !== id) {
    array.push(page.titleText!);
  }
  return array;
};

const getFulQuery = async (editor: Editor, query: string) =>
  await new Promise<string>((resolve) =>
    editor
      .chain()
      .command(({ state }) => {
        const { anchor } = state.selection;
        let textNodeFound = false;
        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.isText) {
            // below gets us the text between '[[' and ']]' assuming cursor is in between '[[' and ']]'
            // lets say we have below text in editor
            // something [[testing]] some text [[test]]
            // when cursor is at position 36(0 based index) then
            // textPrevToCursor = 'something [[testing]] some text [[te' and textNextToCursor = 'st]]'
            // we find last index of '[[' in textPrevToCursor we get 32
            // we find first index of ']]' in textNextToCursor we get 2
            // finally we do substring to get text after index 32 from textPrevToCursor till end and text before index 2 in textNextToCursor
            // above gives us text between '[[' and ']]'.
            textNodeFound = true;
            const positionOfCursorInText = state.selection.anchor - pos;
            const textPrevToCursor =
              node.text?.substring(0, positionOfCursorInText) || "";
            const textNextToCursor =
              node.text?.substring(positionOfCursorInText, node.text.length) ||
              "";
            const startIndex = textPrevToCursor.lastIndexOf("[[");
            const endIndex = textNextToCursor.indexOf("]]");
            // this condition is added if there is no ']]' then fallback to the default query.
            if (endIndex === -1) {
              resolve(query);
            }
            resolve(
              (textPrevToCursor?.substring(startIndex + 2) || "") +
                textNextToCursor?.substring(0, endIndex)
            );
          }
        });
        if (!textNodeFound) {
          resolve("");
        }
        return false;
      })
      .run()
  );

const suggestion: Omit<SuggestionOptions, "editor"> = {
  char: "[[",
  pluginKey: RefPluginKey,
  items: async ({ query, editor }) => {
    //adding filter for ]] or ] then just return empty array.
    if (query.match(/(]]|])[a-zA-Z0-9]*/g)) {
      return [];
    }
    const pages = await db.nodes
      .where("nodeType")
      .equals(EfNodeType.Page)
      .toArray();
    // query is the content from [[ to the cursor, fullQuery is the content from [[ to ]]
    const fullQuery = await getFulQuery(editor, query);
    const filteredPages = pages.filter(({ deleted }) => !deleted);
    const pagesMap = Object.assign(
      {},
      ...filteredPages.map(({ id, titleText, parentId }) => ({
        [id]: { titleText, parentId },
      }))
    );
    // filtering it with title text and then sorting the list by last modified time.
    const mappedPages: {
      label: Maybe<string>;
      id: Maybe<string>;
      text?: Maybe<string>;
      node?: React.ReactNode;
      action?: () => Promise<void>;
      actualQuery?: string;
      path?: string;
    }[] = filteredPages
      .filter(({ titleText }) =>
        titleText?.toLowerCase().includes(fullQuery.toLowerCase())
      )
      .sort(
        (a, b) =>
          (b.clientModifiedTime?.getTime?.() ||
            b.modifiedTime?.getTime() ||
            0) -
          (a.clientModifiedTime?.getTime?.() || a.modifiedTime?.getTime() || 0)
      )
      .map(({ id, titleText, modifiedTime, clientModifiedTime }) => {
        const pathFromParent = getPathToCurrentNode(id, pagesMap, id);
        return {
          id: id,
          label: titleText,
          text: titleText,
          fullQuery,
          modifiedTime: clientModifiedTime || modifiedTime,
          path: pathFromParent.length ? pathFromParent.join("/") + "/" : "",
          showMetaDetails: true,
        };
      });

    // if exact match is not there then add create option to the list
    const exactMatch = pages.find(({ titleText }) => titleText === fullQuery);
    if (!exactMatch && fullQuery) {
      const id = v4();
      mappedPages.unshift({
        label: fullQuery,
        id,
        text: `Create ${fullQuery}`,
        node: (
          <p className="flex items-center space-x-2">
            <BsPlusSquare /> <span>Create new page</span> <b>{fullQuery}</b>
          </p>
        ),
        action: async () => {
          await createPageNode(id, fullQuery);
        },
      });
    }
    return mappedPages;
  },
  render: () => {
    let reactRenderer: ReactRenderer;
    let popup: Instance[];

    return {
      onStart: (props) => {
        reactRenderer = new ReactRenderer(SuggestionList, {
          props,
          editor: props.editor,
        });
        if (!props.clientRect || !props.editor.isFocused) {
          return;
        }
        // @ts-ignore
        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
          hideOnClick: false,
        });
      },

      onUpdate(props) {
        reactRenderer.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          // @ts-ignore
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (!popup) return;
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        if (popup[0].state.isVisible) {
          // @ts-ignore
          return reactRenderer.ref?.onKeyDown(props);
        }
      },

      onExit() {
        popup && popup[0].destroy();
        reactRenderer?.destroy();
      },
    };
  },
};

export const PageRef = Base.extend({
  name: "PageRef",
  priority: 100000,
  addNodeView() {
    return ReactNodeViewRenderer(PageReferenceNodeView, {
      update: ({ oldNode, newNode }) => {
        if (
          oldNode.attrs.id !== newNode.attrs.id ||
          oldNode.attrs.label !== newNode.attrs.label
        ) {
          // Ignore update in case prosemirror try to update with other page ref
          return false;
        }
        return true;
      },
    });
  },
  renderHTML({ node }) {
    return [
      "span",
      mergeAttributes({
        "data-type": this.name,
        "data-id": node.attrs.id,
        "data-label": node.attrs.label,
      }),
    ];
  },
  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isPageRef = false;
          const {
            selection: { empty, anchor },
            schema: {
              nodes: { PageRef },
            },
          } = state;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type === PageRef) {
              isPageRef = true;
              tr.insertText(
                this.options.suggestion.char + node.attrs.label + "]" || "",
                pos,
                pos + node.nodeSize
              );

              return false;
            }
          });

          return isPageRef;
        }),
      ArrowLeft: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isPageRef = false;
          const {
            selection: { empty, anchor },
            schema: {
              nodes: { PageRef },
            },
          } = state;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type === PageRef) {
              isPageRef = true;
              tr.insertText(
                this.options.suggestion.char + node.attrs.label + "]]" || "",
                pos,
                pos + node.nodeSize
              );
              return false;
            }
          });
          if (isPageRef) {
            state.tr.setSelection(
              TextSelection.create(state.tr.doc, state.selection.anchor - 1)
            );
          }
          return isPageRef;
        }),
      ArrowRight: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isPageRef = false;
          const {
            selection: { empty, anchor },
            schema: {
              nodes: { PageRef },
            },
          } = state;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor, anchor + 1, (node, pos) => {
            if (node.type === PageRef) {
              isPageRef = true;
              tr.insertText(
                this.options.suggestion.char + node.attrs.label + "]]" || "",
                pos,
                pos + node.nodeSize
              );
              return false;
            }
          });
          if (isPageRef) {
            state.tr.setSelection(
              TextSelection.create(state.tr.doc, state.selection.anchor + 1)
            );
          }
          return isPageRef;
        }),
    };
  },
  addInputRules() {
    return [
      {
        find: /\[$/,
        handler({ state }) {
          state.tr.insertText("[]", state.selection.from, state.selection.to);
          state.tr.setSelection(
            TextSelection.create(state.tr.doc, state.selection.anchor - 1)
          );
        },
      },
    ];
  },
}).configure({
  suggestion: {
    ...suggestion,
    allowSpaces: true,
    command: ({ editor, range, props }) => {
      // increase range.to by one when the next node is of type "text"
      // and starts with a space character
      const nodeAfter = editor.view.state.selection.$to.nodeAfter;
      const overrideSpace = nodeAfter?.text?.startsWith(" ");

      if (overrideSpace) {
        range.to += 1;
      }
      // below check is added to remove "any characters till ]]" from editor.
      if (nodeAfter?.text) {
        const foundIndex = nodeAfter.text.indexOf("]]");
        range.to += foundIndex + 2;
      }
      editor
        .chain()
        .focus()
        .insertContentAt(range, [
          {
            type: "PageRef",
            attrs: props,
          },
          {
            type: "text",
            text: " ",
          },
        ])
        .run();

      window.getSelection()?.collapseToEnd();
    },
  },
  HTMLAttributes: {
    spellcheck: false,
  },
});
