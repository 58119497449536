import { ListOrder } from "@/types";
import clsx from "clsx";
import { btn } from "../styles/classes";
import { Menu } from "@headlessui/react";
import { BiCheck } from "react-icons/bi";

import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { FilterIcon } from "./Icons";

interface ListOrderProps {
  listOrderOptions: ListOrder[];
  listOrder: ListOrder;
  onChangeListOrder: (listOrder: ListOrder) => void;
}

export const ListOrderMenu = ({
  listOrderOptions,
  listOrder,
  onChangeListOrder,
}: ListOrderProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button>
            <div className="flex justify-between space-x-2 items-center border rounded-md p-1 w-60">
              <div className="flex items-center space-x-2 ">
                <FilterIcon />
                <span>{listOrder}</span>
              </div>
              {open ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
          </Menu.Button>
          <Menu.Items className="z-50 absolute left-0 mt-2 w-52 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="px-1 py-1 ">
              {listOrderOptions.map((listOrderValue) => {
                return (
                  <Menu.Item key={listOrderValue}>
                    {({ active }) => (
                      <button
                        className={clsx(
                          {
                            "hover:bg-gray-100": active,
                            "pl-5": listOrder !== listOrderValue,
                          },
                          "text-gray-900 group flex w-full items-center rounded-md px-1.5 pr-2 py-2 text-sm"
                        )}
                        onClick={() => onChangeListOrder(listOrderValue)}
                      >
                        {listOrder === listOrderValue && <BiCheck />}
                        {listOrderValue}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};
