import { useEffect, useState } from "react";
import { api, CheckAuthQuery } from "../graphql";
import * as Sentry from "@sentry/browser";

export function useAuth() {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<CheckAuthQuery | null>(null);

  const run = async () => {
    try {
      const result = (await api.CheckAuth()).data;
      setData(result);
      // TMP
      localStorage.setItem("workspaceId", result.user.userConfig?.workspaceId);
      Sentry.setUser({ username: result.user.username });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    run();
  }, []);

  return { loading, user: data?.user };
}
