import clsx from "clsx";
import { useLiveQuery } from "dexie-react-hooks";
import {
  MdAdd,
  MdClose,
  MdEdit,
  MdChevronRight,
  MdExpandMore,
  MdDragIndicator,
} from "react-icons/md";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { DataTest } from "../../../tests/e2e/utils/constants";
import { MdOutlinePersonOutline } from "react-icons/md";
import { db } from "../../db";
import { EfNodeType } from "../../graphql";
import { btn, sidebarBtn, sidebarBtnActive } from "../../styles/classes";
import { EfNode } from "../../types";
import { deleteNode, updateNode } from "../../utils";
import { createContactNode } from "../../utils/contact";
import { useSectionDetails } from "../../hooks/useSectionDetails";
import { cloneDeep, isNil } from "lodash";
import { SidePanelAction } from "./SidePanelAction";
import { useDrag } from "react-dnd";
import { useTreeDrop } from "../../hooks/useTreeDrop";
import { useCallback, useEffect, useMemo } from "react";
import { getEmptyImage } from "react-dnd-html5-backend";
import { ImMoveUp } from "react-icons/im";
import { sortSidePanelTree } from "./utils/sidePanelActionUtils";

export function ContactList() {
  const contacts = useLiveQuery(() =>
    db.nodes
      .where("nodeType")
      .equals(EfNodeType.Contact)
      .filter((contact) => !contact.deleted)
      .toArray()
  );

  const {
    isCollapsed,
    sortBy,
    sortOrder,
    manualSortOrder,
    setCollapsed,
    setSortByOrSortOrder,
    setManualSortOrder,
  } = useSectionDetails("contact");

  const sortedContacts = useMemo(
    () => sortSidePanelTree(contacts, sortBy, manualSortOrder, sortOrder),
    [contacts, sortBy, manualSortOrder, sortOrder]
  );

  const updateManualOrder = (sortedPages: EfNode[] = []) => {
    setManualSortOrder(sortedPages.map(({ id }) => id));
  };

  const onChangeSortOrder = (key: "sortBy" | "sortOrder", value: string) => {
    if (key === "sortBy" && value === "manual" && !manualSortOrder?.length) {
      updateManualOrder(sortedContacts);
    }
    setSortByOrSortOrder(key, value);
  };

  return (
    <div>
      <div className="flex items-center justify-between pr-1 pl-3">
        <button
          onClick={setCollapsed}
          className={clsx("grow", {
            ["pointer-events-none"]: !sortedContacts.length,
          })}
        >
          <div className="flex items-center">
            {isCollapsed ? (
              <MdChevronRight
                className={clsx("w-5 h-5", {
                  ["invisible"]: !sortedContacts.length,
                })}
              />
            ) : (
              <MdExpandMore
                className={clsx("w-5 h-5", {
                  ["invisible"]: !sortedContacts.length,
                })}
              />
            )}
            <MdOutlinePersonOutline className="text-xl" />
            <div className="ml-3">Contacts</div>
          </div>
        </button>
        <div className="flex">
          <SidePanelAction
            sortBy={sortBy}
            sortOrder={sortOrder}
            onChange={onChangeSortOrder}
          />
          <button
            className={clsx(btn, "!p-1")}
            onClick={() => {
              const name = prompt("Contact name");
              if (!name) return;
              const email = promptValidEmail("Contact email");
              if (!email) return;
              createContactNode(v4(), name, email);
              isCollapsed && setCollapsed();
            }}
          >
            <MdAdd className="w-5 h-5" />
          </button>
        </div>
      </div>
      {!isCollapsed && (
        <>
          {sortedContacts.map((contact, idx) => (
            <ContactNode
              key={contact.id}
              contact={contact}
              contacts={sortedContacts}
              canDrop={sortBy === "manual"}
              index={idx}
              showDragHandles={sortBy === "manual"}
              updateManualOrder={updateManualOrder}
              isLast={sortedContacts.length - 1 === idx}
            />
          ))}
        </>
      )}
    </div>
  );
}

export function ContactNode({
  contact,
  index,
  isLast,
  canDrop,
  contacts,
  showDragHandles,
  updateManualOrder,
}: {
  contact: EfNode;
  index?: number;
  contacts: EfNode[];
  isLast?: boolean;
  canDrop: boolean;
  showDragHandles?: boolean;
  updateManualOrder?: (nodes: EfNode[]) => void;
}) {
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      item: {
        itemValue: contact,
        index,
      },
      type: EfNodeType.Contact,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [contact]
  );

  const changePosition = (currentIndex: number, hoverIndex?: number) => {
    if (isNil(hoverIndex)) {
      return;
    }
    const clonedCards = [...cloneDeep(contacts)];
    const removedItem = clonedCards.splice(currentIndex, 1)[0];
    clonedCards.splice(hoverIndex, 0, removedItem);
    updateManualOrder?.(clonedCards);
  };
  const onClickMoveToTop = useCallback(() => {
    if (!contact) {
      return;
    }
    const clonedCards = cloneDeep(contacts);
    updateManualOrder?.([
      contact,
      ...clonedCards.filter(({ id }) => contact.id !== id),
    ]);
  }, [contact, updateManualOrder, contacts]);

  const {
    dropBetween,
    dropBetweenLast,
    isOverCurrentBetween,
    isOverCurrentBetweenLast,
  } = useTreeDrop({
    canDrop,
    isDragging,
    node: contact,
    accept: EfNodeType.Contact,
    index,
    changePosition,
  });

  // This adds empty image to the Drag Preview
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <>
      {contact && (
        <div
          ref={dropBetween}
          className={`relative h-[6px] w-full ${
            canDrop && isOverCurrentBetween && "bg-blue-200"
          }`}
        />
      )}
      <NavLink
        key={contact.id}
        to={`/contacts/${contact.id}`}
        className="block"
      >
        {({ isActive }) => (
          <div ref={drag}>
            <ContactElement
              contact={contact}
              isActive={isActive}
              showDragHandles={showDragHandles}
              onClickMoveToTop={onClickMoveToTop}
            />
          </div>
        )}
      </NavLink>
      {isLast && (
        <div
          ref={dropBetweenLast}
          className={`relative h-[6px] ${
            canDrop && isOverCurrentBetweenLast && "bg-blue-200"
          }`}
        />
      )}
    </>
  );
}

export const ContactElement = ({
  contact,
  isActive,
  showDragHandles,
  onClickMoveToTop,
}: {
  contact: EfNode | null;
  isActive: boolean;
  showDragHandles?: boolean;
  onClickMoveToTop?: () => void;
}) => {
  return (
    <div
      className={clsx(
        "px-1 !ml-1 mr-1",
        sidebarBtn,
        isActive && sidebarBtnActive,
        "group items-stretch truncate"
      )}
    >
      <div className="flex items-center">
        <ImMoveUp
          title="move to top"
          onClick={onClickMoveToTop}
          className={clsx(
            "my-1 ml-2 text-lg opacity-0 group-hover:opacity-100",
            {
              ["!opacity-0"]: !showDragHandles,
            }
          )}
        />
        <MdDragIndicator
          className={clsx("my-1 ml-2", { ["opacity-0"]: !showDragHandles })}
        />
      </div>
      <div className="ml-5 flex items-center justify-between min-w-0 w-full">
        <div className="flex-1 truncate">
          {contact?.titleText} {contact?.properties?.contactEmails?.[0]?.email}
        </div>
        <ContactNodeActions contact={contact!} />
      </div>
    </div>
  );
};

export function ContactNodeActions({ contact }: { contact: EfNode }) {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <div className="space-x-2 sm:space-x-1 flex items-center justify-center">
      <button
        className="cursor-pointer lg:hidden group-hover:block"
        onClick={(evt) => {
          evt.preventDefault();
          const name = prompt("Contact name", contact.titleText ?? undefined);
          if (!name) return;
          const email = promptValidEmail(
            "Contact email",
            contact.properties?.contactEmails?.[0]?.email
          );
          if (!email) return;
          updateNode({
            ...contact,
            titleText: name,
            properties: { ...contact.properties, contactEmails: [{ email }] },
          });
        }}
      >
        <MdEdit className="w-4 h-4 sm:w-5 sm:h-5" />
      </button>
      <button
        className="cursor-pointer lg:hidden group-hover:block"
        data-testid={DataTest.DeleteContact}
        onClick={async (evt) => {
          if (params.contactId !== contact.id) {
            evt.preventDefault();
          }
          const confirmed = confirm(`Are you sure?`);
          if (!confirmed) return;
          await deleteNode(contact);
          if (params.contactId === contact.id) {
            navigate("/");
          }
        }}
      >
        <MdClose className="w-4 h-4 sm:w-5 sm:h-5" />
      </button>
    </div>
  );
}

function emailIsValid(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function promptValidEmail(message: string, defaultValue?: string) {
  while (true) {
    const response = prompt(message, defaultValue);
    if (response === null) return;

    if (emailIsValid(response)) {
      return response;
    } else {
      defaultValue = response;
      alert("Invalid email");
    }
  }
}
