import { EfNode } from "@/types";
import { db } from "../db";

/**
 *
 * @param nodeId
 * @returns list of descendents nodeIds
 */
export async function findNodeDescendents(nodeId: string) {
  const result = [nodeId];
  const children = await db.nodes.where("parentId").equals(nodeId).toArray();

  for (const child of children) {
    const descendents = await findNodeDescendents(child.id);
    result.push(...descendents);
  }

  return result;
}

/**
 *
 * @param nodeId
 * @returns list of descendents nodes(whole EfNode object)
 */
export async function findNodeDescendentsNode(nodeId: string) {
  const result: EfNode[] = [];
  const currNode = await db.nodes.where("id").equals(nodeId).first();
  if (!currNode || currNode.deleted) {
    return result;
  }
  result.push(currNode);
  const children = await db.nodes.where("parentId").equals(nodeId).toArray();

  for (const child of children) {
    const descendents = await findNodeDescendentsNode(child.id);
    result.push(...descendents);
  }

  return result;
}
