import * as Sentry from "@sentry/browser";
import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { db } from "../db";
import { useProxyRef } from "../hooks/useProxyRef";
import { btn } from "../styles/classes";
import { EfNodeData, PmNode } from "../types";
import { buildNode, denormalizeNode, persistDiff } from "../utils";
import { diffEditorNodes } from "../utils/diffEditorNodes";
import { Tiptap, TiptapProps } from "./Tiptap";

type PresistedNodeProps = {
  node: EfNodeData;
  disableEdit?: boolean;
  hideToolbar?: boolean;
  hideChildren?: boolean;
  wrapInEmbed?: boolean;
  tiptapProps?: Partial<TiptapProps>;
  pageSize?: number;
};

/**
 *
 * This component get a node and make sure it synced with localdb and render it as an contentEditable element
 */
export function PaginatedPresistedNode({
  node,
  disableEdit,
  hideToolbar,
  hideChildren,
  wrapInEmbed,
  tiptapProps,
  pageSize = 10,
}: PresistedNodeProps) {
  const pendingUpdate = useRef<Update | null>(null);

  const [searchParams] = useSearchParams();
  const [noPagination] = useState(Boolean(searchParams.get("node")));
  const [rootNodesLimit, setRootNodesLimit] = useState(
    noPagination ? Infinity : pageSize
  );
  const rootNodes = useLiveQuery(
    () =>
      db.nodes
        .where({ parentId: node.id })
        .filter((c) => !c.deleted)
        .sortBy("position"),
    [node.id]
  );
  const rootNodesCount = rootNodes?.length;
  const nextRootNode = rootNodes?.[rootNodesLimit];
  const hasMoreRootNodes =
    rootNodesCount !== undefined && rootNodesCount > rootNodesLimit;

  // liveNodeOnLocalDb - will render the component and change each time one of the children (or sub-children) of the node will change on the local db
  // This important to keep inside useLiveQuery so that each time the sync change the local db state it will be reflected to the editor
  const liveNodeOnLocalDb = useLiveQuery(
    () => buildNode(node, rootNodesLimit),
    [node, rootNodesLimit]
  );

  // editorContentProp not represant the most updated state of the editor, it's the last value we passed to the tiptap component to make sure localdb
  // and editor state are sync which is an async operation, for example between user typing and write to local db this state wouldnt be up to date
  // please dont use it as the editor state
  const [editorContentProp, setEditorContentProp] = useState<
    PmNode | undefined
  >(liveNodeOnLocalDb);

  const oldContentRef = useRef<PmNode | undefined>(editorContentProp);
  useEffect(() => {
    oldContentRef.current = editorContentProp;
  }, [editorContentProp]);

  // reset editor content prop when node changes
  useEffect(() => {
    editorContentProp && setEditorContentProp(undefined);
  }, [node.id]);

  const nodeProxy = useProxyRef(node);

  // every time the local db changes, we will update the editor state
  // unless there is a pending update
  // updating during a pending update will cause the cursor to jump
  useEffect(() => {
    // if data is not ready yet, stop
    if (!liveNodeOnLocalDb) return;

    // if there is a pending update, stop
    if (pendingUpdate.current) return;

    // if liveNodeOnLocalDB is not the same as the node we are rendering, stop
    if (liveNodeOnLocalDb.attrs?.id !== nodeProxy.current.id) return;

    setEditorContentProp(liveNodeOnLocalDb);
  }, [liveNodeOnLocalDb]);

  // stores the changes on the local db
  const updateContent = useCallback(async (newContent: PmNode) => {
    const localUpdate = new Update();
    try {
      pendingUpdate.current = localUpdate;

      // sleep for debouncing
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // if this is not the last update, we will stop
      if (pendingUpdate.current !== localUpdate) return;

      /**
       * Old content is the content we passed to the editor before the user started editing
       * This is different from PresistedNode. We do this because of a bug where
       * while pulling new nodes and creating nodes there is a big diff between old and new content
       * causing sync to fail
       */
      const oldContent = oldContentRef.current!;
      // update the old content to the new content so this becomes the next base for the diff
      oldContentRef.current = newContent;

      const oldNodes = denormalizeNode(oldContent, {
        parentId: nodeProxy.current.parentId ?? null,
      });
      const newNodes = denormalizeNode(newContent, {
        parentId: nodeProxy.current.parentId ?? null,
      });
      const diff = diffEditorNodes(oldNodes, newNodes);

      const newRootNodesCount = [...newNodes.values()].filter(
        (n) => n.parentId === nodeProxy.current.id
      ).length;
      setRootNodesLimit(newRootNodesCount);

      await db.transaction("readwrite", db.nodes, () => persistDiff(diff));

      // sleep for testing
      // await new Promise((resolve) => setTimeout(resolve, 1000));

      // if this is not the last update, we will stop
      if (pendingUpdate.current !== localUpdate) return;

      // after the last update, we want to update the editor state
      // in case new nodes were added to the local db during the update
      const freshContent = await buildNode(
        nodeProxy.current,
        newRootNodesCount
      );

      // need to check again because a new update might have started during buildNode
      if (pendingUpdate.current === localUpdate) {
        setEditorContentProp(freshContent);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    } finally {
      if (pendingUpdate.current === localUpdate) {
        pendingUpdate.current = null;
      }
    }
  }, []);

  const contentWithoutChildNodes =
    hideChildren && editorContentProp
      ? removeChildNodes(editorContentProp)
      : editorContentProp;
  // Memo pmNodeWithEmbadNodes to avoid extra rending Tiptap
  const contentWithEmbed = useMemo(
    () =>
      wrapInEmbed && contentWithoutChildNodes
        ? wrapInEmbedNode(contentWithoutChildNodes)
        : contentWithoutChildNodes,
    [wrapInEmbed, contentWithoutChildNodes]
  );

  if (!editorContentProp) return null;

  return (
    <>
      <Tiptap
        content={contentWithEmbed}
        setContent={updateContent}
        disableEdit={disableEdit}
        hideToolbar={hideToolbar}
        rootAfterPosition={nextRootNode?.position}
        {...tiptapProps}
      />
      {hasMoreRootNodes && (
        <button
          onClick={() => setRootNodesLimit((limit) => limit + pageSize)}
          className={btn}
        >
          Load more ({rootNodesLimit}/{rootNodesCount})
        </button>
      )}
    </>
  );
}

// this is a dumb class with no real functionality
// we use it to create a unique instance each time we want to update the local db
// we use it only for reference comparison
class Update {}

function removeChildNodes(content: PmNode) {
  if (content.content?.length === 2) {
    content.content.pop();
  }
  return content;
}

function wrapInEmbedNode(node: PmNode) {
  return {
    type: "EfNodeEmbed",
    content: [node],
  };
}
