import { EfNode } from "@/types";

export const onArrowUp =
  (
    clearSelectedNodes: () => void,
    nodes: EfNode[],
    index: number,
    setCurrentId: (id: string) => void
  ) =>
  (count = 0, handled = false) => {
    clearSelectedNodes();
    // If handled on tiptap just clear nodes
    if (handled) return;

    const previousNode = nodes[index - 1];
    if (!previousNode) return;
    setCurrentId(previousNode.id);

    const previousNodeEditor = window.editors[previousNode.id];
    if (!previousNodeEditor) {
      return;
    }
    if (count === Infinity) {
      previousNodeEditor.commands.focus("end");
      return;
    }
  };

export const onArrowDown =
  (
    clearSelectedNodes: () => void,
    nodes: EfNode[],
    index: number,
    setCurrentId: (id: string) => void
  ) =>
  (count = 0, handled = false) => {
    clearSelectedNodes();
    // If handled on tiptap just clear nodes
    if (handled) return;

    const nextNode = nodes[index + 1];
    if (!nextNode) return;
    setCurrentId(nextNode.id);

    const nextNodeEditor = window.editors[nextNode.id];
    if (!nextNodeEditor) {
      return;
    }
    if (count === -Infinity) {
      nextNodeEditor.commands.focus("start");
      return;
    }
  };

export const onArrowLeft = (clearSelectedNodes: () => void) => () => {
  clearSelectedNodes();
};

export const onArrowRight = (clearSelectedNodes: () => void) => () => {
  clearSelectedNodes();
};
