import { EfNode } from "@/types";
import { ReactNode, createContext, useState } from "react";

const initialValue: FocusMode = {
  firstUnFocusedNode: undefined,
  activated: false,
};

export type FocusMode = {
  firstUnFocusedNode: EfNode | undefined;
  activated: boolean;
};

export const FocusModeContext = createContext({
  focusModeDetails: initialValue,
  setFocusModeDetails: (initialValue: FocusMode) => {},
});

export function FocusModeProvider({ children }: { children: ReactNode }) {
  const [focusModeDetails, setFocusModeDetails] =
    useState<FocusMode>(initialValue);

  return (
    <FocusModeContext.Provider
      value={{ focusModeDetails, setFocusModeDetails }}
    >
      {children}
    </FocusModeContext.Provider>
  );
}
