import clsx from "clsx";
import { GiHamburgerMenu } from "react-icons/gi";
import { btn } from "../../styles/classes";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { isIOS, isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { TopBarAction } from "./TopBarAction";
import { useLiveQuery } from "dexie-react-hooks";
import { getCurrentPageNode } from "./ToolBarUtils";
import { useContext, useEffect, useState } from "react";
import { FocusModeContext } from "@/context/FocusMode";
import { TitleContext } from "@/context/TitleContext";
import { useSearch } from "@/context/SearchContext";
import { useMenu } from "@/context/MenuContext";

type TopBarProps = {
  onClickGlobalSearch: () => void;
};

export const TopBar = ({ onClickGlobalSearch }: TopBarProps) => {
  const { setIsMenuOpen } = useMenu();
  const navigate = useNavigate();
  const params = useParams();
  const [indexDiff, setIndexDiff] = useState(
    window.history.length - window.history.state?.idx
  );

  const { showInTopBar } = useContext(TitleContext);
  const { inSearch } = useSearch();

  const page = useLiveQuery(() => getCurrentPageNode(params), [params]);
  const { focusModeDetails } = useContext(FocusModeContext);

  useEffect(() => {
    setIndexDiff(window.history.length - window.history.state?.idx);
  }, [window.history.length]);

  return (
    <div className={clsx("flex items-center justify-between  mr-4 ml-4 mt-4")}>
      {!inSearch && (
        <>
          <div className="flex items-center justify-start ">
            <button
              type="button"
              className={clsx("lg:hidden", btn, "!p-1 bg-transparent")}
              onClick={() => setIsMenuOpen(true)}
            >
              <GiHamburgerMenu />
            </button>
            <button
              className={clsx(btn, "!p-1 bg-transparent", {
                ["hover:bg-white cursor-default"]:
                  window.history?.state?.idx === 0,
              })}
              disabled={window.history.state?.idx === 0}
              onClick={() => {
                navigate(-1);
              }}
            >
              <BiLeftArrowAlt
                className={clsx("text-2xl", {
                  ["text-slate-400"]: window.history?.state?.idx === 0,
                })}
              />
            </button>
            <button
              className={clsx(btn, "!p-1 bg-transparent", {
                ["hover:bg-white cursor-default"]:
                  window.history?.state?.idx === 0,
              })}
              disabled={
                window.history.state?.idx + indexDiff >= window.history.length
              }
              onClick={() => navigate(1)}
            >
              <BiRightArrowAlt className="text-2xl" />
            </button>
            {(focusModeDetails?.activated || showInTopBar) && (
              <div className="text-[14px] font-semibold leading-[16.8px] ml-1">
                {page?.titleText}
              </div>
            )}
          </div>
          <div className="text-slate-500 font-semibold flex space-x-2.5">
            {isMobile && (
              <button onClick={onClickGlobalSearch}>
                {isIOS ? "⌘" : <span>Ctrl + </span>}K
              </button>
            )}
            <TopBarAction />
          </div>
        </>
      )}
    </div>
  );
};
