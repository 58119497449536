export const UpdateAppPrompt = () => {
  return (
    <div className="flex justify-center items-center h-screen p-">
      <div className="bg-gray-50 p-4 rounded-lg max-w-xl space-y-4 shadow border">
        <div className="font-bold">Please update your app</div>
        <div>
          You are using an old version of the app. Please update to the latest
          version.
        </div>
      </div>
    </div>
  );
};
