import { v4 } from "uuid";
import { EfNodeType } from "../graphql";
import { createNode } from "./sync";

export async function createPageNode(
  id: string = v4(),
  titleText: string,
  parentId: string | null = null
) {
  await createNode({
    id,
    nodeType: EfNodeType.Page,
    contentText: "",
    titleText: titleText || "Untitled",
    parentId,
    position: null,
    tagIds: [],
    referencedPageIds: [],
    properties: {
      collapsed: false,
    },
  });
}
