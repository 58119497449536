import { ReactNode, createContext, useState } from "react";

export const TitleContext = createContext({
  showInTopBar: true,
  setShowInTopBar: (initialValue: boolean) => {},
});

export function TitleProvider({ children }: { children: ReactNode }) {
  const [showInTopBar, setShowInTopBar] = useState<boolean>(false);

  return (
    <TitleContext.Provider value={{ showInTopBar, setShowInTopBar }}>
      {children}
    </TitleContext.Provider>
  );
}
