import { Button } from "../ui/button";
import { SearchFilterIcon } from "../Icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown";
import { OperatorDropdown } from "./OperatorDrowdown";
import { useSearch } from "@/context/SearchContext";
import { AvailableFilter } from "./AvailableFilter";
import { useMemo, useRef } from "react";
import { Fields, UIFields } from "./fields";
import { FilterRow } from "./FilterRow";
import { BaseOperatorDropdown } from "./UtilityComponents";

/**
 * SearchFilers are similar to the filters in linear. You can add filters, modify the operator of the each filter
 * and also of the entire group of filters. Currently all filters are part of a single group but the current
 * architecture allows for nested groups as well. We use react-querybuilder for the grouping logic and have created
 * custom components for the operators and the fields.
 */
export const SearchFilters = () => {
  const recentlyAddedRef = useRef<string | undefined>(undefined);
  const { filters, setFilters, addFilter, clear } = useSearch();

  const availableFilters = useMemo(() => {
    // Filter out the fields that are already in the group
    // and only show the fields that are UIFields
    return Object.values(Fields).filter(
      (field) =>
        !filters.rules.find((rule) => rule.id === field.name) &&
        UIFields.includes(field.name)
    );
  }, [filters?.rules]);

  return (
    <div className="flex flex-row gap-2 items-start justify-between">
      <div className="flex flex-row gap-2 items-center justify-start">
        <DropdownMenu>
          <Button
            variant="outline"
            size="icon"
            className="flex items-center justify-center h-[20px] w-[20px] rounded-sm bg-slate-50"
          >
            <DropdownMenuTrigger>
              <SearchFilterIcon />
            </DropdownMenuTrigger>
          </Button>
          <DropdownMenuContent side="bottom" align="start">
            <DropdownMenuItem className="flex flex-row gap-2">
              <BaseOperatorDropdown />
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            {availableFilters.map((field) => (
              <AvailableFilter
                field={field}
                addFilter={(filter) => {
                  recentlyAddedRef.current = filter.id;
                  addFilter(filter);
                }}
                key={field.name}
              />
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        <FilterRow
          group={filters}
          recentlyAddedFilter={recentlyAddedRef.current}
        />
      </div>
      {
        <div className="flex flex-row gap-1">
          {filters.rules.length > 1 && (
            <OperatorDropdown
              className="w-[120px] text-gray-500"
              operator={filters.combinator === "or" ? "Match any" : "Match all"}
              operators={[
                {
                  label: "Match all",
                  value: "Match all",
                },
                {
                  label: "Match any",
                  value: "Match any",
                },
              ]}
              setOperator={(operator) => {
                setFilters({
                  ...filters,
                  combinator: operator === "Match any" ? "or" : "and",
                });
              }}
            />
          )}
          {filters.rules.length > 0 && (
            <Button
              variant="link"
              className="flex flex-row gap-1 h-[28px] text-gray-500"
              onClick={() => clear()}
            >
              Clear
            </Button>
          )}
        </div>
      }
    </div>
  );
};
