import { Editor } from "@tiptap/react";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { findParentNodeOfType } from "prosemirror-utils";
import { isMacOs } from "react-device-detect";
import { ListComponent } from "./ContentMenuWrapper/List";

export function ContextMenu({
  editor,
  closeContextMenu,
  style,
}: {
  editor: Editor;
  closeContextMenu: () => void;
  style: CSSProperties;
}) {
  const buttons = [
    {
      key: "task",
      label: "Task",
      command: () => editor.chain().focus().toggleTask().run(),
    },
    {
      key: "paragraph",
      label: "Paragraph",
      command: () => editor.chain().focus().setNode("paragraph").run(),
    },
    {
      key: "heading1",
      label: "Heading 1",
      command: () =>
        editor.chain().focus().setNode("heading", { level: 1 }).run(),
    },
    {
      key: "heading2",
      label: "Heading 2",
      command: () =>
        editor.chain().focus().setNode("heading", { level: 2 }).run(),
    },
    {
      key: "heading3",
      label: "Heading 3",
      command: () =>
        editor.chain().focus().setNode("heading", { level: 3 }).run(),
    },
    {
      key: "bulletList",
      label: "Bullet List",
      command: () => editor.chain().focus().toggleBulletList().run(),
    },
    {
      key: "orderedList",
      label: "Ordered List",
      command: () => editor.chain().focus().toggleOrderedList().run(),
    },
    {
      key: "codeBlock",
      label: "Code block",
      command: () => editor.chain().focus().toggleCodeBlock().run(),
    },
    {
      key: "blockquote",
      label: "Blockquote",
      command: () => editor.chain().focus().toggleBlockquote().run(),
    },
    {
      key: "file",
      label: "File",
      command: () => editor.chain().focus().run(),
    },
  ];

  if (isMacOs) {
    buttons.push({
      key: "TextView",
      label: "TextView",
      command: () => {
        const { node } =
          findParentNodeOfType(editor.state.schema.nodes.EfNode)(
            editor.state.selection
          ) || {};
        if (node) {
          window.open(
            `execfnimgprocess://image?id=${node.attrs.id}&type=efNode`
          );
        }
        return true;
      },
    });
  }

  const [selectedIndex, setSelectedIndex] = useState(0);
  const selectedButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    selectedButtonRef.current?.scrollIntoView?.({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }, [selectedIndex]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      event.preventDefault();
      if (event.key === "Escape") {
        closeContextMenu();
      } else if (event.key === "ArrowUp") {
        upHandler();
      } else if (event.key === "ArrowDown") {
        downHandler();
      } else if (event.key === "Enter") {
        enterHandler();
      }
    };

    const handleClick = ({ target }: MouseEvent) => {
      const suggestionListDiv = document.getElementById("items");
      if (target != suggestionListDiv) {
        closeContextMenu();
      }
    };

    // this is declared for the keydown methods to work
    document.addEventListener("keydown", handleKeyDown);

    // This is declared for closing the context menu when the user clicks on the editor
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClick);
    };
  });

  const selectItem = (index: number) => {
    const item = buttons[index];
    if (item) {
      item.command();
      closeContextMenu();
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + buttons.length - 1) % buttons.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % buttons.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  return (
    <ListComponent
      classes={{
        container:
          "z-10 absolute flex flex-col items-start bg-white shadow-2xl w-80 max-h-72 overflow-auto rounded-md p-1 cursor-pointer text-gray-700",
      }}
      containerStyle={style}
      items={buttons.map(({ label, key }, index) => ({
        key,
        label,
        ref: index === selectedIndex ? selectedButtonRef : null,
        className: `w-full rounded-md px-3 py-2 break-words hover:bg-slate-200 justify-start ${
          index === selectedIndex ? "bg-slate-200" : ""
        }`,
        onClick: () => selectItem(index),
      }))}
    />
  );
}
