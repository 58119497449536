import { Editor } from "@tiptap/react";
import { FilesWithNodeIDs, addFilesToUploadQueue, selectFiles, validateFiles } from "../utils/fileUtils";
import { v4 } from "uuid";

export const uploadFileCommand = async (editor: Editor) => {
    const files = await selectFiles();
    if (!files) return;

    const filesWithNodeID: FilesWithNodeIDs = [];
    for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (!file) return;
        const fileNodeID = v4();
        filesWithNodeID.push([fileNodeID, file]);
    }

    // Validate size
    if (!validateFiles(filesWithNodeID)) return alert("File too large");
    // Add file top upload queue
    addFilesToUploadQueue(filesWithNodeID);

    // Create file node
    filesWithNodeID.forEach(async ([nodeID]) => {
        editor.commands.addFile(nodeID);
    });
}