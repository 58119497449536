import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { MouseEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../db";
import { useLiveQuery } from "dexie-react-hooks";
import { PresistedNode } from "./PresistedNode";
import { EfNode } from "../types";
import { debounce } from "lodash";
import { Tooltip, TooltipContent, TooltipTrigger } from "./Tooltip";
import { BiFile } from "react-icons/bi";

export const PageReferenceNodeView = ({
  node,
  updateAttributes,
}: NodeViewProps) => {
  const [hoverPageOpen, setHoverPageOpen] = useState(false);

  const navigate = useNavigate();
  const {
    attrs: { id, label },
  } = node;
  const page = useLiveQuery(() => db.nodes.get(id));

  if (page && page.titleText !== label) {
    updateAttributes({ label: page?.titleText });
  }

  const onClickPageReference = useCallback(
    (evt: MouseEvent<HTMLAnchorElement>) => {
      evt.preventDefault();
      navigate("/pages/" + id);
    },
    [node]
  );

  const onHover = useCallback(
    debounce((show: boolean) => {
      setHoverPageOpen(show);
    }, 300),
    [setHoverPageOpen]
  );

  return (
    <NodeViewWrapper as="span" className="inline-block mx-0.5">
      <Tooltip
        open={hoverPageOpen}
        onOpenChange={setHoverPageOpen}
        placement="bottom"
      >
        <TooltipTrigger className="inline-block">
          <span
            onClick={onClickPageReference}
            onMouseEnter={() => onHover(true)}
            onMouseLeave={() => onHover(false)}
            className="flex items-baseline cursor-pointer hover:bg-blue-50 transition-all relative"
          >
            <span className="text-slate-600 ">
              <BiFile />
            </span>
            <span className="text-blue-400 no-underline">
              {page?.titleText}
            </span>
          </span>
        </TooltipTrigger>
        <TooltipContent>
          <HoverPageView page={page} onHover={onHover} />
        </TooltipContent>
      </Tooltip>
    </NodeViewWrapper>
  );
};

interface HoverPageProps {
  page?: EfNode;
  onHover: (show: boolean) => void;
}

export const HoverPageView = ({ page, onHover }: HoverPageProps) => {
  if (!page) {
    return null;
  }
  return (
    <div
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      className="bg-white shadow-2xl p-4 rounded-lg z-10 overflow-y-scroll w-80 md:w-[500px] max-h-[400px] hover"
    >
      <PresistedNode
        key={page.id}
        node={page}
        tiptapProps={{ autofocus: true }}
        disableEdit
      />
    </div>
  );
};
