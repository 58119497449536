import { elementFromString, Extensions, getSchema } from "@tiptap/react";
import { DOMParser } from "prosemirror-model";

export function generateJSON(
  html: string,
  extensions: Extensions
): Record<string, any> {
  const schema = getSchema(extensions);
  const dom = elementFromString(html);
  return DOMParser.fromSchema(schema)
    .parse(dom, { preserveWhitespace: "full" })
    .toJSON();
}
