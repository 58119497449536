import { SlNotebook } from "react-icons/sl";

interface LoaderProps {
  totalNodes: number;
  nodesLeftToPull: number;
}
export function Loader({ totalNodes, nodesLeftToPull }: LoaderProps) {
  const progress = (100 * (totalNodes - nodesLeftToPull)) / totalNodes;
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="flex items-center p-3 border-b mb-4">
        <SlNotebook className="w-7 h-7 mr-2" />
        <h1 className="text-lg font-semibold">Executive Function</h1>
      </div>
      <div>
        <h1 className="text-md p-3 mb-4">
          Please wait while we are syncing your client
        </h1>
        <ProgressBar progress={isNaN(progress) ? 0 : progress} />
        {!!totalNodes && (
          <span className="text-xs text-gray-600 mb-4">
            Pending nodes: {nodesLeftToPull}
          </span>
        )}
      </div>
    </div>
  );
}

function ProgressBar({ progress }: { progress: number }) {
  const roundedProgress = Math.round(progress);
  return (
    <div className="w-full bg-gray-200 rounded-full h-1.5">
      <div
        className="transition-all duration-500 bg-green-500 h-1.5 rounded-full"
        style={{ width: `${roundedProgress}%` }}
      />
    </div>
  );
}
