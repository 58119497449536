import * as Sentry from "@sentry/browser";
import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { db } from "../db";
import { useProxyRef } from "../hooks/useProxyRef";
import { EfNodeData, PmNode } from "../types";
import { buildNode, denormalizeNode, persistDiff } from "../utils";
import { diffEditorNodes } from "../utils/diffEditorNodes";
import { Tiptap, TiptapProps } from "./Tiptap";
import { useSearchParams } from "react-router-dom";

type PresistedNodeProps = {
  node: EfNodeData;
  disableEdit?: boolean;
  hideToolbar?: boolean;
  hideChildren?: boolean;
  wrapInEmbed?: boolean;
  tiptapProps?: Partial<TiptapProps>;
};

/**
 *
 * This component get a node and make sure it synced with localdb and render it as an contentEditable element
 */
export function PresistedNode({
  node,
  disableEdit,
  hideToolbar,
  hideChildren,
  wrapInEmbed,
  tiptapProps,
}: PresistedNodeProps) {
  const pendingUpdate = useRef<Update | null>(null);
  const [searchParams] = useSearchParams();
  const nodeParam = searchParams.get("node");
  const hasSelectedNode = !!nodeParam;

  // liveNodeOnLocalDb - will render the component and change each time one of the children (or sub-children) of the node will change on the local db
  // This important to keep inside useLiveQuery so that each time the sync change the local db state it will be reflected to the editor
  const liveNodeOnLocalDb = useLiveQuery(
    () => buildNode(node, undefined),
    [node]
  );
  // editorContentProp not represant the most updated state of the editor, it's the last value we passed to the tiptap component to make sure localdb
  // and editor state are sync which is an async operation, for example between user typing and write to local db this state wouldnt be up to date
  // please dont use it as the editor state
  const [editorContentProp, setEditorContentProp] = useState<
    PmNode | undefined
  >(liveNodeOnLocalDb);

  // reset editor content prop when node changes
  useEffect(() => {
    editorContentProp && setEditorContentProp(undefined);
  }, [node.id]);

  const nodeProxy = useProxyRef(node);

  // every time the local db changes, we will update the editor state
  // unless there is a pending update
  // updating during a pending update will cause the cursor to jump
  useEffect(() => {
    // if data is not ready yet, stop
    if (!liveNodeOnLocalDb) return;

    // if there is a pending update, stop
    if (pendingUpdate.current) return;

    // if liveNodeOnLocalDB is not the same as the node we are rendering, stop
    if (liveNodeOnLocalDb.attrs?.id !== nodeProxy.current.id) return;

    setEditorContentProp(liveNodeOnLocalDb);
  }, [liveNodeOnLocalDb]);

  // stores the changes on the local db
  const updateContent = useCallback(async (newContent: PmNode) => {
    const localUpdate = new Update();
    pendingUpdate.current = localUpdate;

    // sleep for debouncing
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // if this is not the last update, we will stop
    if (pendingUpdate.current !== localUpdate) return;

    // run everything inside a transaction to avoid inconsistency
    try {
      await db.transaction("readwrite", db.nodes, async () => {
        // oldContent is the latest content from the local db
        const oldContent = await buildNode(nodeProxy.current);
        const oldNodes = denormalizeNode(oldContent, {
          parentId: nodeProxy.current.parentId ?? null,
        });
        const newNodes = denormalizeNode(newContent, {
          parentId: nodeProxy.current.parentId ?? null,
        });
        const diff = diffEditorNodes(oldNodes, newNodes);
        await persistDiff(diff);
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      return;
    }

    // if this is the last update, we will reset the pending update
    if (pendingUpdate.current === localUpdate) {
      pendingUpdate.current = null;
    }
  }, []);

  const contentWithoutChildNodes =
    hideChildren && editorContentProp
      ? removeChildNodes(editorContentProp)
      : editorContentProp;
  // Memo pmNodeWithEmbadNodes to avoid extra rending Tiptap
  const contentWithEmbed = useMemo(
    () =>
      wrapInEmbed && contentWithoutChildNodes
        ? wrapInEmbedNode(contentWithoutChildNodes)
        : contentWithoutChildNodes,
    [wrapInEmbed, contentWithoutChildNodes]
  );

  if (!editorContentProp) return null;

  return (
    <Tiptap
      content={contentWithEmbed}
      setContent={updateContent}
      disableEdit={disableEdit}
      hideToolbar={hideToolbar}
      {...tiptapProps}
      onCreate={async (editor) => {
        tiptapProps?.onCreate?.(editor);
        const selection = await db.selections.get(node.id);
        if (hasSelectedNode) return; // Don't scroll intoview/selection if there is a selected node

        if (selection) {
          editor.commands.setTextSelection(selection);
          editor.commands.scrollIntoView();
        }
      }}
      onSelectionUpdate={async (editor) => {
        const { selection } = editor.state;
        await db.selections.put({
          nodeId: node.id,
          from: selection.from,
          to: selection.to,
        });
      }}
    />
  );
}

// this is a dumb class with no real functionality
// we use it to create a unique instance each time we want to update the local db
// we use it only for reference comparison
class Update {}

function removeChildNodes(content: PmNode) {
  if (content.content?.length === 2) {
    content.content.pop();
  }
  return content;
}

function wrapInEmbedNode(node: PmNode) {
  return {
    type: "EfNodeEmbed",
    content: [node],
  };
}
