import { merge, mergeWith } from "lodash";
import { db } from "../db";
import { PageProperties } from "@/types";

export const setLandingPageInSettings = async (url: string) => {
  const userPreferenceSettings = await db.settings
    .where({ id: "userPreference" })
    .first();
  await db.settings.put(
    merge(userPreferenceSettings, {
      id: "userPreference",
      landingPage: url,
    }),
    "userPreference"
  );
};

export const getLandingPageInSettings = async () => {
  const userPreferenceSettings = await db.settings
    .where({ id: "userPreference" })
    .first();
  if (userPreferenceSettings?.id === "userPreference") {
    return userPreferenceSettings.landingPage;
  }
  return null;
};

export const setLastVisitedInSettings = async (
  pageId: string,
  nodeIds: string[]
) => {
  const userPreferenceSettings = await db.settings
    .where({ id: "userPreference" })
    .first();
  await db.settings.put(
    mergeWith(
      userPreferenceSettings,
      {
        id: "userPreference",
        lastVisitedNode: {
          [pageId]: nodeIds,
        },
      },
      (_, currVal) => {
        if (currVal instanceof Array) {
          return currVal;
        }
        return undefined;
      }
    ),
    "userPreference"
  );
};

export const getLastVisitedInSettings = async (pageId?: string) => {
  const userPreferenceSettings = await db.settings
    .where({ id: "userPreference" })
    .first();
  if (userPreferenceSettings?.id === "userPreference" && pageId) {
    return userPreferenceSettings.lastVisitedNode?.[pageId];
  }
  return null;
};

export async function getPagePropertiesInSettings() {
  const userPreferenceSettings = await db.settings
    .where({ id: "userPreference" })
    .first();
  if (userPreferenceSettings?.id === "userPreference") {
    return userPreferenceSettings.pageProperties;
  }
  return null;
}

export async function setPagePropertiesInSettings(
  value: Record<string, Partial<PageProperties>>
) {
  const userPreferenceSettings = await db.settings
    .where({ id: "userPreference" })
    .first();
  await db.settings.put(
    merge(userPreferenceSettings, {
      pageProperties: value,
      id: "userPreference",
    }),
    "userPreference"
  );
}
