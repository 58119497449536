import { useAreaSelectionContext } from "@/components/SelectionWrapper/SelectionWrapper";
import { useProxyRef } from "@/hooks/useProxyRef";
import { btn, btnActive } from "@/styles/classes";
import { useFloating, autoUpdate, offset, flip } from "@floating-ui/react";
import { Editor, isNodeSelection, posToDOMRect } from "@tiptap/core";
import clsx from "clsx";
import { debounce } from "lodash";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

type ControlledBubbleMenuProps = {
  editor: Editor;
  className?: string;
};

export const ControlledBubbleMenu = ({
  editor,
  className,
}: ControlledBubbleMenuProps) => {
  const menuRef = useRef<HTMLDivElement | null>();
  const areaSelection = useAreaSelectionContext();
  // ref for current area selection state since it is used in an event listener that is subscribed once
  const areaSelectionProxyRef = useProxyRef(areaSelection);
  const [show, setShow] = useState<boolean>(false);
  const {
    x,
    y,
    strategy,
    refs: { setFloating, setReference, reference },
  } = useFloating({
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
    placement: "top",
    middleware: [
      offset({ mainAxis: 8 }),
      flip({
        padding: 8,
        boundary: editor.options.element,
        fallbackPlacements: [
          "bottom",
          "top-start",
          "bottom-start",
          "top-end",
          "bottom-end",
        ],
      }),
    ],
  });

  useEffect(() => {
    const onSelectionUpdateDebounced = debounce(
      ({ editor: newEditor }: { editor: Editor }) => {
        // Do not show menu when area selection is active
        const { isAreaSelectionActive } = areaSelectionProxyRef.current;
        if (isAreaSelectionActive()) return setShow(false);
        const {
          state: { selection },
        } = newEditor;
        // Don't show menu when selection is empty or selection is inside code block
        if (
          selection.to === selection.from ||
          selection.$from.parent.type.name ===
            newEditor.schema.nodes.codeBlock.name
        ) {
          setShow(false);
          return;
        }
        setShow(true);
      },
      200
    );
    const onBlur = () => {
      setTimeout(() => {
        // added timeout to run this on next tick because active element was coming out to be body.
        setShow(!!menuRef.current?.contains(document.activeElement));
      });
    };
    editor.on("selectionUpdate", onSelectionUpdateDebounced);
    // using dom node to add event listener because editor.on was not working
    editor.view.dom.addEventListener("blur", onBlur);
    return () => {
      editor.off("selectionUpdate", onSelectionUpdateDebounced);
      editor.view.dom.removeEventListener("blur", onBlur);
    };
  }, []);

  useLayoutEffect(() => {
    setReference({
      getBoundingClientRect() {
        const { ranges } = editor.state.selection;
        const from = Math.min(...ranges.map((range) => range.$from.pos));
        const to = Math.max(...ranges.map((range) => range.$to.pos));
        if (isNodeSelection(editor.state.selection)) {
          let node = editor.view.nodeDOM(from) as HTMLElement;
          const nodeViewWrapper = node.dataset.nodeViewWrapper
            ? node
            : node.querySelector("[data-node-view-wrapper]");

          if (nodeViewWrapper) {
            node = nodeViewWrapper.firstChild as HTMLElement;
          }
          if (node) {
            return node.getBoundingClientRect();
          }
        }
        return posToDOMRect(editor.view, from, to);
      },
    });
  }, [reference, editor.state.selection]);

  const setRef = useCallback((refNode: HTMLDivElement) => {
    setFloating(refNode);
    menuRef.current = refNode;
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div
      ref={setRef}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      }}
      className={className ?? ""}
    >
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={clsx(btn, editor.isActive("bold") ? btnActive : "")}
      >
        B
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={clsx(
          btn,
          "italic",
          editor.isActive("italic") ? btnActive : ""
        )}
      >
        I
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={clsx(
          btn,
          "line-through",
          editor.isActive("strike") ? btnActive : ""
        )}
      >
        S
      </button>
      <button
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
        className={clsx(
          btn,
          "line-through",
          editor.isActive("underline") ? btnActive : ""
        )}
      >
        U
      </button>
    </div>
  );
};
