import { Editor, EditorContent } from "@tiptap/react";
import clsx from "clsx";
import { isMobile } from "react-device-detect";
import { ControlledBubbleMenu } from "./VirtualizedEditor/Menu/ControlledBubbleMenu";

type NewTiptapProps = {
  editor: Editor;
  highlighted?: boolean;
};

export const NewTiptap = ({ editor, highlighted }: NewTiptapProps) => {
  return (
    <>
      <EditorContent
        editor={editor}
        className={clsx("prose prose-sm max-w-none leading-[120%] w-full", {
          "animate-highlight": highlighted,
        })}
      />
      {!isMobile && (
        <ControlledBubbleMenu
          editor={editor}
          className="bg-white p-2 border rounded flex gap-2 select-none z-10"
        />
      )}
    </>
  );
};
