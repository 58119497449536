import { Node as BaseNode, ReactNodeViewRenderer } from "@tiptap/react";
import { TextSelection } from "prosemirror-state";
import {
  findChildrenByType,
  findParentNodeOfType,
  findParentNodeOfTypeClosestToPos,
} from "prosemirror-utils";
import { ExtensionsOptions } from ".";
import { NodeRenderer } from "../components/NodeRenderer";
import { EfNodeType } from "../graphql";
import { insertFileNode } from "../utils/fileUtils";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    EfNode: {
      liftNode: () => ReturnType;
      sinkNode: () => ReturnType;
      splitNode: () => ReturnType;
      createNode: () => ReturnType;
      createNodeBefore: () => ReturnType;
      toggleTask: () => ReturnType;
      addFile: (fileNodeID: string) => ReturnType;
      removeNodePosition: () => ReturnType;
      moveCursorToTop: () => ReturnType;
    };
  }
}

type NodeOptions = Pick<ExtensionsOptions, "submitHandler">;
export const Node = BaseNode.create<NodeOptions>({
  name: "EfNode",
  content: "block+ EfNodeList?",

  addAttributes() {
    return {
      id: { default: null, keepOnSplit: false },
      position: { default: null, keepOnSplit: false },
      subType: { default: EfNodeType.Block, keepOnSplit: false },
      taskStatus: { default: "PENDING", keepOnSplit: false },
      collapsed: { default: false, keepOnSplit: false },
      fileContentType: { default: undefined, keepOnSplit: false },
      fileName: { default: undefined, keepOnSplit: false },
      fileUrl: { default: undefined, keepOnSplit: false },
    };
  },

  parseHTML() {
    return [{ tag: `div[data-type="${this.name}"]` }];
  },

  renderHTML() {
    return ["div", { "data-type": this.name }, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(NodeRenderer);
  },

  addCommands() {
    return {
      liftNode:
        () =>
        ({ commands, state }) => {
          let { $from, $to } = state.selection;
          const isNodeEmbed =
            $from.node(0).type === state.schema.nodes.EfNodeEmbed;
          const nodeHasSingleIndextation =
            ($from.blockRange($to)?.depth || 0) <= 3;
          // Below check disable outdent command when inside EfNodeEmbed and when current node has a single indent.
          if (isNodeEmbed && nodeHasSingleIndextation) return false;
          commands.removeNodePosition();
          return commands.liftListItem(state.schema.nodes.EfNode);
        },
      sinkNode:
        () =>
        ({ commands, state }) => {
          commands.removeNodePosition();
          return commands.sinkListItem(state.schema.nodes.EfNode);
        },
      splitNode:
        () =>
        ({ commands, state }) => {
          return commands.splitListItem(state.schema.nodes.EfNode);
        },
      createNode:
        () =>
        ({ tr, state, dispatch }) => {
          // get node from selection
          const node = state.selection.$from.node(state.selection.$from.depth);

          const parentListItem = findParentNodeOfType(
            state.schema.nodes.listItem
          )(state.selection);
          if (parentListItem) return false;
          const parent = findParentNodeOfType(state.schema.nodes.EfNode)(
            state.selection
          );

          if (!parent || parent.node.attrs.subType === EfNodeType.File)
            return false;
          if (dispatch) {
            // TODO: need to add here documnation what we are trying to do here
            const newPos = parent.pos + parent.node.nodeSize;
            const node = state.schema.nodes.EfNode.createAndFill({
              subType: parent.node.attrs.subType,
            });
            if (!node) return false;
            tr.insert(newPos, node);
            tr.setSelection(TextSelection.near(tr.doc.resolve(newPos)));
            dispatch(tr.scrollIntoView());
          }

          return true;
        },
      createNodeBefore:
        () =>
        ({ tr, state, dispatch }) => {
          // selection should be empty
          if (!state.selection.empty) return false;

          const parent = findParentNodeOfType(state.schema.nodes.EfNode)(
            state.selection
          );

          // cursor should be inside a node
          if (!parent) return false;

          const cursorAtNodeStart =
            parent.pos + 2 === state.selection.$from.pos;

          // cursor should be at the start of the node
          if (!cursorAtNodeStart) return false;

          if (dispatch) {
            // create an empty node (block)
            const node = state.schema.nodes.EfNode.createAndFill({
              subType: EfNodeType.Block,
            });

            // make sure node is created and valid
            if (!node) return false;

            // insert node before the current node
            tr.insert(parent.pos, node);
            dispatch(tr.scrollIntoView());
          }

          return true;
        },
      toggleTask:
        () =>
        ({ tr, state, dispatch }) => {
          const parent = findParentNodeOfType(state.schema.nodes.EfNode)(
            state.selection
          );
          if (!parent) return false;

          // node must be a block or task
          if (
            parent.node.attrs.subType !== EfNodeType.Block &&
            parent.node.attrs.subType !== EfNodeType.Task
          )
            return false;

          if (dispatch) {
            tr.setNodeAttribute(
              parent.pos,
              "subType",
              parent.node.attrs.subType === EfNodeType.Block
                ? EfNodeType.Task
                : EfNodeType.Block
            );
            dispatch(tr.scrollIntoView());
          }
          return true;
        },
      addFile:
        (fileNodeID) =>
        ({ tr, state, dispatch }) => {
          if (!dispatch) return false;

          insertFileNode(tr, state, fileNodeID);
          dispatch(tr);

          return true;
        },
      removeNodePosition:
        () =>
        ({ tr, state, dispatch }) => {
          if (!dispatch) return false;

          const node = findParentNodeOfType(state.schema.nodes.EfNode)(
            state.selection
          );

          if (!node) return false;

          tr.setNodeMarkup(node.pos, undefined, {
            ...node.node.attrs,
            position: null,
          });

          dispatch(tr.scrollIntoView());

          return true;
        },
      moveCursorToTop:
        () =>
        ({ tr, state, dispatch }) => {
          if (!dispatch) return false;

          if (
            state.doc.type !== state.schema.nodes.EfPage &&
            state.doc.type !== state.schema.nodes.EfThoughtPad
          ) {
            // doc must be a page
            return false;
          }

          const nodeList = findChildrenByType(
            state.doc,
            state.schema.nodes.EfNodeList,
            false
          )?.[0];
          if (!nodeList) return false;

          const firstNode = findChildrenByType(
            nodeList.node,
            state.schema.nodes.EfNode,
            false
          )?.[0];
          if (!firstNode) return false;

          const firstNodeIsEmpty = firstNode.node.textContent.trim() === "";

          if (firstNodeIsEmpty) {
            // focus on first node
            dispatch(
              tr
                .setSelection(
                  TextSelection.near(
                    state.doc.resolve(nodeList.pos + firstNode.pos)
                  )
                )
                .scrollIntoView()
            );
          } else {
            // create a new node on top
            const node = state.schema.nodes.EfNode.createAndFill({
              subType: EfNodeType.Block,
            });
            if (!node) return false;
            tr.insert(nodeList.pos + 1, node);
            tr.setSelection(
              TextSelection.near(tr.doc.resolve(nodeList.pos + 1))
            );
            dispatch(tr.scrollIntoView());
          }

          return false;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        if (editor.storage.mode !== "outline") return false;
        const state = editor.state;
        const codeBlockParent = findParentNodeOfType(
          state.schema.nodes.codeBlock
        )(state.selection);
        if (codeBlockParent) return false;
        const { splitNode, createNodeBefore } = editor.commands;
        return createNodeBefore() || splitNode();
      },
      "Shift-Enter": ({ editor }) => {
        if (editor.storage.mode !== "document") return false;
        const { splitNode, createNodeBefore } = editor.commands;
        return createNodeBefore() || splitNode();
      },
      "Mod-Enter": ({ editor }) => {
        if (!this.options.submitHandler) return false;
        this.options.submitHandler(editor);
        return true;
      },
      Tab: () => this.editor.commands.sinkNode(),
      "Shift-Tab": () => this.editor.commands.liftNode(),
      Backspace: ({ editor }) => {
        const state = editor.state;
        const parent = findParentNodeOfType(state.schema.nodes.EfNode)(
          state.selection
        );

        // not inside a node
        if (!parent) return false;

        // check if node is task
        if (parent.node.attrs.subType === EfNodeType.Task) {
          // check if cursor is at the beginning of the node
          if (state.selection.$from.pos !== parent.pos + 2) return false;

          // change node to block
          return editor.commands.toggleTask();
        }

        // Check if cursor is at the start of the node and perform a lift
        const selectionAtNodeStart = state.selection.$anchor.parentOffset === 0;
        // Check if node is the most indent / last on a list
        const isNodeLeaf = !findChildrenByType(
          parent.node,
          state.schema.nodes.EfNodeList
        ).length;
        // Ignore backspace when trying to delete node that is not a leaf (middle node)
        if (state.selection.empty && selectionAtNodeStart && !isNodeLeaf) {
          return true;
        }
        // Set selection to title when trying to delete first node
        // first we make sure that the we are not selecting full line
        // then we check if we are at the start of the efNode
        if (
          state.selection.from === state.selection.to &&
          state.selection.from === parent.start + 1
        ) {
          // Make sure node is first node in the doc, by check that two nodes back is the title node
          const titleNode = findParentNodeOfTypeClosestToPos(
            state.doc.resolve(parent.pos - 3),
            state.schema.nodes.title
          );
          if (titleNode) {
            const tr = state.tr;
            tr.setSelection(
              TextSelection.create(
                tr.doc,
                titleNode.start + titleNode.node.nodeSize
              )
            );
            editor.view.dispatch(tr);
            return true;
          }
        }
        return false;
      },
      Escape: () => this.editor.commands.moveCursorToTop(),
    };
  },
  addInputRules() {
    return [
      {
        find: /^\^\s$/,
        handler({ state, range }) {
          const node = findParentNodeOfType(state.schema.nodes.EfNode)(
            state.selection
          );
          if (!node) return;

          const $pos = state.doc.resolve(node.pos);
          const list = findParentNodeOfTypeClosestToPos(
            $pos,
            state.schema.nodes.EfNodeList
          );
          if (!list) return;

          const index = $pos.index($pos.depth);
          if (index === 0) return;

          const prevNode = $pos.parent.child(index - 1);
          const tags = findChildrenByType(prevNode, state.schema.nodes.tag).map(
            (tag) =>
              state.schema.nodes.tag.create({
                id: tag.node.attrs.id,
              })
          );

          state.tr
            .delete(range.from, range.to)
            .insertText(" ", range.from)
            .insert(range.from, tags)
            .scrollIntoView();
        },
      },
    ];
  },
});
