export const findElementWithNodeId = (
  element: Element | null
): Element | null => {
  if (!element) {
    return null;
  }
  if (element.hasAttribute("data-nodeid")) {
    return element;
  }
  return findElementWithNodeId(element.parentElement);
};
