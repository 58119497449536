import { useMemo } from "react";
import { OperatorDropdown, Operators } from "../OperatorDrowdown";
import {
  FilterContainer,
  FilterItemsContainer,
  MobileFilterContainer,
  MobileFilterOperatorContainer,
} from "./FilterContainer";
import { DatePicker } from "../DatePicker";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { DatePickerWithRange } from "../DateRangePicker";
import { useSearch } from "@/context/SearchContext";
import { isMobile } from "react-device-detect";
import { cn } from "@/utils/styles";
import { FIELD_ICONS, CreatedAtField, FilterFields } from "../fields";
import { CreatedAtRule, ModifiedAtRule, FilterRule } from "../types";
import {
  ApplyFiltersButton,
  useDirty,
} from "../MobileFilterDrawer/ApplyFilterButton";

interface DateFilterProps {
  field: FilterFields.CreatedAt | FilterFields.ModifiedAt;
  dropdown?: boolean;
}
export const DateFilter = ({ field, dropdown = true }: DateFilterProps) => {
  const { setRule, removeRule, filters } = useSearch();
  const { dirty } = useDirty(field);
  const rule = useMemo(
    () =>
      filters.rules.find((rule) => rule.id === field) as
        | CreatedAtRule
        | ModifiedAtRule,
    [filters, field]
  );
  const operator = rule.operator;
  const isBetween = operator === "between";
  const [date1, date2] = useMemo(() => {
    if (rule.value) {
      return rule.value.split(",").map((date) => {
        try {
          if (!date) return undefined;
          if (isNaN(Date.parse(date))) return undefined;
          return new Date(Date.parse(date));
        } catch (error) {
          return undefined;
        }
      });
    }
    return [undefined, undefined];
  }, [rule]);

  const setOperator = (operator: string) => {
    if (operator === "between") {
      return setRule({
        ...rule,
        operator: operator,
        value: date1
          ? date1.toISOString() +
            "," +
            new Date(date1.getTime() + 24 * 60 * 60 * 1000).toISOString()
          : "",
      });
    }
    const nextRule = {
      ...rule,
      operator: operator,
      value: date1?.toISOString(),
    };
    setRule(nextRule as FilterRule);
  };

  const setDate = (date1: Date, date2?: Date) => {
    if (!isBetween) {
      return setRule({
        ...rule,
        value: date1 ? date1.toISOString() : "",
      });
    }
    const dates = [date1, date2];
    setRule({
      ...rule,
      value: dates.map((date) => date?.toISOString()).join(","),
    });
  };

  const Icon = FIELD_ICONS[field];

  const operatorDropdownWithTitle = (
    <>
      <Icon />
      <span className={cn(isMobile && "text-sm")}>
        {field === FilterFields.CreatedAt ? "Created" : "Modified"} At
      </span>
      <OperatorDropdown
        operator={operator}
        operators={operators}
        setOperator={setOperator}
      />
    </>
  );

  if (!dropdown) {
    return (
      <MobileFilterContainer>
        <MobileFilterOperatorContainer>
          {operatorDropdownWithTitle}
          <div className="flex flex-1 justify-end">
            {dirty && <ApplyFiltersButton />}
          </div>
        </MobileFilterOperatorContainer>
        <div className="flex justify-center">
          {!isBetween && (
            <DatePicker
              date={date1}
              setDate={setDate}
              popover={false}
              calendarProps={mobileDatePickerProps}
            />
          )}
          {isBetween && (
            <DatePickerWithRange
              date1={date1}
              date2={date2}
              setDate={setDate}
              popover={false}
              calendarProps={mobileDatePickerProps}
            />
          )}
        </div>
      </MobileFilterContainer>
    );
  }

  return (
    <FilterContainer>
      <FilterItemsContainer>
        {operatorDropdownWithTitle}
        {!isBetween && (
          <DatePicker date={date1} setDate={setDate} contentOnly={isMobile} />
        )}
        {isBetween && (
          <DatePickerWithRange
            date1={date1}
            date2={date2}
            setDate={setDate}
            contentOnly={isMobile}
          />
        )}
        <Button
          variant="outline"
          size="icon"
          onClick={(e) => {
            removeRule(field);
            e.stopPropagation();
          }}
          className="h-5 w-5 border-0"
        >
          <X height={16} width={16} />
        </Button>
      </FilterItemsContainer>
    </FilterContainer>
  );
};

const operators = CreatedAtField.operators as Operators;

const mobileDatePickerProps = {
  className: "w-full",
  classNames: {
    cell: "[&:has([aria-selected])].bg-white",
  },
  styles: {
    cell: {
      width: "calc(100% / 7)",
    },
    head_cell: {
      width: "calc(100% / 7)",
    },
  },
};
