import { Node } from "@tiptap/react";

export const NodeList = Node.create({
  name: "EfNodeList",
  content: "EfNode+",
  parseHTML() {
    return [{ tag: "div" }];
  },
  renderHTML({ HTMLAttributes }) {
    return ["div", { ...HTMLAttributes, class: "ef-node-list" }, 0];
  },
});
