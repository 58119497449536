import { Editor } from "@tiptap/react";
import { EfNode } from "../../../types";
import { HistoryManager } from "../../../hooks/useHistoryManager";

export interface ToolbarOption {
  name: string; // Will serve as key
  component: React.ReactNode;
  onClick: (params: {
    node: EfNode;
    editor?: Editor; // Editor can be undefined because when creating a new node - the editor might not be mounted yet
    setToolbarType: (toolbarType: ToolbarType) => void;
    historyManager: HistoryManager;
    setCurrentId: (id: string) => void;
    jumpToTop: (node: EfNode) => Promise<void>;
    relativeDepth?: number;
  }) => void | Promise<void>;
  isDisabled?: (editor: Editor) => boolean;
  isActive?: (editor: Editor) => boolean;
}

export enum ToolbarType {
  Main,
  Formatting,
  Layout,
}
