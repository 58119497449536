import { Dialog } from "@headlessui/react";
import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { AiOutlineSearch, AiOutlineLoading } from "react-icons/ai";
import { HiHashtag } from "react-icons/hi";
import { EfNodeType } from "../../graphql";
import { EfNode, EfNodeData } from "../../types";
import { debounce, groupBy } from "lodash";
import { MdOutlinePersonOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaRegFileAlt } from "react-icons/fa";
import { db } from "../../db";
import { useKeyboardShortcut } from "../../hooks/useKeyboardShortcut";
import { isMacOs } from "react-device-detect";
import { filterNodes, getUpdatedQuery } from "./SearchUtil";
import { tagFullNameCache } from "@/cache/tagCache";
import { GroupedVirtuoso, GroupedVirtuosoHandle } from "react-virtuoso";
import { sortNodesBasedOnRecency } from "@/utils/tags";

type GlobalSearchProps = {
  isOpen?: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const GlobalSearch = ({ isOpen, setIsOpen }: GlobalSearchProps) => {
  const [nodesByType, setNodesByType] =
    useState<{ [key in EfNodeType]?: (EfNode & { fullName?: string })[] }>();
  const virtuosoRef = useRef<GroupedVirtuosoHandle>(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [contextIndex, setContextIndex] = useState<number>(-1);

  const { isKeyboardShortcut } = useKeyboardShortcut({
    keys: [{ or: isMacOs ? ["cmd"] : ["ctrl"] }, { or: ["k", "K"] }],
    element: window,
    preventDefault: true,
  });

  useEffect(() => {
    if (isKeyboardShortcut) {
      setIsOpen(true);
    }
  }, [isKeyboardShortcut]);

  useEffect(() => {
    if (!isOpen) {
      setNodesByType(undefined);
      setLoading(false);
      setContextIndex(-1);
    }
  }, [isOpen]);

  const getQueryData = useCallback(async (query: string) => {
    const nodesWithoutTaskAndTag = await db.nodes
      .where("nodeType")
      .anyOf([EfNodeType.Page, EfNodeType.Contact, EfNodeType.ThoughtPad])
      .toArray();

    // Concatinating tagNodes + pending, completed nodes to  nodesWithoutTaskAndTag.
    const nodes: (EfNode & { fullName?: string })[] = nodesWithoutTaskAndTag
      .concat([
        {
          titleText: "Pending",
          nodeType: EfNodeType.Task,
          deleted: false,
        },
        {
          titleText: "Completed",
          nodeType: EfNodeType.Task,
          deleted: false,
        },
      ] as EfNode[])
      // Taking tag nodes from cache.
      .concat(tagFullNameCache.getCacheEntries());
    // filtering nodes
    const filteredNodes = nodes.filter((node) => filterNodes(query, node));
    // sorting nodes
    const sortedNodes = sortNodesBasedOnRecency(
      groupBy(filteredNodes, "nodeType") as Record<
        EfNodeType,
        (EfNode & { fullName?: string })[]
      >,
      getUpdatedQuery(query)
    );
    setNodesByType(sortedNodes);
  }, []);

  const onChangeSearch = useCallback(
    debounce(
      async ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(value);
        if (!value) {
          setNodesByType(undefined);
          return;
        }
        setLoading(true);
        setContextIndex(-1);
        await getQueryData(value);
        setLoading(false);
      },
      300
    ),
    [setNodesByType, setLoading]
  );

  const onClickResult = useCallback(
    ({ nodeType, node }: { nodeType: EfNodeType; node: Partial<EfNode> }) => {
      switch (nodeType) {
        case EfNodeType.ThoughtPad:
          navigate("/thoughtpad");
          break;
        case EfNodeType.Task:
          navigate(`/tasks/${node.titleText?.toLowerCase()}`);
          break;
        default:
          navigate(`/${nodeType.toLowerCase()}s/${node.id}`);
          break;
      }
      setIsOpen(false);
    },
    [navigate]
  );

  const { sortedNodesByType, totalLength } = useMemo(
    () => ({
      sortedNodesByType: Object.entries(nodesByType || {}).sort(
        ([nodeTypeA], [nodeTypeB]) => nodeTypeB.localeCompare(nodeTypeA)
      ) as [EfNodeType, (EfNode & { fullName?: string })[]][],
      totalLength: Object.entries(nodesByType || {}).reduce(
        (prevValue, [_, nodes]) => prevValue + nodes.length,
        0
      ),
    }),
    [nodesByType]
  );

  const onKeyDown = useCallback(
    (evt: KeyboardEvent<HTMLDivElement>) => {
      if (evt.key === "ArrowUp") {
        setContextIndex((prevIdx) => {
          const locationIndex = Math.max((prevIdx || 0) - 1, 0);
          virtuosoRef.current?.scrollIntoView({ index: locationIndex });
          return locationIndex;
        });
      } else if (evt.key === "ArrowDown") {
        setContextIndex((prevIdx) => {
          const locationIndex = Math.min(
            (prevIdx || 0) + 1,
            sortedNodesByType.reduce(
              (prevValue, [_, nodes]) => prevValue + nodes.length,
              0
            ) - 1
          );
          virtuosoRef.current?.scrollIntoView({ index: locationIndex });
          return locationIndex;
        });
      } else if (evt.key === "Enter") {
        const node = sortedNodesByType.flatMap(([_, nodes]) => nodes)[
          contextIndex
        ];
        if (!node) {
          return;
        }
        onClickResult({ node, nodeType: node.nodeType });
      } else if (evt.key === "Escape") {
        setIsOpen(false);
      }
    },
    [sortedNodesByType, virtuosoRef.current, onClickResult]
  );

  const onClose = useCallback(() => {
    setIsOpen?.(false);
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed flex bg-white rounded-lg top-28 w-11/12 mx-3.5 sm:top-1/3 sm:left-[25%] sm:w-2/4">
        <Dialog.Panel className="w-full">
          <div onKeyDown={onKeyDown}>
            <div className="p-4">
              {loading ? (
                <AiOutlineLoading className="w-6 h-6 left-6 top-[20px] animate-spin inline text-gray-400 absolute text-center" />
              ) : (
                <AiOutlineSearch className="pl-2 pt-1 w-8 h-8 text-gray-400 absolute text-center" />
              )}
              <input
                onChange={onChangeSearch}
                autoFocus
                placeholder="Lookup tags, contacts, pages, tasks…"
                className="flex-1 pr-10 py-1 text-md rounded-3xl outline-none border-2 border-slate-300 focus:border-blue-600 w-full pl-10"
                onKeyDown={(event) => {
                  if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className="pb-3 space-y-3">
              {totalLength === 0 && (
                <div className="block py-2 p-4 border-t-2">
                  No Results found
                </div>
              )}
              {totalLength !== 0 && (
                <GroupedVirtuoso
                  style={{ height: 400 }}
                  ref={virtuosoRef}
                  groupCounts={sortedNodesByType.map(
                    ([_, nodes]) => nodes.length
                  )}
                  groupContent={(index) => (
                    <div className="bg-white pl-2 pb-1">
                      {sortedNodesByType[index][0]}
                    </div>
                  )}
                  itemContent={(index, groupIndex) => {
                    const currentIndex =
                      index -
                      sortedNodesByType
                        .slice(0, groupIndex)
                        .reduce(
                          (prevValue, [_, nodes]) => prevValue + nodes.length,
                          0
                        );
                    const nodeType = sortedNodesByType[groupIndex][0];
                    const node = sortedNodesByType[groupIndex][1][currentIndex];
                    const updatedQuery = getUpdatedQuery(searchQuery);
                    const { titleText, id, fullName } = node;
                    const nodeString = fullName || titleText || "";
                    const matchedIndex = nodeString
                      ?.toLowerCase()
                      ?.indexOf(updatedQuery.toLowerCase());
                    let nodeElement = <>{nodeString}</>;
                    if (matchedIndex >= 0) {
                      nodeElement = (
                        <>
                          {nodeString.substring(0, matchedIndex)}
                          <b className="bg-yellow-200">
                            {nodeString.substring(
                              matchedIndex,
                              matchedIndex + updatedQuery.length
                            )}
                          </b>
                          {nodeString.substring(
                            matchedIndex + updatedQuery.length,
                            nodeString.length
                          )}
                        </>
                      );
                    }
                    return (
                      <div
                        key={id}
                        onClick={() => onClickResult({ node, nodeType })}
                        onMouseEnter={() => {
                          setContextIndex(index);
                        }}
                        onMouseLeave={() => setContextIndex(-1)}
                      >
                        <>
                          <span
                            className={clsx(
                              "text-md py-2 cursor-pointer text-slate-70 rounded-md flex items-center space-x-1 pl-7",
                              { ["bg-slate-100"]: contextIndex === index }
                            )}
                          >
                            <span>
                              {nodeType === EfNodeType.Tag && (
                                <HiHashtag className="text-base" />
                              )}
                              {nodeType === EfNodeType.Contact && (
                                <MdOutlinePersonOutline className="text-lg" />
                              )}
                              {nodeType === EfNodeType.Page && (
                                <FaRegFileAlt className="text-sm" />
                              )}
                            </span>
                            <div>{nodeElement}</div>
                          </span>
                        </>
                      </div>
                    );
                  }}
                />
              )}
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
