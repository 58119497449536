import { useRef, useState } from "react";

export const usePastePopup = () => {
  const [showConfirmPaste, setShowConfirmPaste] = useState(false);
  const onSubmitPasteRef = useRef<((pasteAsList: boolean) => void) | null>(
    null
  );

  return {
    showConfirmPaste,
    setShowConfirmPaste,
    onSubmitPasteRef,
  };
};

interface ConfirmPastePopupProps {
  isOpen: boolean;
  onSubmit: (pasteAsList: boolean) => void;
}
export const ConfirmPastePopup = ({
  isOpen,
  onSubmit,
}: ConfirmPastePopupProps) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
      <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg max-w-full w-full sm:max-w-md">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">
          We have detected lists in copied content
        </h2>
        <p className="mb-4">Would you like to paste with EfNode Structure?</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => onSubmit(false)}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition"
          >
            No
          </button>
          <button
            onClick={() => onSubmit(true)}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};
