import { db } from "../db";

export async function calculateDepth(nodeId: string) {
  let node = await db.nodes.get(nodeId);
  let depth = 0;
  while (node?.parentId) {
    depth++;
    node = await db.nodes.get(node.parentId);
  }
  return depth;
}
