import clsx from "clsx";
import { CSSProperties, ForwardedRef, forwardRef } from "react";
import { DataTest } from "../../../tests/e2e/utils/constants";

type ListProps = {
  containerStyle?: CSSProperties;
  classes?: {
    container?: string;
  };
  onBlurCotainer?: () => void;
  items: {
    key: string;
    label: string;
    className?: string;
    onMouseEnter?: () => void;
    onClick?: () => void;
    ref?: any;
  }[];
};

export const ListComponent = forwardRef(function ListComponent(
  {
    containerStyle,
    classes: { container = "" } = {},
    onBlurCotainer,
    items,
  }: ListProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      tabIndex={0}
      className={container}
      style={containerStyle ?? {}}
      {...(ref && { ref: ref })}
      {...(onBlurCotainer && { onBlur: onBlurCotainer })}
    >
      {items.map(
        ({ label, key, className, onMouseEnter, onClick, ref }, index) => (
          <span
            key={key}
            className={className}
            data-testid={`${DataTest.TagSuggestion}${label}`}
            {...(ref && { ref: ref })}
            {...(onMouseEnter && { onMouseEnter: onMouseEnter })}
            {...(onClick && { onClick: onClick })}
          >
            {label}
          </span>
        )
      )}
    </div>
  );
});
