export const BASE_URL = "127.0.0.1:1234";
export const GRAPHQL_ROUTE = /graphql/;
export const RAW_EDITOR = `${BASE_URL}/editor`;
export const THOUGHT_PAD = `${BASE_URL}/thoughtpad`;

// DateTest must be either string or regexp
export enum DataTest {
  TitleNode = "titleNode",
  CreatePage = "createPage",
  TagPage = "tagPage",
  GroupedNodesListItem = "GroupedNodesListItem",
  PageTitle = "pageTitle",
  TagSuggestion = "tagSuggestion",
  TaskSuggestion = "tagSuggestionTask",
  FileSuggestion = "tagSuggestionFile",
  EfNode = "EfNode",
  EfThoughtPad = "ThoughtPad",
  Search = "Search",
  GroupedNodesList = "GroupedNodesList",
  SearchBar = "SearchBar",
  TagLink = "tagLink",
  MentionLink = "mentionLink",
  Pending = "pendingTasks",
  Completed = "completedTasks",
  CheckBox = "checkbox",
  DeleteTag = "deleteTag",
  DeleteContact = "deleteContact",
  File = "file",
  FileName = "imageFile",
  FileNode = "fileNode",
  DeletePage = "deletePage",
  EditTag = "editTag",
  TagTitle = "tagTitle",
  SlashMenu = "slashMenu",
}

export enum SlashSuggestion {
  Task = "tagSuggestionTask",
  Heading1 = "tagSuggestionHeading 1",
  Heading2 = "tagSuggestionHeading 2",
  Heading3 = "tagSuggestionHeading 3",
  Paragraph = "tagSuggestionParagraph",
  BulletList = "tagSuggestionBullet List",
  OrderedList = "tagSuggestionOrdered List",
  CodeBlock = "tagSuggestionCode block",
  BlockQuote = "tagSuggestionBlockquote",
  tagSuggestionFile = "tagSuggestionFile",
}

// Depending on the platform, the modifier key is different
export const ModifierKey = process.platform === "darwin" ? "Meta" : "Control";
