import { useEffect, useState } from "react";
import { v4 } from "uuid";

declare global {
  interface Window {
    lastIosKeyboardHeight: number;
    iosKeyboardHeightSubscribers: Record<string, (newHeight: number) => void>;
    iosKeyboardHeightCallback: (newHeight: number) => void;
  }
}

window.lastIosKeyboardHeight = window.lastIosKeyboardHeight || 0;
window.iosKeyboardHeightSubscribers = window.iosKeyboardHeightSubscribers || {};
window.iosKeyboardHeightCallback =
  window.iosKeyboardHeightCallback ||
  ((newHeight: number) => {
    window.lastIosKeyboardHeight = newHeight;
    Object.values(window.iosKeyboardHeightSubscribers).forEach((cb) => {
      cb(newHeight);
    });
  });

// This hook subscribe new to ios keyboard change and update the state
// Outside the webiew on ios we check for keyboard height and "send" the height by trigger window.iosKeyboardHeightCallback
export const useIosKeyboardHeight = () => {
  const [height, setHeight] = useState(window.lastIosKeyboardHeight);
  useEffect(() => {
    const cbId = v4();
    window.iosKeyboardHeightSubscribers[cbId] = setHeight;
    return () => {
      delete window.iosKeyboardHeightSubscribers[cbId];
    };
  }, []);
  return height;
};
