import { cn } from "@/utils/styles";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

export type Operators = {
  label: string;
  value: string;
}[];

interface OperatorDropdownProps {
  operator: string;
  setOperator: (operator: string) => void;
  operators: Operators;
  className?: string;
}

/**
 * OperatorDropdown is a dropdown that allows the user to select an operator
 * This component is shared across all filters
 */
export function OperatorDropdown({
  operators,
  operator,
  setOperator,
  className,
}: OperatorDropdownProps) {
  return (
    <Select onValueChange={setOperator} value={operator}>
      <SelectTrigger className={cn("h-[26px] w-fit", className)}>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {operators.map((operator) => {
          return (
            <SelectItem key={operator.value} value={operator.value}>
              {operator.label}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
}
