import { db } from "../db";
import { EfNodeType } from "../graphql";

export async function getParentPageNode(nodeId: string) {
  let node = await db.nodes.get(nodeId);

  while (true) {
    if (!node?.parentId) return null;
    node = await db.nodes.get(node.parentId);
    if (!node) return null;
    if (node.nodeType === EfNodeType.Page) return node;
    if (node.nodeType === EfNodeType.ThoughtPad) {
      // force thoughtpad to appear first
      node.position = "";
      return node;
    }
  }
}
