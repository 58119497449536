import { capitalize } from "lodash";
import { useParams } from "react-router-dom";
import { db } from "../db";
import { EfNodeType } from "../graphql";
import {
  NodesGroupedByPage,
  NodesGroupedByPageSkeleton,
} from "./NodesGroupedByPage";
import { ChronologicalNodes } from "./ChronologicalNodes";
import { useEffect, useMemo, useRef, useState } from "react";
import { NodesGroupedByPriority } from "./NodesGroupByPriority";
import { EfNodeEditorData, ListOrder } from "@/types";
import { v4 } from "uuid";
import { generateKeyBetween } from "fractional-indexing";
import { getFirstNodeInAnThoughtPad } from "@/utils/nodes";
import { useFocusNode } from "@/hooks/useFocusNode";
import {
  getPagePropertiesInSettings,
  setLastVisitedInSettings,
  setPagePropertiesInSettings,
} from "@/utils/settings";
import { cn } from "@/utils/styles";
import { useQuery } from "@/hooks/useQuery";
import { ListOrderMenu } from "./ListOrder";

export const TasksRouteWapper = () => {
  const { status = "" } = useParams();
  return <TasksRoute status={status} key={status} />;
};

function TasksRoute({ status }: { status: string }) {
  const [tasks, tasksLoading] = useQuery(
    () => db.nodes.where("nodeType").equals(EfNodeType.Task).toArray(),
    []
  );
  const stateChangeCountRef = useRef<number>(0);
  const [focusOn, setFocusOn] = useState<boolean>(true);

  useEffect(() => {
    if (!tasksLoading) {
      stateChangeCountRef.current += 1;
    }
  }, [tasks]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  // const { nodeFocussed, onScroll, observer } = useFocusNode({
  //   pageId: status,
  //   ids: [status],
  //   enabled: focusOn && !!status,
  //   scrollRef,
  //   stateChangeCountRef: stateChangeCountRef,
  // });

  const [listOrder, setListOrder] = useState(ListOrder.Chronological);
  const filteredTasks = (tasks ?? []).filter((task) => {
    if (status === "completed")
      return task.properties?.taskStatus === "COMPLETED";
    if (status === "pending") return task.properties?.taskStatus === "PENDING";
    return false;
  });

  useEffect(() => {
    getPagePropertiesInSettings().then((pageProperties) => {
      setListOrder(
        pageProperties?.[status]?.listOrderType || ListOrder.Chronological
      );
    });
  }, []);

  const onChangeListOrder = async (listOrderValue: ListOrder) => {
    setListOrder(listOrderValue);
    setFocusOn(false);
    await setLastVisitedInSettings(status, []);
    setPagePropertiesInSettings({
      [status]: { listOrderType: listOrderValue },
    });
  };

  const createNewNodeAtStart = async () => {
    const { firstNode, thoughtPad } =
      (await getFirstNodeInAnThoughtPad()) || {};
    const node: EfNodeEditorData = {
      id: v4(),
      parentId: thoughtPad?.id!,
      position: generateKeyBetween(null, firstNode?.position || null),
      nodeType: EfNodeType.Task,
      properties: {
        taskStatus: status?.toUpperCase() as
          | "PENDING"
          | "COMPLETED"
          | undefined,
      },
      tagIds: [],
      referencedPageIds: [],
      mentionIds: [],
      titleText: "",
      contentText: "<p></p>",
    };
    return node;
  };

  // Based on the height of the display getting how many skeleton to show.
  const length = useMemo(() => {
    const iosTopPadding = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        "--safe-area-inset-top"
      ) || "0"
    );
    return Math.floor((window.innerHeight - 40 - iosTopPadding) / 44) - 1;
  }, []);

  if (!tasksLoading && !tasks) return null;

  return (
    // <>
    //   <div
    //     className={cn("relative", {
    //       hidden: nodeFocussed,
    //       block: !nodeFocussed,
    //     })}
    //   >
    //     <div className="p-2 px-4 absolute w-full">
    //       <NodesGroupedByPageSkeleton length={length} />
    //     </div>
    //   </div>
    <div
      className={cn("overflow-auto overscroll-none p-2  flex-1", {
        // "opacity-0": !nodeFocussed,
        // "opacity-100": nodeFocussed,
        // overflowAnchor: "none",
      })}
      // onScroll={onScroll}
      ref={scrollRef}
    >
      <div
        className="max-w-5xl mx-auto my-4"
        // ref={observer}
      >
        <div className="prose" ref={headerRef}>
          <h1>{capitalize(status)} Tasks</h1>
          <div className="mb-3">
            <ListOrderMenu
              listOrderOptions={Object.entries(ListOrder).map(
                ([_, val]) => val
              )}
              listOrder={listOrder}
              onChangeListOrder={onChangeListOrder}
            />
          </div>
        </div>
        {listOrder === ListOrder.Chronological && (
          <ChronologicalNodes
            nodes={filteredTasks}
            key={status}
            createNewNodeAtStart={createNewNodeAtStart}
            containerScrollRef={scrollRef}
            headerRef={headerRef}
          />
        )}
        {listOrder === ListOrder.ByPage && (
          <NodesGroupedByPage
            nodes={filteredTasks}
            key={status}
            createNewNodeAtStart={createNewNodeAtStart}
            containerScrollRef={scrollRef}
            headerRef={headerRef}
          />
        )}
        {listOrder === ListOrder.ByPriority && (
          <NodesGroupedByPriority
            nodes={filteredTasks}
            key={status}
            createNewNodeAtStart={createNewNodeAtStart}
            containerScrollRef={scrollRef}
            headerRef={headerRef}
          />
        )}
      </div>
    </div>
    // </>
  );
}
