import download from "downloadjs";
import { generateNKeysBetween } from "fractional-indexing";
import { useNavigate } from "react-router-dom";
import invariant from "tiny-invariant";
import { v4 } from "uuid";
import { db } from "../db";
import { api, EfNodeType } from "../graphql";
import { btn } from "../styles/classes";
import { EfNodeEditorData } from "../types";
import { createNode, updateRelatedNodesModifiedTimeIfRequired } from "../utils";
import { setLandingPageInSettings } from "../utils/settings";
import { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { cn } from "@/utils/styles";

const tagsCache = new Map();

export function DataRoute() {
  const navigate = useNavigate();
  const [relatedNodesUpdating, setRelatedNodesUpdating] = useState(false);

  const onClickUpdateRelatedNodes = async () => {
    setRelatedNodesUpdating(true);
    const nodes = await db.nodes.orderBy("modifiedTime").toArray();
    await Promise.all(
      nodes.map(async ({ id }) => {
        return await updateRelatedNodesModifiedTimeIfRequired(id);
      })
    );
    setRelatedNodesUpdating(false);
  };

  const exportLocal = (
    <button
      className={btn}
      onClick={async () => {
        const blob = await db.export();
        download(
          blob,
          `execfn-export-${new Date().toLocaleString()}.json`,
          "application/json"
        );
      }}
    >
      Export Local Data
    </button>
  );
  const importLocal = (
    <>
      <input
        id="file-upload-btn"
        type="file"
        onChange={async (e) => {
          const file = e.currentTarget.files?.[0];
          if (!file) return;
          await db.import(file, {
            acceptNameDiff: true,
            overwriteValues: true,
            acceptMissingTables: true,
            clearTablesBeforeImport: true,
          });
        }}
        accept="application/json"
        hidden
      />
      <label htmlFor="file-upload-btn" className={btn}>
        Import Local Data
      </label>
    </>
  );
  const deleteBoth = (
    <button
      title="Clear Data"
      className={btn}
      onClick={async () => {
        await api.ClearData();
        await db.delete();
        localStorage.clear();
        window.location.reload();
      }}
    >
      Clear Data
    </button>
  );
  const importRoam = (
    <>
      <label
        htmlFor="file"
        className={btn}
        style={{
          display: "inline-block",
          cursor: "pointer",
          padding: "0.5rem 1rem",
        }}
      >
        Import Roam JSON
      </label>
      <input
        id="file"
        type="file"
        accept=".json"
        onChange={async (e) => {
          if (!e.currentTarget.files) return;
          if (!e.currentTarget.files.length) return;
          const data = await parseJsonFile(e.currentTarget.files[0]);
          importData(data);
        }}
        style={{ display: "none" }}
      />
    </>
  );
  return (
    <div className="p-3 space-y-4">
      <div className="flex space-x-2">
        {exportLocal}
        {importLocal}
      </div>
      <div className="flex space-x-2">{deleteBoth}</div>
      <div className="flex space-x-2">
        <button
          className={btn}
          onClick={() => setLandingPageInSettings("/thoughtpad")}
        >
          Clear landing page
        </button>
      </div>
      {/* <div className="flex space-x-2">
        <button
          className={cn(btn, "space-x-2")}
          onClick={onClickUpdateRelatedNodes}
        >
          {relatedNodesUpdating && (
            <AiOutlineLoading3Quarters className="animate-spin" />
          )}
          <div>Update tag with related nodes</div>
        </button>
      </div> */}
      <div className="flex space-x-2">{importRoam}</div>
      <div className="flex space-x-2">
        <button
          className={btn}
          onClick={() => {
            localStorage.setItem("debug", "");
            window.location.reload();
          }}
        >
          Disable All Debuggers
        </button>
        <button
          className={btn}
          onClick={() => {
            localStorage.setItem("debug", "sync");
            window.location.reload();
          }}
        >
          Enable Sync Debugger
        </button>
      </div>
      <div className="flex space-x-2">
        <button
          className={btn}
          onClick={async () => {
            const parent = await db.nodes
              .where({ nodeType: EfNodeType.ThoughtPad })
              .first();
            invariant(parent, "Thoughtpad not found");
            await createNode({
              id: v4(),
              titleText: null,
              contentText: "content text here",
              parentId: parent.id,
              nodeType: EfNodeType.File,
              position: null,
              tagIds: [],
              referencedPageIds: [],
              properties: {
                fileContentType: "image/png",
                fileName: "my-image.png",
                fileUrl: "https://picsum.photos/600/300",
              },
            });
            await createNode({
              id: v4(),
              titleText: null,
              contentText: "content text here",
              parentId: parent.id,
              nodeType: EfNodeType.File,
              position: null,
              tagIds: [],
              referencedPageIds: [],
              properties: {
                fileContentType: "application/pdf",
                fileName: "my-pdf.pdf",
                fileUrl:
                  "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
              },
            });
          }}
        >
          Create File Nodes in Thoughtpad
        </button>
        <button
          className={btn}
          onClick={async () => {
            const parent = await db.nodes
              .where({ nodeType: EfNodeType.ThoughtPad })
              .first();
            invariant(parent, "Thoughtpad not found");
            for (let i = 0; i < 100; i++) {
              await createNode({
                id: v4(),
                titleText: null,
                contentText: `<p>this block was created automatically ${Math.random()}</p>`,
                parentId: parent.id,
                nodeType: EfNodeType.Block,
                position: "a" + String(Math.random()),
                tagIds: [],
                referencedPageIds: [],
                properties: {
                  collapsed: false,
                  taskStatus: "PENDING",
                  fileContentType: undefined,
                  fileName: undefined,
                  fileUrl: undefined,
                },
              });
            }
          }}
        >
          Create 100 nodes in Thoughtpad
        </button>
        <button
          className={btn}
          onClick={async () => {
            const parent = await db.nodes
              .where({ nodeType: EfNodeType.ThoughtPad })
              .first();
            invariant(parent, "Thoughtpad not found");
            await db.transaction("readwrite", db.nodes, async () => {
              for (let i = 0; i < 300; i++) {
                await createNode({
                  id: v4(),
                  titleText: null,
                  contentText: `<p>this block was created automatically ${Math.random()}</p>`,
                  parentId: parent.id,
                  nodeType: EfNodeType.Block,
                  position: "a" + String(Math.random()),
                  tagIds: [],
                  referencedPageIds: [],
                  properties: {
                    collapsed: false,
                    taskStatus: "PENDING",
                    fileContentType: undefined,
                    fileName: undefined,
                    fileUrl: undefined,
                  },
                });
              }
            });
            window.location.reload();
          }}
        >
          Create 300 nodes in Thoughtpad and Reload
        </button>
        <button
          className={btn}
          onClick={async () => {
            const parent = await db.nodes
              .where({ nodeType: EfNodeType.ThoughtPad })
              .first();
            invariant(parent, "Thoughtpad not found");
            const positions = generateNKeysBetween(null, null, 10000);
            for (let i = 0; i < 10000; i++) {
              await createNode({
                id: v4(),
                titleText: null,
                contentText: `<p>Node ${i}</p>`,
                parentId: parent.id,
                nodeType: EfNodeType.Block,
                position: positions[i],
                tagIds: [],
                referencedPageIds: [],
                properties: {
                  collapsed: false,
                  taskStatus: "PENDING",
                  fileContentType: undefined,
                  fileName: undefined,
                  fileUrl: undefined,
                },
              });
            }
          }}
        >
          Create 10k nodes in Thoughtpad
        </button>
        <button className={btn} onClick={() => navigate("/editor")}>
          Raw Editor
        </button>
        <button
          className={btn}
          onClick={async () => {
            const id = "006d3d5e-8118-4395-b154-7efee130697c";
            const maxNodes = 20;
            const idb = db.backendDB();

            const tx = idb.transaction("nodes", "readonly");
            const store = tx.objectStore("nodes");
            const nodes: any[] = [];

            await new Promise<void>((resolve) => {
              const request = store.openCursor(
                IDBKeyRange.upperBound(id),
                "prev"
              );
              request.onsuccess = (event) => {
                if (nodes.length >= maxNodes / 2) {
                  return resolve();
                }
                const cursor = request.result;
                if (!cursor) {
                  return resolve();
                }

                nodes.unshift(cursor.value);
                cursor.continue();
              };
            });

            await new Promise<void>((resolve) => {
              const request = store.openCursor(
                IDBKeyRange.lowerBound(id, true),
                "next"
              );
              request.onsuccess = (event) => {
                if (nodes.length >= maxNodes) {
                  return resolve();
                }
                const cursor = request.result;
                if (!cursor) {
                  return resolve();
                }

                nodes.push(cursor.value);
                cursor.continue();
              };
            });

            console.log("nodes", nodes);
          }}
        >
          Get nodes with cursor
        </button>
      </div>
    </div>
  );
}

type Page = {
  uid: string;
  title: string;
  children?: Child[];
};

type Child = {
  uid: string;
  string: string;
  children?: Child[];
};

function importData(data: any) {
  const pages = data as Page[];
  pages.forEach((page, index) => {
    importPage(page, index);
  });
}

function importPage(page: Page, index: number) {
  if (page.title === "TODO") return;
  if (page.title === "DONE") return;
  if (page.title === "embed") return;
  if (tagsCache.has(page.title)) return;

  const id = v4();
  createNode({
    id,
    titleText: page.title,
    contentText: null,
    nodeType: EfNodeType.Page,
    parentId: null,
    position: index.toString().padStart(4, "0"),
    properties: {},
    tagIds: [],
    referencedPageIds: [],
  });

  page.children?.forEach((child, index) => {
    importChild(child, index, id);
  });
}

function importChild(child: Child, index: number, parentId: string) {
  const id = v4();

  let nodeType: EfNodeEditorData["nodeType"] = EfNodeType.Block;
  let properties: EfNodeEditorData["properties"] = {};
  let tagIds: EfNodeEditorData["tagIds"] = [];
  let contentText = child.string;

  const tagMatches = Array.from(contentText.matchAll(/#([\w.-_]+)/g));
  for (const tagMatch of tagMatches) {
    const tagTitle = tagMatch[1];
    const tagId = createTag(tagTitle);
    tagIds.push(tagId);
    contentText = contentText.replace(
      `#${tagTitle}`,
      `<span data-type="tag" data-id="${tagId}">#${tagTitle}</span>`
    );
  }

  if (contentText.includes("{{[[TODO]]}}")) {
    contentText = contentText.replace("{{[[TODO]]}}", "");
    nodeType = EfNodeType.Task;
    properties.taskStatus = "PENDING";
  }

  if (contentText.includes("{{[[DONE]]}}")) {
    contentText = contentText.replace("{{[[DONE]]}}", "");
    nodeType = EfNodeType.Task;
    properties.taskStatus = "COMPLETED";
  }

  contentText = contentText.trim();
  contentText = contentText.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
  contentText = contentText.replace(/\_\_(.*?)\_\_/g, "<em>$1</em>");
  contentText = contentText.replace(/\~\~(.*?)\~\~/g, "<s>$1</s>");
  contentText = `<p>${contentText}</p>`;

  createNode({
    id,
    titleText: null,
    contentText,
    nodeType,
    parentId,
    properties,
    tagIds,
    referencedPageIds: [],
    position: index.toString().padStart(4, "0"),
  });

  child.children?.forEach((child, index) => {
    importChild(child, index, id);
  });
}

function createTag(titleText: string) {
  if (tagsCache.has(titleText)) {
    return tagsCache.get(titleText);
  }

  const id = v4();
  createNode({
    id,
    titleText,
    contentText: null,
    nodeType: EfNodeType.Tag,
    parentId: null,
    position: tagsCache.size.toString().padStart(4, "0"),
    properties: {},
    tagIds: [],
    referencedPageIds: [],
  });

  tagsCache.set(titleText, id);

  return id;
}

async function parseJsonFile(file: File) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      invariant(typeof event.target?.result === "string");
      return resolve(JSON.parse(event.target.result));
    };
    fileReader.onerror = (error) => reject(error);
    fileReader.readAsText(file);
  });
}
