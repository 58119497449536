import { useLiveQuery } from "dexie-react-hooks";
import { EfNode, EfNodeEditorData } from "../types";
import { db } from "../db";
import { EfNodeType } from "../graphql";
import { DataTest } from "../../tests/e2e/utils/constants";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { btn } from "../styles/classes";
import { getParentPageNode } from "../utils/getParentPageNode";
import { keyBy, map, orderBy } from "lodash";
import { usePrevious } from "../hooks/usePrevious";
import { useEffect, useState } from "react";
import { GoToNodeIcon } from "./Icons";
import { SingleNodeEditor } from "./VirtualizedEditor/SingleNodeEditor";
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { useKeyboardShortcut } from "@/hooks/useKeyboardShortcut";
import { createNode } from "@/utils";

type NodesGroupedByPriority = {
  nodes: EfNode[];
  createNewNodeAtStart?: () => Promise<EfNodeEditorData | undefined>;
  containerScrollRef: React.RefObject<HTMLElement>;
  headerRef?: React.RefObject<HTMLDivElement>;
};

const priorities = ["p1", "p2", "p3"];

/**
 * Component used to group nodes by priority and display them
 * @param nodes - List of nodes to be displayed
 * @param createNewNodeAtStart - cb that creates a new node at the start of the list
 * @param containerScrollRef - ref to the container that needs to be scrolled, used for SNE
 * @param headerRef - ref to the header, used for SNE
 * @returns
 */
export function NodesGroupedByPriority({
  nodes,
  createNewNodeAtStart,
  containerScrollRef,
  headerRef,
}: NodesGroupedByPriority) {
  const [activeNode, setActiveNode] = useState<string | null>(null);
  const result = useLiveQuery(() => groupNodesByPriority(nodes), [nodes]);
  const prevResult = usePrevious(result);
  const [initialSortOrder, setInitialSort] =
    useState<Record<string, string[]>>();

  const [createdNewNodeAtStartIds, setCreatedNewNodeAtStartIds] = useState<
    string[]
  >([]);

  const { isKeyboardShortcut } = useKeyboardShortcut({
    keys: [{ or: ["Escape"] }],
    element: window,
  });

  useEffect(() => {
    if (
      result &&
      Object.keys(result.groupByPriority ?? {}).length &&
      !Object.keys(prevResult?.groupByPriority ?? {}).length
    ) {
      setInitialSort(
        Object.entries(result.groupByPriority).reduce(
          (prevValue, [key, value]) => {
            prevValue[key] = orderBy(
              value,
              (node) =>
                node.clientModifiedTime?.getTime?.() ||
                node.modifiedTime?.getTime?.(),
              "desc"
            ).map(({ id }) => id);
            return prevValue;
          },
          {} as Record<string, string[]>
        )
      );
    }
  }, [result]);

  useEffect(() => {
    const createNewNode = async () => {
      const newNodeToCreate = await createNewNodeAtStart?.();
      if (!newNodeToCreate) {
        return;
      }
      await createNode(newNodeToCreate);
      setCreatedNewNodeAtStartIds((prevCreatedNewNodeAtStartIds) => [
        newNodeToCreate.id,
        ...prevCreatedNewNodeAtStartIds,
      ]);
      setActiveNode(newNodeToCreate.id);
    };
    if (isKeyboardShortcut) {
      createNewNode();
    }
  }, [isKeyboardShortcut]);

  if (!result) {
    return null;
  }

  const { groupByPriority, priorityTagById, pageByNodeId } = result;

  return (
    <div>
      {Object.keys(groupByPriority)
        .sort((a, b) =>
          (priorityTagById[a]?.titleText ?? "z").localeCompare(
            priorityTagById[b]?.titleText ?? "z"
          )
        )
        .map((priorityTagId) => {
          return (
            <div
              key={priorityTagId}
              className="mb-6 divide-y pb-0 border rounded-md "
            >
              <div className={clsx(btn, "rounded-b-none")}>
                {priorityTagId === "no-priority"
                  ? "Use #p1, #p2, #p3 to move these tasks to respective sections"
                  : `#${priorityTagById[priorityTagId].titleText}`}
              </div>
              {sortByInitialSortOrder(groupByPriority?.[priorityTagId], [
                ...createdNewNodeAtStartIds,
                ...(initialSortOrder?.[priorityTagId] || []),
              ]).map((node) => {
                const page = pageByNodeId[node.id];
                const pageRoute =
                  page.nodeType === EfNodeType.ThoughtPad
                    ? `/thoughtpad`
                    : `/pages/${page.id}`;
                return (
                  <div
                    key={node.id}
                    className="p-1 flex"
                    data-testid={DataTest.GroupedNodesListItem}
                  >
                    <div className="add-padding flex items-start">
                      <TooltipProvider delayDuration={0}>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <NavLink
                              to={`${pageRoute}?node=${node.id}`}
                              className="hover:bg-gray-200 p-1.5 rounded-full"
                            >
                              <GoToNodeIcon className="w-5 h-5" />
                            </NavLink>
                          </TooltipTrigger>
                          <TooltipPortal>
                            <TooltipContent>{page.titleText}</TooltipContent>
                          </TooltipPortal>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                    <div className="w-full">
                      <SingleNodeEditor
                        parentNode={node}
                        isActive={activeNode === node.id}
                        setActive={setActiveNode}
                        containerScrollRef={containerScrollRef}
                        headerRef={headerRef}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
}

/**
 * Groups nodes by priority tags (p1, p2, p3)
 * @param nodes
 * @returns an object containing nodes grouped by priority, map of priority tag id to priority tag node and map of node id to page node
 */
async function groupNodesByPriority(nodes: EfNode[]) {
  const pageByNodeId: Record<string, EfNode> = {};
  const priorityTags = (
    await db.nodes.where("nodeType").equals(EfNodeType.Tag).toArray()
  ).filter(
    ({ titleText, parentId }) =>
      !parentId && !!titleText && priorities.includes(titleText)
  );

  const priorityTagIds = map(priorityTags, "id");
  const priorityTagById = keyBy(priorityTags, "id");
  const filteredNodes = nodes.filter(({ deleted }) => !deleted);
  const groupByPriority = {} as Record<string, EfNode[]>;
  // Go over all non-deleted nodes
  for (const filteredNode of filteredNodes) {
    // Get the priority tags of the node
    const priorityTagSet = new Set(
      (filteredNode.tagIds || []).filter((tagId) =>
        priorityTagIds.includes(tagId)
      )
    );
    const priorityTag = Array.from(priorityTagSet);
    if (filteredNode.tagIds?.length) {
      console.log("filtered");
    }
    // If the node doesn't have a priority tag, add it to the no-priority group
    if (!priorityTag?.length) {
      if (!groupByPriority["no-priority"]) {
        groupByPriority["no-priority"] = [];
      }
      groupByPriority["no-priority"].push(filteredNode);
    } else {
      // Add the node to the group of the priority tag
      priorityTag.forEach((tagId) => {
        if (!groupByPriority[tagId]) {
          groupByPriority[tagId] = [];
        }
        groupByPriority[tagId].push(filteredNode);
      });
    }
    // Get the page node of the node
    const page = await getParentPageNode(filteredNode.id);
    if (page) {
      // Add the page node to the map
      pageByNodeId[filteredNode.id] = page;
    }
  }
  return {
    groupByPriority,
    priorityTagById,
    pageByNodeId: pageByNodeId,
  };
}

function sortByInitialSortOrder(
  nodes: EfNode[],
  initialSortOrder: string[] = []
) {
  return orderBy(
    nodes,
    (node) => {
      const idx = initialSortOrder.findIndex((id) => id === node.id!);
      return idx < 0 ? Infinity : idx;
    },
    "asc"
  );
}
