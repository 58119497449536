import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import clsx from "clsx";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../db";
import { MdAttachFile } from "react-icons/md";
import { useScaledFileSource } from "../utils/fileUtils";
import { btn } from "../styles/classes";
import { DataTest } from "../../tests/e2e/utils/constants";
import { useState } from "react";

// Get EfFileNode or file data from uploading queue
const getFileData = async (nodeID: string) => {
  const fileNode = await db.nodes.get(nodeID);
  const fileFromUploadingQueue = await db.fileQueue.get(nodeID);
  return [fileNode, fileFromUploadingQueue] as const;
};
/**
 * Gets nodeID and returns the file data from both nodes table and uploading queue
 * @param nodeID
 * @returns array of EfFileNodeData, FileQueueItem
 */
function useFileData(nodeID: string) {
  const fileData = useLiveQuery(() => getFileData(nodeID));
  if (!fileData) return [undefined, undefined] as const;
  return fileData;
}

// Component for 'file' node
export function FileRenderer(props: NodeViewProps) {
  const nodeID = props.node.attrs.id;

  // Get file data from EfFileNode and Upload Queue
  const [fileNode, uploadingFileNode] = useFileData(nodeID);
  const tmpFileURL = uploadingFileNode?.properties?.fileUrl ?? undefined;
  const tmpFileSrc = useScaledFileSource(tmpFileURL);

  if (!fileNode && !uploadingFileNode) return null;

  const fileNodeProperties = fileNode?.properties || {
    ...uploadingFileNode?.properties,
    fileUrl: null,
  };

  return (
    <NodeViewWrapper as="span">
      <FileNode
        id={nodeID}
        properties={fileNodeProperties}
        tmpFileSrc={tmpFileSrc}
        deleted={fileNode?.deleted}
      />
    </NodeViewWrapper>
  );
}

interface FileNodeProps {
  id: string;
  properties?: {
    fileUrl?: string | null;
    fileName?: string | null;
    fileContentType?: string | null;
    fileDimension?: {
      width?: number;
      height?: number;
    } | null;
  } | null;
  tmpFileSrc?: string | null;
  deleted?: boolean | null;
}
// Component for EfFileNode
export function FileNode({
  id,
  properties,
  tmpFileSrc,
  deleted,
}: FileNodeProps) {
  const fileUrl = properties?.fileUrl;
  const fileName = properties?.fileName;
  const fileContentType = properties?.fileContentType;
  const imageHeight = properties?.fileDimension?.height;
  const imageWidth = properties?.fileDimension?.width;
  const isImage = fileContentType?.startsWith("image/");

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  if (!fileUrl && !tmpFileSrc) return null;

  // If no fileUrl exists yet, use tmpFileSrc (file data stored in uploadQueue)
  const imgSrc = fileUrl ? `${fileUrl}?preview` : tmpFileSrc;

  if (isImage && !imgSrc) return null;

  return (
    <div contentEditable={false} className="inline-flex flex-col w-full">
      <a
        onClick={(e) => {
          // If there is no fileUrl, do not navigate
          if (!fileUrl) e.preventDefault();
        }}
        href={fileUrl || ""}
        target="_blank"
        data-testid={DataTest.FileName}
        className={clsx(
          btn,
          "no-underline rounded-b-none text-xs whitespace-normal ",
          deleted && "bg-red-100"
        )}
      >
        <div>
          <MdAttachFile className="mr-2" />
        </div>
        {fileName ?? "Unnamed file"} ({fileContentType})
      </a>
      {isImage && (
        <div className="thumbnail-container">
          <img
            src={imgSrc!}
            alt={id}
            className={clsx(
              "block m-0 max-w-100 h-auto rounded-b thumbnail-image",
              !fileUrl && "filter contrast-50",
              showPlaceholder && "bg-gray-300 animate-pulse"
            )}
            style={{ height: imageHeight, width: imageWidth }}
            onLoad={() => {
              setShowPlaceholder(false);
            }}
          />
        </div>
      )}
    </div>
  );
}
