import { db } from "../../../db";
import { EfNodeType } from "../../../graphql";

export const getNodeChildren = async (nodeId: string) => {
  return db.nodes
    .where("parentId")
    .equals(nodeId)
    .filter((node) => {
      return (
        [EfNodeType.Block, EfNodeType.Task].includes(node.nodeType) &&
        node.deleted === false
      );
    })
    .sortBy("position");
};

export const getEditorId = (rootId: string, nodeId: string) => {
  return rootId + ":" + nodeId;
};

export const getNodeIdFromEditorId = (editorId?: string | null) => {
  return editorId?.split?.(":").at(1);
};
