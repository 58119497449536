import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getLandingPageInSettings } from "../utils/settings";

export function IndexRoute() {
  const navigate = useNavigate();

  useEffect(() => {
    const goToLandingPage = async () => {
      let landingPage;
      try {
        landingPage = await getLandingPageInSettings();
      } finally {
        navigate(landingPage || "/thoughtpad", {
          replace: true,
        });
      }
    };
    goToLandingPage();
  }, []);

  return null;
}
