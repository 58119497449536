import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import clsx from "clsx";
import { useLiveQuery } from "dexie-react-hooks";
import { NavLink } from "react-router-dom";
import { DataTest } from "../../tests/e2e/utils/constants";
import { db } from "../db";

export function MentionRenderer(props: NodeViewProps) {
  const contact = useLiveQuery(() => db.nodes.get(props.node.attrs.id));
  if (!contact) return null;

  return (
    <NodeViewWrapper
      as="span"
      className={clsx(
        "px-1 rounded-full no-underline inline-block mx-0.5",
        contact.deleted ? "bg-red-100" : "bg-yellow-200"
      )}
    >
      <NavLink
        to={`/contacts/${contact.id}`}
        className={clsx(
          "px-2 rounded-full no-underline",
          contact.deleted ? "bg-red-100" : "bg-yellow-200"
        )}
        data-testid={DataTest.MentionLink}
      >
        @{contact.titleText}
      </NavLink>
    </NodeViewWrapper>
  );
}
