import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import { print } from 'graphql'
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  UUID: any;
};

export type AddEfNodeResponse = EfNodeError | EfNodeSuccess | UserNotLoggedInError;

export type AddFileContextInput = {
  contextCleaned: InputMaybe<Scalars['String']>;
  contextFull: InputMaybe<Scalars['JSON']>;
  extra: InputMaybe<Scalars['JSON']>;
  fileId: Scalars['UUID'];
};

export type AddFileContextResponse = EfNodeError | MessageResponse | UserNotLoggedInError;

export type AddTagsToNode = {
  id: Scalars['UUID'];
  tagIds: Array<Scalars['UUID']>;
};

export type AddToClipBoardInput = {
  contentText: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  nodeType: EfNodeType;
  properties: InputMaybe<Scalars['JSON']>;
  tagIds: InputMaybe<Array<Scalars['UUID']>>;
};

export type ApplyPatchError = {
  __typename?: 'ApplyPatchError';
  id: Scalars['UUID'];
  message: Scalars['String'];
};

export type ApplyPatchSuccess = {
  __typename?: 'ApplyPatchSuccess';
  efNode: EfNode;
};

export type ApplyPatchVersionMismatchError = {
  __typename?: 'ApplyPatchVersionMismatchError';
  efNode: EfNode;
  id: Scalars['UUID'];
};

export type ApplyPatchesResponse = ApplyPatchError | ApplyPatchSuccess | ApplyPatchVersionMismatchError | UserNotLoggedInError;

export type EfNode = {
  __typename?: 'EFNode';
  clientModifiedTime: Maybe<Scalars['DateTime']>;
  contentText: Maybe<Scalars['String']>;
  createdTime: Maybe<Scalars['DateTime']>;
  deleted: Scalars['Boolean'];
  deletedFromParent: Maybe<EfNode>;
  fileIds: Maybe<Array<Scalars['UUID']>>;
  id: Scalars['UUID'];
  lastModifier: Maybe<EfNode>;
  mentionIds: Maybe<Array<Scalars['UUID']>>;
  modifiedTime: Scalars['DateTime'];
  nodeType: EfNodeType;
  ownerId: Maybe<Scalars['UUID']>;
  page: Maybe<EfNode>;
  paginationToken: Maybe<Scalars['String']>;
  parentId: Maybe<Scalars['UUID']>;
  position: Maybe<Scalars['String']>;
  properties: Maybe<Scalars['JSON']>;
  referencedPageIds: Maybe<Array<Scalars['UUID']>>;
  rootId: Maybe<Scalars['UUID']>;
  tagIds: Maybe<Array<Scalars['UUID']>>;
  tags: Maybe<Array<EfNode>>;
  titleText: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['UUID']>;
  workspaceIdStr: Maybe<Scalars['String']>;
};

export type EfNodeConnection = {
  __typename?: 'EFNodeConnection';
  edges: Array<EfNodeEdge>;
  pageInfo: PageInfo;
};

export type EfNodeEdge = {
  __typename?: 'EFNodeEdge';
  cursor: Scalars['String'];
  node: EfNode;
};

export type EfNodeError = {
  __typename?: 'EFNodeError';
  id: Scalars['UUID'];
  message: Scalars['String'];
};

export type EfNodeSuccess = {
  __typename?: 'EFNodeSuccess';
  efNode: EfNode;
};

export enum EfNodeType {
  Block = 'BLOCK',
  Contact = 'CONTACT',
  File = 'FILE',
  Page = 'PAGE',
  Tag = 'TAG',
  Task = 'TASK',
  ThoughtPad = 'THOUGHT_PAD',
  User = 'USER',
  Wip = 'WIP',
  Workspace = 'WORKSPACE'
}

export type EfNodesResponse = EfNodeConnection | UserNotLoggedInError;

export type ExecNotesAttachmentUploadDetails = {
  __typename?: 'ExecNotesAttachmentUploadDetails';
  exists: Scalars['Boolean'];
  fields: Scalars['JSON'];
  id: Scalars['UUID'];
  thumbnailDetails: Maybe<Scalars['JSON']>;
  url: Scalars['String'];
};

export type ExecNotesPackageUploadDetails = {
  __typename?: 'ExecNotesPackageUploadDetails';
  attachments: Maybe<Array<ExecNotesAttachmentUploadDetails>>;
  id: Scalars['UUID'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  version: Scalars['Int'];
};

export type FetchFilesContextResponse = FilesContextDetails | UserNotAuthorizedError | UserNotLoggedInError;

export type FetchNeighbourNodesResponse = NeighbourNodesDetails | UserNotAuthorizedError | UserNotLoggedInError;

export type FetchSlackTagsDataResponse = SlackTagsData | UserNotLoggedInError;

export type FetchSlackUsersDataResponse = SlackUsersData | UserNotLoggedInError;

export type FetchTagsDataResponse = TagsDetails | UserNotLoggedInError;

export type FileContextDetails = {
  __typename?: 'FileContextDetails';
  contextCleaned: Maybe<Scalars['String']>;
  contextFull: Maybe<Scalars['JSON']>;
  efFile: Scalars['Boolean'];
  extra: Maybe<Scalars['JSON']>;
  fileId: Scalars['UUID'];
  fileName: Scalars['String'];
  fileOwner: Scalars['Boolean'];
  fileType: Scalars['String'];
};

export type FilesContextDetails = {
  __typename?: 'FilesContextDetails';
  content: Scalars['String'];
  data: Array<FileContextDetails>;
  tagIds: Maybe<Array<Scalars['String']>>;
};

export type FinishUploadInput = {
  id: Scalars['UUID'];
};

export type FinishUploadResponse = EfNodeError | MessageResponse | UserNotLoggedInError;

export type GetExecNotesAttachmentUploadInput = {
  contentText: InputMaybe<Scalars['String']>;
  contentType: Scalars['String'];
  fileCreatedTime: InputMaybe<Scalars['DateTime']>;
  fileDimensions: InputMaybe<Array<Array<Scalars['Int']>>>;
  fileSourceDevice: InputMaybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  md5sum: InputMaybe<Scalars['String']>;
};

export type GetExecNotesPackageUploadInput = {
  contentText: InputMaybe<Scalars['String']>;
  files: InputMaybe<Array<GetExecNotesAttachmentUploadInput>>;
  id: Scalars['UUID'];
  pageName: InputMaybe<Scalars['String']>;
  slackData: InputMaybe<Scalars['JSON']>;
  tagIds: InputMaybe<Array<Scalars['UUID']>>;
  version: Scalars['Int'];
};

export type GetExecNotesPackageUploadResponse = EfNodeError | ExecNotesPackageUploadDetails;

export type GetUploadDetailsInput = {
  contentText: InputMaybe<Scalars['String']>;
  contentType: Scalars['String'];
  fileCreatedTime: InputMaybe<Scalars['DateTime']>;
  fileSourceDevice: InputMaybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  md5sum: InputMaybe<Scalars['String']>;
  tagIds: InputMaybe<Array<Scalars['UUID']>>;
};

export type GetUploadDetailsResponse = EfNodeError | UploadDetailsResponse;

export type InsertTestNodesResponse = MessageResponse | UserNotLoggedInError;

export type MessageResponse = {
  __typename?: 'MessageResponse';
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addFileContexts: Array<AddFileContextResponse>;
  addTagsToNode: FetchFilesContextResponse;
  addToClipboard: Array<AddEfNodeResponse>;
  applyPatches: Array<ApplyPatchesResponse>;
  finishUpload: Array<FinishUploadResponse>;
  finishUploadAndInsertInDefaultPage: Array<FinishUploadResponse>;
  getUploadDetails: Array<GetUploadDetailsResponse>;
  insertTestNodes: InsertTestNodesResponse;
  saveNoteAndGetUploadDetails: GetExecNotesPackageUploadResponse;
};


export type MutationAddFileContextsArgs = {
  inputs: Array<AddFileContextInput>;
};


export type MutationAddTagsToNodeArgs = {
  tagsData: AddTagsToNode;
};


export type MutationAddToClipboardArgs = {
  inputs: Array<AddToClipBoardInput>;
};


export type MutationApplyPatchesArgs = {
  packets: Array<PacketInput>;
};


export type MutationFinishUploadArgs = {
  inputs: Array<FinishUploadInput>;
};


export type MutationFinishUploadAndInsertInDefaultPageArgs = {
  inputs: Array<FinishUploadInput>;
};


export type MutationGetUploadDetailsArgs = {
  files: Array<GetUploadDetailsInput>;
};


export type MutationInsertTestNodesArgs = {
  nodes?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationSaveNoteAndGetUploadDetailsArgs = {
  package: GetExecNotesPackageUploadInput;
};

export type NeighbourNodesDetails = {
  __typename?: 'NeighbourNodesDetails';
  nodeIds: Array<Scalars['UUID']>;
};

export type PacketInput = {
  id: Scalars['UUID'];
  patch: Scalars['JSON'];
  version: Scalars['Int'];
  workspaceId: Scalars['UUID'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Maybe<Scalars['String']>;
  totalNodes: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  efNodes: EfNodesResponse;
  fetchFilesContextDetails: FetchFilesContextResponse;
  fetchNeighbourNodes: FetchNeighbourNodesResponse;
  fetchSlackUserTagsData: FetchSlackTagsDataResponse;
  fetchSlackUsersData: FetchSlackUsersDataResponse;
  fetchTagsData: FetchTagsDataResponse;
  listClipboardEfnodes: EfNodesResponse;
  listFavoriteEfnodes: EfNodesResponse;
  listFileEfnodes: EfNodesResponse;
  listPagesEfnodes: EfNodesResponse;
  listRecentEfnodes: EfNodesResponse;
  listTasksEfnodes: EfNodesResponse;
  search: EfNodesResponse;
  user: UserType;
};


export type QueryEfNodesArgs = {
  after: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
  nodeType?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  searchQuery: InputMaybe<Scalars['String']>;
};


export type QueryFetchFilesContextDetailsArgs = {
  id: Scalars['String'];
  type: Scalars['String'];
};


export type QueryFetchNeighbourNodesArgs = {
  direction: Scalars['String'];
  id: Scalars['String'];
};


export type QueryFetchSlackUserTagsDataArgs = {
  lastUpdate: Scalars['String'];
};


export type QueryFetchTagsDataArgs = {
  count?: Scalars['Int'];
};


export type QueryListClipboardEfnodesArgs = {
  after: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
};


export type QueryListFavoriteEfnodesArgs = {
  after: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
};


export type QueryListFileEfnodesArgs = {
  after: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
};


export type QueryListPagesEfnodesArgs = {
  after: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
};


export type QueryListRecentEfnodesArgs = {
  after: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
};


export type QueryListTasksEfnodesArgs = {
  after: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
  status?: Scalars['String'];
};


export type QuerySearchArgs = {
  after: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
  searchFilter: InputMaybe<Scalars['String']>;
  searchSpec: Scalars['String'];
  searchType?: Scalars['String'];
};

export type SlackTagsData = {
  __typename?: 'SlackTagsData';
  channelsData: Scalars['JSON'];
};

export type SlackUsersData = {
  __typename?: 'SlackUsersData';
  usersInfo: Scalars['JSON'];
};

export type TagsDetails = {
  __typename?: 'TagsDetails';
  tagsData: Maybe<Scalars['JSON']>;
};

export type UploadDetailsResponse = {
  __typename?: 'UploadDetailsResponse';
  exists: Scalars['Boolean'];
  fields: Scalars['JSON'];
  id: Scalars['UUID'];
  url: Scalars['String'];
};

export type UserConfigType = {
  __typename?: 'UserConfigType';
  workspaceId: Scalars['UUID'];
};

export type UserNotAuthorizedError = {
  __typename?: 'UserNotAuthorizedError';
  message: Scalars['String'];
};

export type UserNotLoggedInError = {
  __typename?: 'UserNotLoggedInError';
  message: Scalars['String'];
};

export type UserType = {
  __typename?: 'UserType';
  isAuthenticated: Scalars['Boolean'];
  userConfig: Maybe<UserConfigType>;
  username: Scalars['String'];
};

export type CheckAuthQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckAuthQuery = { __typename?: 'Query', user: { __typename?: 'UserType', username: string, isAuthenticated: boolean, userConfig: { __typename?: 'UserConfigType', workspaceId: any } | null } };

export type PushMutationVariables = Exact<{
  packets: Array<PacketInput> | PacketInput;
}>;


export type PushMutation = { __typename?: 'Mutation', applyPatches: Array<{ __typename: 'ApplyPatchError', id: any, message: string } | { __typename: 'ApplyPatchSuccess', efNode: { __typename?: 'EFNode', id: any } } | { __typename: 'ApplyPatchVersionMismatchError', id: any } | { __typename?: 'UserNotLoggedInError' }> };

export type PullQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']>;
}>;


export type PullQuery = { __typename?: 'Query', efNodes: { __typename: 'EFNodeConnection', pageInfo: { __typename?: 'PageInfo', endCursor: string | null, totalNodes: number | null }, edges: Array<{ __typename?: 'EFNodeEdge', node: { __typename?: 'EFNode', contentText: string | null, nodeType: EfNodeType, parentId: any | null, position: string | null, titleText: string | null, id: any, version: number | null, workspaceId: any | null, properties: any | null, tagIds: Array<any> | null, mentionIds: Array<any> | null, fileIds: Array<any> | null, referencedPageIds: Array<any> | null, deleted: boolean, modifiedTime: any, rootId: any | null, clientModifiedTime: any | null, createdTime: any | null } }> } | { __typename?: 'UserNotLoggedInError' } };

export type SearchQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']>;
}>;


export type SearchQuery = { __typename?: 'Query', efNodes: { __typename: 'EFNodeConnection', edges: Array<{ __typename?: 'EFNodeEdge', node: { __typename?: 'EFNode', contentText: string | null, nodeType: EfNodeType, parentId: any | null, position: string | null, titleText: string | null, id: any, version: number | null, workspaceId: any | null, properties: any | null, tagIds: Array<any> | null, deleted: boolean } }> } | { __typename?: 'UserNotLoggedInError' } };

export type SearchTextQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']>;
  searchType: InputMaybe<Scalars['String']>;
  query: Scalars['String'];
  searchFilter: InputMaybe<Scalars['String']>;
  after: Scalars['String'];
}>;


export type SearchTextQuery = { __typename?: 'Query', search: { __typename: 'EFNodeConnection', pageInfo: { __typename?: 'PageInfo', startCursor: string | null, endCursor: string | null, hasNextPage: boolean, hasPreviousPage: boolean, totalNodes: number | null }, edges: Array<{ __typename?: 'EFNodeEdge', node: { __typename?: 'EFNode', contentText: string | null, nodeType: EfNodeType, parentId: any | null, position: string | null, titleText: string | null, id: any, version: number | null, workspaceId: any | null, properties: any | null, tagIds: Array<any> | null, deleted: boolean } }> } | { __typename?: 'UserNotLoggedInError' } };

export type ClearDataMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearDataMutation = { __typename?: 'Mutation', insertTestNodes: { __typename: 'MessageResponse', message: string } | { __typename?: 'UserNotLoggedInError' } };

export type FinishUploadMutationVariables = Exact<{
  inputs: Array<FinishUploadInput> | FinishUploadInput;
}>;


export type FinishUploadMutation = { __typename?: 'Mutation', finishUpload: Array<{ __typename: 'EFNodeError', id: any, message: string } | { __typename: 'MessageResponse', message: string } | { __typename?: 'UserNotLoggedInError' }> };

export type GetUploadDetailsMutationVariables = Exact<{
  files: Array<GetUploadDetailsInput> | GetUploadDetailsInput;
}>;


export type GetUploadDetailsMutation = { __typename?: 'Mutation', getUploadDetails: Array<{ __typename: 'EFNodeError', id: any, message: string } | { __typename: 'UploadDetailsResponse', id: any, url: string, fields: any }> };


export const CheckAuthDocument = gql`
    query CheckAuth {
  user {
    username
    isAuthenticated
    userConfig {
      workspaceId
    }
  }
}
    `;
export const PushDocument = gql`
    mutation Push($packets: [PacketInput!]!) {
  applyPatches(packets: $packets) {
    ... on ApplyPatchSuccess {
      __typename
      efNode {
        id
      }
    }
    ... on ApplyPatchError {
      __typename
      id
      message
    }
    ... on ApplyPatchVersionMismatchError {
      __typename
      id
    }
  }
}
    `;
export const PullDocument = gql`
    query Pull($after: String) {
  efNodes(first: 1000, after: $after) {
    ... on EFNodeConnection {
      __typename
      pageInfo {
        endCursor
        totalNodes
      }
      edges {
        node {
          contentText
          nodeType
          parentId
          position
          titleText
          id
          version
          workspaceId
          properties
          tagIds
          mentionIds
          fileIds
          referencedPageIds
          deleted
          modifiedTime
          rootId
          clientModifiedTime
          createdTime
        }
      }
    }
  }
}
    `;
export const SearchDocument = gql`
    query Search($query: String) {
  efNodes(first: 1000, searchQuery: $query) {
    ... on EFNodeConnection {
      __typename
      edges {
        node {
          contentText
          nodeType
          parentId
          position
          titleText
          id
          version
          workspaceId
          properties
          tagIds
          deleted
        }
      }
    }
  }
}
    `;
export const SearchTextDocument = gql`
    query SearchText($first: Int, $searchType: String, $query: String!, $searchFilter: String, $after: String!) {
  search(
    first: $first
    searchType: $searchType
    searchSpec: $query
    searchFilter: $searchFilter
    after: $after
  ) {
    ... on EFNodeConnection {
      __typename
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
        totalNodes
      }
      edges {
        node {
          contentText
          nodeType
          parentId
          position
          titleText
          id
          version
          workspaceId
          properties
          tagIds
          deleted
        }
      }
    }
  }
}
    `;
export const ClearDataDocument = gql`
    mutation ClearData {
  insertTestNodes(nodes: []) {
    ... on MessageResponse {
      __typename
      message
    }
  }
}
    `;
export const FinishUploadDocument = gql`
    mutation FinishUpload($inputs: [FinishUploadInput!]!) {
  finishUpload(inputs: $inputs) {
    ... on MessageResponse {
      __typename
      message
    }
    ... on EFNodeError {
      __typename
      id
      message
    }
  }
}
    `;
export const GetUploadDetailsDocument = gql`
    mutation GetUploadDetails($files: [GetUploadDetailsInput!]!) {
  getUploadDetails(files: $files) {
    ... on UploadDetailsResponse {
      __typename
      id
      url
      fields
    }
    ... on EFNodeError {
      __typename
      id
      message
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();
const CheckAuthDocumentString = print(CheckAuthDocument);
const PushDocumentString = print(PushDocument);
const PullDocumentString = print(PullDocument);
const SearchDocumentString = print(SearchDocument);
const SearchTextDocumentString = print(SearchTextDocument);
const ClearDataDocumentString = print(ClearDataDocument);
const FinishUploadDocumentString = print(FinishUploadDocument);
const GetUploadDetailsDocumentString = print(GetUploadDetailsDocument);
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CheckAuth(variables?: CheckAuthQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CheckAuthQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CheckAuthQuery>(CheckAuthDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CheckAuth', 'query');
    },
    Push(variables: PushMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: PushMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PushMutation>(PushDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Push', 'mutation');
    },
    Pull(variables?: PullQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: PullQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PullQuery>(PullDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Pull', 'query');
    },
    Search(variables?: SearchQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: SearchQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SearchQuery>(SearchDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Search', 'query');
    },
    SearchText(variables: SearchTextQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: SearchTextQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SearchTextQuery>(SearchTextDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SearchText', 'query');
    },
    ClearData(variables?: ClearDataMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: ClearDataMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ClearDataMutation>(ClearDataDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ClearData', 'mutation');
    },
    FinishUpload(variables: FinishUploadMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: FinishUploadMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<FinishUploadMutation>(FinishUploadDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FinishUpload', 'mutation');
    },
    GetUploadDetails(variables: GetUploadDetailsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetUploadDetailsMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetUploadDetailsMutation>(GetUploadDetailsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUploadDetails', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;